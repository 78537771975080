import  { useMemo } from 'react';
import MaterialReactTable from 'material-react-table'
import { useState } from 'react';
import { useEffect } from 'react';
import { shallow } from 'zustand/shallow';
import useGlobalStore from '../store';
import { Space, Button, notification } from 'antd';
import { SaveOutlined } from "@ant-design/icons";


function Table({ HandleSelectedRows, search, tableRef }) {
  const [rowSelection, setRowSelection] = useState({});
  const [tableData, setTableData] = useState([]);
  const { set_selected_rows_app_manager } = useGlobalStore((state) => ({
    set_selected_rows_app_manager: state.set_selected_rows_app_manager,
  }), shallow)
  const tempTableData = search();
  if (tempTableData.length) {
    if (tableData.length !== tempTableData.length) {
      setTableData(tempTableData);
    }
  }

  const columns = useMemo(
    () => [
      {
        accessorKey: 'appname',
        header: 'APPLICATION',
        size: 130,
        enableGrouping: false

      },
      {
        accessorKey: 'role',
        header: 'ENV',
        size: 80,
        enableGrouping: false
      },
      {
        accessorKey: 'servername',
        header: 'SERVERNAME',
        size: 100,
        enableGrouping: false
      },
      {
        accessorKey: 'wave_name',
        header: 'WEEK',
        size: 80,
        enableGrouping: false
      },
      {
        accessorKey: 'migration_date',
        header: 'MDATE',
        size: 80,
        enableGrouping: false
      },
      {
        accessorKey: 'wave_calendar_date',
        header: 'Wave Calendar Date',
        size: 80,
        enableGrouping: false
      },
      {
        accessorKey: 'approval_status',
        header: 'STATUS',
        size: 80,
        enableGrouping: false
      },
      {
        accessorKey: 'approved_by',
        header: 'APPROVED BY',
        size: 100,
        enableGrouping: false
      },
      {
        accessorKey: 'approval_date',
        header: 'APPROVAL DATE',
        size: 80,
        enableGrouping: false
      },
      {
        accessorKey: 'migtype',
        header: 'MIGRATION TYPE',
        size: 80,
        enableGrouping: false
      },
      {
        accessorKey: 'product_manager',
        header: 'PROD MANAGER',
        size: 80,
        enableGrouping: false
      },
      {
        accessorKey: 'divisional_lead',
        header: 'DIV LEAD',
        size: 80,
        enableGrouping: false
      },
    ],
    [],
  );

  const handleRowSelection = (props) => {
    setRowSelection(props)
  }

  useEffect(() => {
    set_selected_rows_app_manager(tableRef)
  }, [rowSelection])
  const handleCheckBoxEvent = (table) => {

  }
  
  const saveTableState = ()=>{
    window.localStorage.setItem('app_manager_table_state',JSON.stringify(tableRef.current.getState()))
    notification.success({message: 'Table state saved.'})
  }
  const getSavedTableState = ()=>{
    let saved_state = window.localStorage.getItem('app_manager_table_state')
    if(saved_state){
      return JSON.parse(saved_state)
    }else{
      return {}
    }
  }
  window.tableRef = tableRef;

  return (
    <div>
      <MaterialReactTable
        columns={columns}
        data={search() || []}
        enableRowSelection
        enableColumnResizing
        muiTableProps={{
          sx: {
            tableLayout: 'fixed',
          },
        }}
        renderTopToolbarCustomActions={({ table }) => {
          return (
            <Space>
              <Button type="default" icon={<SaveOutlined />} size={"small"} onClick={saveTableState}>
                Save Table State
              </Button>
            </Space>
          );
        }}
        enableStickyHeader
        initialState={{
          grouping: ['appname', 'role'],
          density: 'compact',
          pagination: {
            pageSize: 25
          },
          ...getSavedTableState()
        }}
        enableGrouping
        muiSelectAllCheckboxProps={({ table }) => {
          HandleSelectedRows(table.getSelectedRowModel().rows);
          handleCheckBoxEvent(table)
        }}
        enablePagination
        muiTablePaginationProps={{
          showFirstButton: true,
          showLastButton: true,
          rowsPerPageOptions: [25, 50, 100, 250, 500, 1000],
        }}
        enableGlobalFilterModes
        tableInstanceRef={tableRef}
        state={{ rowSelection}}
        onRowSelectionChange={handleRowSelection}

      />
    </div>
  );
}

export default Table;
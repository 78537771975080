import { notification } from 'antd'
import useWebSocket from 'react-use-websocket'
import { shallow } from 'zustand/shallow'
import useGlobalStore from 'store'
import { fetchAuthSession } from '@aws-amplify/core';
import { useCallback, useRef, memo } from 'react';

const WebSocketConnect = memo(({ session }) => {
    let connection_url = process.env.REACT_APP_WEBSOCKET_URL
    const { incrNotificationCount, handle_incoming_notification } = useGlobalStore(
        (state) => ({
            incrNotificationCount: state.incrNotificationCount,
            handle_incoming_notification: state.handle_incoming_notification
        }),
        shallow
    );


    const { readyState } = useWebSocket(connection_url, {
        queryParams: {
            Authorization: `Bearer ${session?.tokens?.accessToken}`
        },
        share: true,
        reconnectAttempts: 25,
        heartbeat: {
            message: JSON.stringify({ action: 'ping' }),
            interval: 60000 * 2, // 2 mins
            timeout: 60000 * 60 //5 mins
        },
        onError: (e) => {
            notification.error({
                message: 'Realtime notifications not enabled',
                description: 'Other services will not be affected due to this',
                key: 'notif_error',
                duration: 1})
        },
        onMessage: (e) => {
            try {
                let data = JSON.parse(e.data)
                if (data?.type === 'ADD_NOTIFICATION') {
                    notification.info({ message: 'New Notification', key: 'new_notif' })
                    incrNotificationCount()
                }
                if (data?.type === 'NEW_NOTIFICATION') {
                    handle_incoming_notification(data)
                }
            } catch (e) {

            }
        },
        onOpen: (event) => {
            event.currentTarget.send(JSON.stringify({ action: 'ping' }))
        },
        onClose: (e) => {
            // notification.info({ message: 'Realtime notifications disabled', description: 'Connection to websocket was closed', key: 'notif_error' })
            console.log(e)
        }


    });

    return <></>
}, [])

export default WebSocketConnect
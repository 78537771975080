import ViewBuilder from 'components/ViewBuilder'
import appAxios from 'components/app_config/axios'
import * as storage from '../services/migserv_storage'
import { useEffect, useState, useRef } from 'react'
import { Col, Row, Segmented, Space, Tooltip, notification } from 'antd'
import { CheckCircleOutlined } from '@ant-design/icons'
import moment from 'moment'
import { getRelativeTime } from 'services/helper'
import JsonViewer from 'components/common/JsonViewer'
import useGlobalStore from 'store'
import { shallow } from 'zustand/shallow'
import OptType from 'components/App/OptType'

const NotificationsPage = () => {
    const [events, setEvents] = useState([])
    const [loggedInUser, setLoggedInUser] = useState(null)
    const paginationChangeCount = useRef(0);
    const [pagination, setPagination] = useState({
        pageSize: 100,
        pageIndex: 0
    })
    const [rowCount, setRowCount] = useState(Infinity)
    const filter = useRef({ event_type: 'UNVIEWED' })
    const { mark_as_read_api } = useGlobalStore(
        (state) => ({
            mark_as_read_api: state.mark_as_read_api,
        }),
        shallow
    );

    useEffect(() => {
        let user = storage.get('user.isid', null)
        setLoggedInUser(user)
    }, [])

    useEffect(() => {
        if (loggedInUser) {
            fetchEvents()
        }
    }, [loggedInUser])

    useEffect(() => {
        if (paginationChangeCount.current >= 1) {
            setEvents([])
            if (loggedInUser) {
                fetchEvents()
            }
        } else {
            paginationChangeCount.current = paginationChangeCount.current + 1
        }
    }, [pagination])

    const fetchEvents = async () => {
        let response = await appAxios.get(`/events?user=${loggedInUser}&limit=${pagination['pageSize']}&skip=${(pagination['pageIndex']) * pagination['pageSize']}`, {
            params: filter.current
        })
        if (Array.isArray(response.data)) {
            let events = response.data.map((event, index) => {
                return {
                    Occured: <Tooltip title={moment.utc(event['created_at']).local().format('YYYY-MM-DD HH:mm:ss')}>{getRelativeTime(moment.utc(event['created_at']))}</Tooltip>,
                    // SrNo: (index + 1) + ((pagination['pageIndex']) * pagination['pageSize']),
                    ...event,
                    Details: <JsonViewer jsonData={JSON.stringify(event['details_json'])} />,
                    status: event['success'] ? 'Succeeded' : 'Failed',
                    migtype: event['migtype'] == null ? '-' : event['migtype'],
                }
            })
            setEvents(events)
            if (events.length < pagination.pageSize) {
                setRowCount((pagination.pageIndex + 1) * pagination.pageSize)
            } else {
                setRowCount((pagination.pageIndex + 1) * pagination.pageSize * 2)
            }
        } else {
            setRowCount((pagination.pageIndex + 1) * pagination.pageSize)
        }
    }

    const onTypeChange = (value) => {
        setRowCount(Infinity)
        filter.current = ({ ...filter.current, event_type: value })
        fetchEvents()
    }

    const markAsRead = async (event_id = null) => {
        let confirm = event_id == null ? window.confirm('Are you sure ?') : true
        if (confirm) {
            notification.info({ message: 'Processing, please wait...', key: 'mark_as_read_notif' })
            let result = await mark_as_read_api(event_id)
            if (result.success) {
                notification.success({ message: "Operation successful !", key: 'mark_as_read_notif' });
                fetchEvents()
            } else {
                notification.error({ message: "Operation Failed !", description: result.error, key: 'mark_as_read_notif' })
            }
        }

    }




    return <>
        <Row justify={'center'} gutter={[0, 10]} >
            <Col span={22}></Col>

            <Col span={18} style={{ height: '100vh' }}>
                <ViewBuilder view_config={{
                    hide_view_details: true,
                    data: events,

                    initial_state_react_material_table: {
                        enableRowActions: filter.current['event_type'] == 'UNVIEWED' ? true : false,
                        renderRowActions: ({ cell, row, table }) => {
                            return (
                                filter.current['event_type'] == 'UNVIEWED' ? <Space>
                                    <CheckCircleOutlined onClick={() => markAsRead(row.original.event_id)} title="Mark as read" />
                                </Space> : <></>
                            );
                        },
                        renderTopToolbarCustomActions: ({ table }) => {
                            return <Space size={'large'}>
                                <Segmented
                                    size='large'
                                    options={[
                                        { label: 'Unread Notifications', value: 'UNVIEWED' },
                                        { label: 'All Notifications', value: 'ALL' },
                                    ]}
                                    onChange={onTypeChange}
                                    value={filter['event_type']}
                                />
                                {filter.current['event_type'] == 'UNVIEWED' ? <button onClick={() => markAsRead(null)} className="bannerBg" style={{ color: 'white', padding: '5px' }} as="button">Mark all as read <CheckCircleOutlined /> </button> : ''}
                            </Space>
                        },
                        enablePagination: true,
                        defaultColumn: {
                            size: 3,
                        },
                        // columnOrder: [
                        //     'Actions', 'event_id', 'Occured', 'event_name', 'status', 'submitted_by', 'migtype', 'Details'
                        // ],
                        columnVisibility: {
                            event_registry_id: false,
                            success: false,
                            event_id: false,
                            created_at: false,
                            Actions: true,
                            details_json: false
                        },
                        // sorting: ['created_at'],
                        pagination,
                        muiTablePaginationProps: {
                            rowsPerPageOptions: [5, 50, 100, 500],
                        },
                        onPaginationChange: setPagination,
                        manualPagination: true,
                        rowCount,
                    }
                }} />

            </Col>

        </Row>
    </>

}

export default NotificationsPage
import {useEffect, useState} from "react";
import {getQueryParams} from "services/helper";
import useGlobalStore from "store";
import {shallow} from "zustand/shallow";
import V2SingleQuestion from "./v2_SingleQuestion";
import _ from "lodash";
import {Col, Collapse, Divider, notification, Row, Tooltip,} from "antd";
import {STATUS_TYPES_META} from "./types";
import {retrieveUserInfo} from "services/migserv_auth";
import {InfoCircleOutlined} from "@ant-design/icons";

const V2Questionnaire = () => {
  const {v2_questionnaire_api} = useGlobalStore(
    (state) => ({
      v2_questionnaire_api: state.v2_questionnaire_api,
    }),
    shallow
  );

  const [questionnaire, setQuestionnaire] = useState([]);

  let {Panel} = Collapse;

  useEffect(() => {
    (async () => {
      let data = await fetch_questionnaire();
      setQuestionnaire(data);
    })();
  }, []);

  const fetch_questionnaire = async () => {
    let PK = getQueryParams()["PK"];
    if (PK) {
      // notification.info({ message: `Fetching Questionnaire for PK: ${PK}`, key: 'fetch' })
      let filterBy = {PK: PK, SK: "Q|"};

      let response = await v2_questionnaire_api(
        "getQuestionsForQuestionnaire",
        filterBy,
        undefined,
        undefined,
        undefined
      );

      if (response.success) {
        return response.data;
      } else {
        notification.error({
          message: `Questionnaire doesnt exists for the ${PK}`,
          key: "fetch",
        });
      }
    }

    return null;
  };

  const on_question_submit = async (question_key, changed_values) => {
    let filterBy = {
      PK: getQueryParams()["PK"],
      SK: Object.keys(changed_values)[0],
    };

    let result = await v2_questionnaire_api(
      "updateQuestion",
      filterBy,
      undefined,
      changed_values
    );

    if (result.success) {
      notification.success({
        message: "Update Successful",
        key: "update",
      });
      //TODO: bondes: Is this necessary?
      const updatedQuestionnaire = await fetch_questionnaire();
      setQuestionnaire(updatedQuestionnaire);
    } else {
      notification.error({
        message: "Failed to update questionnaire",
        description: result.error,
        key: "update",
      });
    }
  };

  const collapsiblePanelForQuestionnaire = () => {
    let logged_in_user = retrieveUserInfo();

    let ques = questionnaire?.map((question, index) => {
      return (
        <Collapse
          style={{
            backgroundColor: _.get(STATUS_TYPES_META, [
              question.q_status,
              "color",
            ]),
          }}
        >
          <Panel
            header={question.q_name}
            key={index}
            extra={
              <Tooltip placement="topRight" title={question.q_description}>
                <InfoCircleOutlined
                  style={{
                    cursor: "pointer",
                    paddingLeft: '8px'
                  }}
                />
              </Tooltip>
            }
          >
            <V2SingleQuestion
              questionnaire_key={question.PK}
              question_key={question.SK}
              admin_comments={question.admin_comments}
              responder_comments={question.responder_comments}
              q_status={question.q_status}
              updated_at={question.updated_at}
              updated_by={question.updated_by}
              on_submit={(changed_values) =>
                on_question_submit(question.SK, changed_values)
              }
              questionnaire_admin={logged_in_user["cognito:groups"].includes(
                "QUESTIONNAIRE_ADMIN"
              )}
            />
          </Panel>
        </Collapse>
      );
    });
    return ques;
  };

  return (
    <>
      <Divider></Divider>
      <Row align="middle" justify={"center"}>
        <Col span={22}>
          {collapsiblePanelForQuestionnaire()}
        </Col>
      </Row>
    </>
  );
};

export default V2Questionnaire;

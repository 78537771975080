import { Dropdown, MenuProps } from "antd";
import {DownOutlined} from '@ant-design/icons'
import { FC } from "react";
import { CommsEmailTypes } from "./CommsEmailTypes";

interface PropTypes {
    initiateFlow: (comms_email_type: CommsEmailTypes)=>any
}

const CommsEmailMenu:FC<PropTypes> = ({ initiateFlow = () => {} }) => {
  const onClick: MenuProps["onClick"] = ({ key }) => {
    //@ts-ignore
    initiateFlow(key)
  };
  const items: MenuProps["items"] = [
    {
      key: "email_migration_schedule",
      label: "Migration Schedule",
    },
    {
      key: "email_approval",
      label: "Approval",
    },
    {
      key: "email_app_shutdown",
      label: "App Shutdown",
    },
    {
      key: "email_in_progress",
      label: "In Progress",
    },
    // {
    //   key: "email_downtime_start",
    //   label: "Downtime Begins",
    // },
    // {
    //   key: "email_downtime_complete",
    //   label: "Downtime Ends",
    // },
    // {
    //   key: "email_hypercare_start",
    //   label: "Hypercare Begins",
    // },
    // {
    //   key: "email_migration_complete",
    //   label: "Migration Ends",
    // },
  ];
  return (
    <Dropdown menu={{ items, onClick }}>
      <button className="btns">Comms Email <DownOutlined /></button>
    </Dropdown>
  );
};

export default CommsEmailMenu;

export enum TableActionTypes {
    APPROVE = 'APPROVE',
    DROP = 'DROP',
    RESCHED = 'RESCHED',
    REQMIG = 'REQMIG'
}

export const TableActionTypeMeta: { [key in TableActionTypes]: {
    modal_title: string,
} } = {
    [TableActionTypes.APPROVE]: {
        modal_title: 'Approval',
    },
    [TableActionTypes.DROP]: {
        modal_title: 'Drop'
    },
    [TableActionTypes.RESCHED]: {
        modal_title: 'Reschedule'
    },
    [TableActionTypes.REQMIG]: {
        modal_title: 'Request Migration'
    },
}
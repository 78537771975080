import {Breadcrumb, Col, Divider, Row, Space} from "antd";
import {FC, useEffect, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import useGlobalStore from "store";
import {shallow} from "zustand/shallow";
import QuestionnaireDescription from "components/Questionnaire/QuestionnaireDescription";
import V2Questionnaire from "components/Questionnaire/v2/v2_Questionnaire";
import V2QuestionnaireTracking from "../../components/Questionnaire/v2/v2_QuestionnaireTracking";

interface QuestionnairePageProps {
}

const V2QuestionnairePage: FC<QuestionnairePageProps> = ({}) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const [showTrackingView, setShowTrackingView] = useState(true);
    const [breadcrumbItems, setBreadcrumbItems] = useState([
        {
            title: "Home",
        },
        {
            title: <a onClick={() => navigate("/v2/questionnaire")}>Questionnaire Tracking</a>,
        },
    ]);
    const {setQuestionnairePK, questionnaire_PK} = useGlobalStore(
        (state) => ({
            setQuestionnairePK: state.setQuestionnairePK,
            questionnaire_PK: state.questionnaire_PK,
        }),
        shallow
    );

    useEffect(() => {
        let PK = searchParams.get("PK");
        setQuestionnairePK("")
        if (PK !== null && questionnaire_PK !== PK) {
            setQuestionnairePK(PK);
            setShowTrackingView(false);
            setBreadcrumbItems([...breadcrumbItems, {title: "Questionnaire: " + PK}]);
        } else {
            setShowTrackingView(true);
            setBreadcrumbItems(breadcrumbItems.slice(0, 2));
        }
    }, [searchParams]);

    return (
        <>
            <Row justify={"center"} align="middle" style={{minHeight: "8vh"}}>
                <Col span={20}>
                    <Space direction="horizontal">
                        <Breadcrumb items={breadcrumbItems}/>
                    </Space>
                </Col>
                <Col span={10}></Col>
            </Row>
            {showTrackingView ? (
                <></>
            ) : (
                <Row justify={"center"} gutter={{sm: 8, md: 16, lg: 32}}>
                    <Col span={18}>
                        <QuestionnaireDescription PK={searchParams.get("PK")}/>
                    </Col>
                </Row>
            )}

            <Row justify={"center"} gutter={{sm: 8, md: 16, lg: 32}}>
                {/* <Divider /> */}
                <Col span={20} style={{backgroundColor: "rgba(128, 128, 128, 0.04)"}}>
                    {showTrackingView ? <V2QuestionnaireTracking/> : <V2Questionnaire/>}
                </Col>
            </Row>
            <Divider/>
        </>
    );
};

export default V2QuestionnairePage;

import { Tag, Input, Space, Row, Tooltip, Col } from "antd";
import { FC, useEffect, useState } from "react";
import { InfoCircleOutlined, UserOutlined } from "@ant-design/icons";
import { isValidEmail } from "../../services/helper";
import useGlobalStore from "../../store";
import { shallow } from "zustand/shallow";

interface AdditionalEmailsFieldProps {
  email_added_hook?: (cc_emails: string[]) => string[];
  email_removed_hook?: (cc_emails: string[]) => string[];
  max_width?: string | null;
  default_width?: string | null;
  max_height?: string | null;
  detach_from_central_store?: boolean;
  initial_email_list?: string[]
  allow_removing_emails?: boolean
  allow_adding_emails?: boolean
}

const AdditionalEmailsField: FC<AdditionalEmailsFieldProps> = ({
  email_added_hook,
  email_removed_hook,
  max_width = "750px",
  default_width = "750px",
  max_height = "100%",
  detach_from_central_store = false,
  initial_email_list = [],
  allow_removing_emails = true,
  allow_adding_emails = true
}) => {
  const [loading_val, set_loadingval] = useState(false);
  const [inputFieldError, setInputFieldError] = useState(false);
  const [inputFieldFocus, setInputFieldFocus] = useState(false);
  const [inputFieldValue, setInputFieldValue] = useState("");

  const [cc_emails_local, set_cc_emails_local] = useState<string[]>([]);

  const remove_cc_emails_local: (email: string) => any = (email) => {
    let mails = cc_emails_local;
    mails = mails.filter((item: string) => item !== email);
    set_cc_emails_local(mails);
  };

  const { cc_emails, set_cc_emails, remove_cc_emails } = useGlobalStore(
    (state) => ({
      cc_emails: state.cc_emails,
      set_cc_emails: state.set_cc_emails,
      remove_cc_emails: state.remove_cc_email,
    }),
    shallow
  );

  useEffect(() => {
    if (detach_from_central_store) {
      set_cc_emails_local(initial_email_list);
    }
    else {
      set_cc_emails(initial_email_list);
    }
    return
  }, []);

  useEffect(() => {
    if (isValidEmail(inputFieldValue)) {
      setInputFieldError(false);
    } else {
      setInputFieldError(true);
    }
  }, [inputFieldValue]);

  const onPressEnter = () => {
    if (!inputFieldError) {
      if (isDuplicate(inputFieldValue)) {
        window.alert("Given email is already in the list");
        return;
      }
      let email_list_updated = detach_from_central_store
        ? [...cc_emails_local, inputFieldValue]
        : [...cc_emails, inputFieldValue];
      detach_from_central_store ? set_cc_emails_local(email_list_updated) : set_cc_emails(email_list_updated);
      setInputFieldValue("");
      if (email_added_hook) {
        email_added_hook(email_list_updated);
      }
    }
  };

  const isDuplicate: (valid_email: string) => boolean = (valid_email) => {
    if (detach_from_central_store) {
      return cc_emails_local.find((item) => item === valid_email) === undefined ? false : true;
    } else {
      return cc_emails.find((item) => item === valid_email) === undefined ? false : true;
    }
  };

  const onRemoveEmail = (email: string) => {
    if (detach_from_central_store) {
      remove_cc_emails_local(email);
    } else {
      remove_cc_emails(email);
    }
    if (email_removed_hook) {
      if (detach_from_central_store) {
        email_removed_hook(cc_emails_local.filter((item) => item !== email));
      } else {
        email_removed_hook(cc_emails.filter((item) => item !== email));
      }
    }
  };

  const renderEmails = (emails: string[]) => {
    return emails.length > 0 ? (
      <>
        {emails.map((email) => (
          <Tag key={email} closable={allow_removing_emails} onClose={(e) => onRemoveEmail(email)}>
            {email}
          </Tag>
        ))}
      </>
    ) : null;
  };

  return (
    <Row>
      {allow_adding_emails ? <Col span={24}>
        <Input
          width={100}
          size="small"
          status={inputFieldError && inputFieldFocus ? "error" : ""}
          placeholder="Enter valid email for CC"
          prefix={<UserOutlined className="site-form-item-icon" />}
          suffix={
            inputFieldError && inputFieldFocus ? (
              <Tooltip title="Extra information">
                <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
              </Tooltip>
            ) : null
          }
          value={inputFieldValue}
          onChange={(event) => setInputFieldValue(event.target.value)}
          onFocus={() => setInputFieldFocus(true)}
          onBlur={() => setInputFieldFocus(false)}
          onPressEnter={(event) => onPressEnter()}
        />
      </Col> : null}


      <Col span={24}>
        <div
          style={{
            maxWidth: max_width ? max_width : "",
            width: default_width ? default_width : "",
            overflowY: "auto",
            maxHeight: max_height ? max_height : "",
          }}
        >
          {detach_from_central_store ? renderEmails(cc_emails_local) : renderEmails(cc_emails)}
        </div>
      </Col>
    </Row>
  );
};

export default AdditionalEmailsField;

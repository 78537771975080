import { FC, useState, useMemo, useEffect } from "react";
import {
  doesDateFallWithinXDaysOfGivenDate,
  extractEmailsFromString,
  getCurrentMerckWeek,
  getListOfMerckWeeksFromAGivenWeek,
} from "../../services/helper";
import { shallow } from "zustand/shallow";
import useGlobalStore from "../../store";
import { MaterialReactTable, MRT_Cell } from "material-react-table";
import { Button, Input, Modal, notification, Select } from "antd";
import * as storage from "../../services/migserv_storage";
import send_email from "../../services/send_email_api";
import AdditionalEmailsField from "../common/AdditionalEmailsField";
import moment from "moment";
import { get_comms_email_config, get_comms_email_config_with_cache, set_comms_email_config } from "store/CommsEmailConfigStore";
import appAxios from "components/app_config/axios";

interface ModalPropType {
  show?: boolean;
  handleClose?: () => void;
  onSubmit?: () => any;
  onCancel?: () => any;
  wavename?: any[];
}

const RescheduleModal_v2: FC<ModalPropType> = ({
  show = true,
  handleClose,
  wavename,
  onSubmit,
  onCancel,
}: any) => {
  let comms_email_type = 'email_reschedule_request'

  const { selected_rows_app_manager, approval_status_api, cc_emails } =
    useGlobalStore(
      (state) => ({
        selected_rows_app_manager: state.selected_rows_app_manager,
        approval_status_api: state.approval_status_api,
        cc_emails: state.cc_emails,
      }),
      shallow
    );
  let allowed_waves = getListOfMerckWeeksFromAGivenWeek(
    getCurrentMerckWeek(),
    6
  );

  const [showModal, setShowModal] = useState(false);
  const [responses, setResponses] = useState<any>({});

  useEffect(() => {
    if (showModal) {
      if (!isSingleApplicationGroup()) {
        notification.warning({
          message: "Action not allowed",
          description: `Please select servers from the same application group to continue.`,
        });
        setShowModal(false);
        return;
      }
      if (!validateRowsBeforeAction()) {
        notification.warning({
          message: "Action not allowed",
          description: `Your application selection is too close to the migration date, or you have selected an invalid application - please contact the migration team`,
        });
        setShowModal(false);
        return;
      }
      let _responses = {};
      selected_rows_app_manager.forEach((row) => {
        _responses = {
          ..._responses,
          [generate_key(row)]: {
            data: row,
            comment: null,
            reschedule_date: null,
          },
        };
      });
      setResponses(_responses);
    }
  }, [showModal]);
  const onCommentBlurr = function (value: string, row: any) {
    setResponses({
      ...responses,
      [generate_key(row)]: {
        data: row,
        comment: value,
        reschedule_date: responses?.[generate_key(row)]?.reschedule_date,
      },
    });
  };

  const generate_key = (row: any) => {
    return row?.["app_ci_id"] + row?.["servername"];
  };
  const onSelectChange = function (value: string, row: any) {
    setResponses({
      ...responses,
      [generate_key(row)]: {
        data: row,
        comment: responses?.[generate_key(row)]?.comment,
        reschedule_date: value,
      },
    });
  };

  const onSubmitClick = async () => {
    if (validateFormValues()) {
      await reschedule_api();
      setShowModal(false);
    } else {
      notification.error({
        message: "Some fields are not filled",
        description:
          "Please make sure to fill all the details before submitting",
        duration: 10,
        key: "notif_reschedule",
      });
    }
  };

  const reschedule_api = async () => {
    let failure_count = 0;
    let successful_app_server_responses: any = {};
    console.log(responses);
    let key: string = "";
    let response_keys = Object.keys(responses);
    for (let i = 0; i < response_keys.length; i++) {
      key = response_keys[i];
      notification.info({
        message: `Rescheduling ${i + 1} out of ${responses.length} app-servers`,
        key: "notif_reschedule",
        description: "Please wait",
      });
      let result = await approval_status_api(
        "RESCHEDULE REQUESTED",
        responses[key].data.app_ci_id,
        responses[key].data.servername,
        responses[key].data.migtype,
        responses[key].comment
      );
      if (result.error !== null) {
        failure_count++;
      } else {
        successful_app_server_responses[key] = responses[key];
      }
      console.log(successful_app_server_responses);
    }
    await request_reschedule_email(successful_app_server_responses);
    notification.open({
      type: failure_count > 0 ? "error" : "success",
      message: `Reschedule ${failure_count > 0 ? "failed for some servers" : "succeeded"
        }`,
      key: "notif_reschedule",
      description:
        failure_count > 0
          ? `Failed to Reschedule for ${failure_count} app-servers`
          : "Reschedule Completed",
    });
  };

  const validateFormValues: () => boolean = () => {
    let is_valid = true;
    Object.keys(responses).forEach((response: any) => {
      if (
        !(responses[response].reschedule_date && responses[response].comment)
      ) {
        is_valid = false;
      }
    });
    return is_valid;
  };

  const request_reschedule_email = async (responses: any) => {
    let html = await generate_email_html(responses)
    let email = storage.get<string>("user.email", "")
      ? storage.get<string>("user.email", "")
      : "";
    let comms_email_config = get_comms_email_config('Default', comms_email_type, null)
    let _cc_emails_arr = cc_emails
    let _to_emails_arr = typeof email === "string" ? [email] : []
    if (comms_email_config?.['cc_list']) {
      _cc_emails_arr.push(...extractEmailsFromString(comms_email_config?.['cc_list']))
    }
    if (comms_email_config?.['to_list']) {
      _to_emails_arr.push(...extractEmailsFromString(comms_email_config?.['to_list']))
    }
    let result = await send_email._post(
      _to_emails_arr.join(','),
      _cc_emails_arr.join(","),
      "Migration Services Re-Schedule Request ",
      html
    );
    console.log(result);
  };

  const generate_email_html = async (responses: any) => {
    let comms_email_config = await get_comms_email_config_with_cache('Default', comms_email_type, null)
    let template_html = comms_email_config?.['template_html']
    if (!template_html) {
      template_html = 'No Html Found !!'
    }

    let response_key: any = Object.keys(responses)[0]

    let replace_values: { [key: string]: string } = {
      migweek: responses[response_key].data.wave_name,
      appname: responses[response_key].data.appname,
      //@ts-ignore
      sender_name: storage.get('user.givenName', '') + ' ' + storage.get('user.familyName', ''),
      request_time: moment().toString(),
      app_table: build_table(responses)
    }

    Object.keys(replace_values).forEach((key) => {
      template_html = template_html.replaceAll(`\{\{${key}\}\}`, replace_values[key])
    })

    return template_html
  }

  const build_table: (responses: any) => string = (responses) => {
    let table_cols_object: any = {
      wave_name: 'Migration Week',
      migration_date: 'Date',
      appname: 'App Abbrvtn',
      app_project_name: 'App Name',
      servername: 'Server Name',
      role: 'Role',
      app_managed_by: 'Managed By',
      reschedule_date: 'Reschedule Date',
      comment: 'Reason for Reschedule',
      migtype: 'Migration Type'
    }
    let table_cols = ``
    Object.keys(table_cols_object).forEach((key) => {
      table_cols += `
        <td nowrap class="app_server_table">
            <p class="TableHeaderMerck" style="text-align: center">
            ${table_cols_object[key]}
            </p>
        </td>`
    })
    let table = `
    <table
    border="0"
    cellspacing="0"
    cellpadding="0"
    width="75%"
  >
  <thead>
  <tr>
     ${table_cols}
    </tr>
    </thead>
    <tbody>
 {{table_body}}
 </tbody>
  </table>
  `;

    let table_body = ``;
    let row_body = ``;
    let table_value_map: any = {}
    Object.keys(responses).forEach((key) => {
      let { data: row } = responses[key]
      table_value_map = {}
      Object.keys(table_cols_object).forEach((col) => {
        if (['reschedule_date', 'comment'].includes(col)) {
          table_value_map[col] = responses[key][col]
          return
        }
        table_value_map[col] = row?.[col] ? row[col] : "-"
      })

      row_body = "";
      Object.keys(table_value_map).forEach((key) => {
        row_body += `
                <td class="app_server_table_row">
                    <p  class="TableText" style="text-align: center">
                    <span style="color: black">${table_value_map[key]}</span>
                    </p>
                </td>`
      });
      table_body += `
        <tr>
        ${row_body}
        </tr>
      `;
    });

    table = table.replace(new RegExp(`\{\{table_body\}\}`, "g"), table_body);
    return table;
  }

  const validateRowsBeforeAction = () => {
    let isValid = true;
    let row: any = {};
    let current_date = new Date();
    for (let i = 0; i < selected_rows_app_manager.length; i++) {
      row = selected_rows_app_manager[i];
      if (row?.migration_date && row?.migration_date !== "") {
        if (
          doesDateFallWithinXDaysOfGivenDate(
            current_date,
            row?.migration_date,
            3
          )
        ) {
          isValid = false;
          break;
        }
      } else {
        isValid = false;
        break;
      }
    }
    return isValid;
  };

  const isSingleApplicationGroup = () => {
    let isValid = true
    let row: any = {}
    let appname = selected_rows_app_manager.length > 0 ? selected_rows_app_manager[0].appname : ''
    for (let i = 0; i < selected_rows_app_manager.length; i++) {
      row = selected_rows_app_manager[i]
      if (row.appname !== appname) {
        isValid = false
        break;
      }
    }
    return isValid
  }

  const columns = useMemo((): any => {
    console.log("useMemo invoked");
    return [
      {
        accessorKey: "reschedule_date",
        header: "Select Reschedule Date",
        size: 30,
        Cell: ({ row }: MRT_Cell) => {
          return (
            <Select
              size={"small"}
              defaultValue="Select Reschedule Date"
              onChange={(val) => onSelectChange(val, row ? row?.original : {})}
              options={allowed_waves.map((allowed_wave) => {
                return {
                  value: wavename.find(
                    (wave: any) =>
                      wave.wave_date_name.split(" ")[0] === allowed_wave
                  )?.wave_date_name,
                  label: wavename.find(
                    (wave: any) =>
                      wave.wave_date_name.split(" ")[0] === allowed_wave
                  )?.wave_date_name,
                };
              })}
            />
          );
        },
      },
      {
        accessorKey: "comment",
        header: "Comment",
        size: 20,
        Cell: ({ row }: MRT_Cell) => {
          return (
            <Input
              onBlur={(event) =>
                onCommentBlurr(event.target.value, row ? row?.original : {})
              }
              size="small"
              placeholder="Reschedule Comment"
            />
          );
        },
      },
      {
        accessorKey: "wave_name", //access nested data with dot notation
        header: "Migration Week",
        size: 10,
      },
      {
        accessorKey: "migration_date", //access nested data with dot notation
        header: "Date",
        size: 10,
      },
      {
        accessorKey: "appname", //access nested data with dot notation
        header: "App Name",
        size: 10,
      },
      {
        accessorKey: "servername", //normal accessorKey
        header: "Servername",
        size: 10,
      },
      {
        accessorKey: "role",
        header: "Role",
        size: 10,
      },
    ];
  }, [responses]);
  return (
    <>
      <button className="btns" onClick={() => setShowModal(true)}>
        Request Reschedule
      </button>
      <Modal
        destroyOnClose
        open={showModal}
        closable
        onCancel={() => setShowModal(false)}
        footer={null}
        title={"Reschedule Modal"}
        width={"850px"}
        style={{ overflowY: "auto", maxHeight: "550px" }}
      >
        <MaterialReactTable
          columns={columns}
          data={selected_rows_app_manager}
          enableRowSelection={false}
          enablePagination={false}
          enableStickyHeader
          initialState={{
            density: "compact",
            columnPinning: { left: ["reschedule_date", "comment"] },
          }}
        />
        <br />
        <AdditionalEmailsField initial_email_list={[]} />
        <br />
        <Button
          type="dashed"
          htmlType="button"
          onClick={onSubmitClick}
          title="submit"
        >
          Submit
        </Button>
      </Modal>
    </>
  );
};

export default RescheduleModal_v2;

import axios from 'axios'
import { fetchAuthSession } from '@aws-amplify/core';

const apiBaseUrl = process.env.REACT_APP_MIGSERV_API_URL

const appAxios = axios.create({
    baseURL: apiBaseUrl
})
appAxios.interceptors.request.use(async config => {
    let session = await fetchAuthSession()
    if (session?.tokens?.accessToken) {
        config.headers.Authorization = `Bearer ${session.tokens.accessToken}`;
    }
    return config;
});

export default appAxios
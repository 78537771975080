import { Col, Row, notification, Space } from "antd"
import _, { template } from "lodash"
import { useState, useEffect } from 'react'
import { comms_email_config } from "store/CommsEmailConfigStore"
import EditTemplate from "./EditTemplate"

const current_comms_map = {}
Object.keys(comms_email_config).forEach((migtype_or_default_key) => {
  Object.keys(comms_email_config[migtype_or_default_key]).forEach((comms_type_with_migtype_key) => {
    if (comms_email_config[migtype_or_default_key][comms_type_with_migtype_key]?.['template_html_path']) {
      if (current_comms_map[comms_type_with_migtype_key]) {
        current_comms_map[comms_type_with_migtype_key] = {
          ...current_comms_map[comms_type_with_migtype_key],
          [migtype_or_default_key]: {
            template_html_path: comms_email_config[migtype_or_default_key][comms_type_with_migtype_key]['template_html_path']
          }
        }
      } else {
        current_comms_map[comms_type_with_migtype_key] = {
          [migtype_or_default_key]: {
            template_html_path: comms_email_config[migtype_or_default_key][comms_type_with_migtype_key]['template_html_path']
          }
        }
      }
    }
  })
})

let final_comms_email_map = {}
Object.keys(current_comms_map).forEach((comms_email_type_key) => {
  Object.keys(current_comms_map[comms_email_type_key]).forEach((migtype_or_default_key) => {
    if (final_comms_email_map[comms_email_type_key]) {
      if (final_comms_email_map[comms_email_type_key][current_comms_map[comms_email_type_key][migtype_or_default_key]['template_html_path']]) {
        final_comms_email_map[comms_email_type_key][current_comms_map[comms_email_type_key][migtype_or_default_key]['template_html_path']]['migtypes'].push(migtype_or_default_key)
      } else {
        final_comms_email_map[comms_email_type_key][current_comms_map[comms_email_type_key][migtype_or_default_key]['template_html_path']] = {
          migtypes: [migtype_or_default_key]
        }
      }
    } else {
      final_comms_email_map[comms_email_type_key] = {
        [current_comms_map[comms_email_type_key][migtype_or_default_key]['template_html_path']]: {
          migtypes: [migtype_or_default_key]
        }
      }
    }
  })
})

const ManageCommsTemplates = ({ comms_email_type = '' }) => {

  const [comms_templates_object, set_comms_templates_object] = useState({})
  const [showEditModal, setShowEditModal] = useState(false)

  useEffect(() => {
    if (final_comms_email_map?.[comms_email_type]) {
      set_comms_templates_object(final_comms_email_map?.[comms_email_type])
    } else {
      notification.error({ message: 'No Templates Found for the given comms type' })
    }
  }, [comms_email_type])

  return (
    <>
      <Row>
        <Col span={10}>
          <h5>Select a template to edit:</h5>
          <ol>
            <Space direction="vertical">
              {Object.keys(comms_templates_object).map((template_path_str) => {
                return <li><Space direction="horizontal">
                  {comms_templates_object[template_path_str]?.['migtypes']?.join(',')}
                  <EditTemplate template_html_path={template_path_str} />
                </Space></li>
              })}
            </Space>
          </ol>

        </Col>
      </Row>
    </>
  )
}

export default ManageCommsTemplates
import React, { FC, useMemo } from "react";
import { ObjectDataType } from ".";
import MaterialReactTable, { MaterialReactTableProps } from "material-react-table";
import { Button, Space, ConfigProvider } from "antd";
import { DownloadOutlined, FileExcelOutlined } from "@ant-design/icons";
import { Icon } from "@ant-design/compatible";
import { ExportToCsv } from "export-to-csv";
import { utils, writeFile } from 'xlsx'
import _ from 'lodash'


interface ReactMaterialTableViewProps {
  columns: ObjectDataType;
  data: ObjectDataType;
  isLoading?: boolean;
  initialState?: { [key: string]: any };
  material_react_table_props?: MaterialReactTableProps<Record<any, any>>
  enableEditing?: boolean
}

const ReactMaterialTableViewV2: FC<ReactMaterialTableViewProps> = ({
  columns = [],
  data = [],
  isLoading = false,
  initialState = {},
  enableEditing = false,
  material_react_table_props = {}
}) => {
  const viewDataColumns = useMemo(() => columns, [columns]);
  const viewData = useMemo(() => data, [data]);

  const csvOptions: any = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    headers: columns.map((c) => c.header),
  };

  const exportDataToCsv = () => {
    const csvExporter = new ExportToCsv({ ...csvOptions, filename: `${new Date().toISOString()}_data` });
    csvExporter.generateCsv(data);
  };

  const exportDataToExcel = () => {
    const worksheet = utils.json_to_sheet(data);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Sheet1");
    writeFile(workbook, `${new Date().toISOString()}_datasheet.xlsx`);
  }

  return (
    <ConfigProvider theme={{}}>
      {React.createElement(MaterialReactTable, {
        //@ts-ignore
        columns: viewDataColumns,
        data: viewData,
        ..._.merge({
          renderTopToolbarCustomActions: ({ table }: any) => {
            return (
              <Space>
                <Button
                  type="default"
                  icon={<FileExcelOutlined />
                    // <Icon
                    //   component={() => <img src="icons/excel.svg"></img>}
                    //   style={{ fontSize: "10px" }}
                    // />
                  }
                  size={"small"}
                  onClick={exportDataToExcel}
                >
                  Download Excel
                </Button>
                <Button type="default" icon={<DownloadOutlined />} size={"small"} onClick={exportDataToCsv}>
                  Download CSV
                </Button>
              </Space>
            );
          },
          muiTableProps: {
            sx: {
              tableLayout: 'auto',
            },
          },
          initialState: {
            density: 'compact',
          },
        }, material_react_table_props)

      })}
    </ConfigProvider>
  );
};

export default ReactMaterialTableViewV2;

import appAxios from "../components/app_config/axios";
import useGlobalStore from "../store";
import { get } from './migserv_storage'


const base_url = process.env.REACT_APP_MIGSERV_API_URL ? process.env.REACT_APP_MIGSERV_API_URL : '';


async function _post(to: string, cc: string, subject: string, html: string, others: { [key: string]: any } = {}) {
    if (cc == null) {
        cc = ''
    }
    let logged_in_user = get('user.isid', null)

    if (process.env.REACT_APP_SEND_EMAILS == 'true') {
        let res = await appAxios.post(`${process.env.REACT_APP_MIGSERV_API_URL}/email?${logged_in_user ? 'user_isid=' + logged_in_user : ''}`, {
            from: "noreply-migsrv@merck.com",
            // TODO: Add replymail
            to: to,
            cc: cc,
            // TODO: Add bcc
            subject: subject ? subject : 'Automated Email',
            html: html,
            ...others
        }, {
        })
        if (res?.data?.renderedHtml) {
            return res.data.renderedHtml
        } else {
            return '<p>Preview email api failed</p>'
        }
    } else {
        console.log('Not triggering an email. Here is the data:')
        console.log({
            to, cc, subject, others
        })
        return new Promise((resolve, reject) => {
            resolve(null)
        })
    }
}

async function preview_email(to: string, cc: string, subject: string, html: string, others: { [key: string]: any } = {}) {
    try {
        let cc_add = useGlobalStore.getState().cc_emails.join(',')
        if (cc_add !== '') {
            cc = cc + ',' + cc_add
        }
        let res = await appAxios.post(`${process.env.REACT_APP_MIGSERV_API_URL}/preview_email`, {
            from: "noreply-migsrv@merck.com",
            to: to,
            cc: cc,
            subject: subject ? subject : 'Automated Email',
            html: html,
            fname: get('user.givenName', null),
            lname: get('user.familyName', null),
            ...others
        }, {
            // headers: {
            //     'Origin': '*'
            // }
        })
        if (res?.data?.renderedHtml) {
            return res.data.renderedHtml
        } else {
            return '<p>Preview email api failed</p>'
        }


    } catch (e) {

    }

}

const send_email = { preview_email, _post }

export default send_email

import { FC, useState, useEffect } from 'react'
import { Space, Row, Col, Button, Modal, notification, ConfigProvider } from 'antd'
import AdditionalEmailsField from '../common/AdditionalEmailsField';
import HtmlPreview from '../common/HtmlPreview';
import useGlobalStore from '../../store';
import { shallow } from 'zustand/shallow';
import { doesDateFallWithinXDaysOfGivenDate, extractEmailsFromString } from '../../services/helper';
import { TableActionTypes } from 'types/Action_Types';
import { get_comms_email_config, get_comms_email_config_with_cache, set_comms_email_config } from 'store/CommsEmailConfigStore';
import appAxios from 'components/app_config/axios';
import * as storage from '../../services/migserv_storage'
import moment from 'moment';
import get_date_config_property from 'store/DateConfigStore';
import * as send_email_api from '../../services/send_email_api'

interface ModalPropType {
    show: boolean,
    handleClose: () => void,
    onSubmit: () => any,
    onCancel: () => any,
    action_type: string,
    onSubmit_text: string,
    preview_email: (type: string, preview_only: boolean) => Promise<any>,
    tableRef?: { current: any }
}

const ApproveOrReqMigrationModal: FC<ModalPropType> = ({ show = true, handleClose, onSubmit, onCancel, action_type, onSubmit_text, preview_email }: any) => {
    let [previewEmailHtml, setPreviewEmailHtml] = useState<null | string>(null)
    let [showPreview, setShowPreview] = useState(false)
    let [showModal, setShowModal] = useState(false)
    let [processingSubmit, setProcessingSubmit] = useState(false)

    const { set_cc_emails, selected_rows_app_manager, cc_emails } = useGlobalStore((state) => ({
        set_cc_emails: state.set_cc_emails,
        cc_emails: state.cc_emails,
        selected_rows_app_manager: state.selected_rows_app_manager
    }), shallow)

    useEffect(() => {
        if (showPreview && previewEmailHtml == null) {
            generate_email_html(selected_rows_app_manager).then((result) => {
                setPreviewEmailHtml(result)
            })
        }
    }, [showPreview])

    const onActionButtonClick = () => {
        if (selected_rows_app_manager.length === 0) {
            notification.warning({
                message: 'Action not allowed',
                description: `Please select a few servers to continue`
            })
            return
        }
        if (action_type === TableActionTypes.APPROVE) {
            if (!validateRowsBeforeAction()) {
                notification.warning({
                    message: 'Action not allowed',
                    description: `Your application selection is too close to the migration date, or you have selected an invalid application - please contact the migration team`
                })
                return
            }
        }
        if (action_type === TableActionTypes.REQMIG) {
            if (!validateRowsBeforeRequestMigration()) {
                notification.warning({ message: 'Action not allowed', description: 'You have selected an Invalid application.' })
                return
            }
            if (!isSingleApplicationGroup()) {
                notification.warning({ message: 'Action not allowed', description: 'Please select servers from the same application group to continue.' })
                return
            }
        }
        let emails: string[] = []
        selected_rows_app_manager.forEach((item) => {
            emails = extractEmailsFromString(item?.product_manager)
            emails = [...emails, ...extractEmailsFromString(item?.divisional_lead)]
        })
        set_cc_emails(emails)


        setShowModal(true)
        setPreviewEmailHtml(null)
        setShowPreview(false)
    }

    const generate_email_html = async (selected_rows_app_manager: any[]) => {
        let comms_email_type = ''
        if (action_type === TableActionTypes.APPROVE) {
            comms_email_type = 'email_approval_confirmation'
        }
        if (action_type === TableActionTypes.REQMIG) {
            comms_email_type = 'email_request_migration'
        }
        let comms_email_config = await get_comms_email_config_with_cache('Default', comms_email_type, null)
        let template_html = comms_email_config?.['template_html']
        if (!template_html) {
            template_html = 'No Html Found !'
        }
        let replace_values: { [key: string]: string } = {
            migweek: selected_rows_app_manager[0].wave_name,
            appname: selected_rows_app_manager[0].appname,
            //@ts-ignore
            sender_name: storage.get('user.givenName', '') + ' ' + storage.get('user.familyName', ''),
            request_time: moment().format('ddd MMM DD YYYY'),
            app_table: build_table()
        }

        Object.keys(replace_values).forEach((key) => {
            template_html = template_html.replaceAll(`\{\{${key}\}\}`, replace_values[key])
        })

        return template_html
    }

    const build_table: () => string = () => {
        let table_cols_object: any = {
            migweek: 'Migration Week',
            migration_date: 'Date',
            appname: 'App Abbrvtn',
            app_project_name: 'App Name',
            servername: 'Servername',
            role: 'Role',
            app_managed_by: 'Managed By',
            migtype: 'Migration Type'
        }
        let table_cols = ``

        Object.keys(table_cols_object).forEach((key) => {
            if (key === 'app_project_name') {
                table_cols += `
            <td nowrap class="app_server_table" style="width: 25px">
                <p class="TableHeaderMerck" style="text-align: center">
                ${table_cols_object[key]}
                </p>
            </td>`
                return
            }
            table_cols += `
            <td nowrap class="app_server_table">
                <p class="TableHeaderMerck" style="text-align: center">
                ${table_cols_object[key]}
                </p>
            </td>`
        })
        let table = `
        <table
        border="0"
        cellspacing="0"
        cellpadding="0"
        width="75%"
        style="border: solid; "
      >
      <thead>
      <tr>
         ${table_cols}
        </tr>
        </thead>
        <tbody>
     {{table_body}}
     </tbody>
      </table>
      `;

        let table_body = ``;
        let row_body = ``;
        let table_value_map: any = {}
        let date: any = {}



        selected_rows_app_manager.forEach((row) => {
            table_value_map = {}
            Object.keys(table_cols_object).forEach((col) => {
                if (col == 'migweek') {
                    col = 'wave_name'
                }
                if (col === 'date') {
                    // fetch the migration date, appliy to table_value_map, return
                    //@ts-ignore
                    date = get_date_config_property(row.migtype, 'default_mig_date', null, null, row.role, true, 'YYYY-MM-DD')
                    if (date) {
                        table_value_map[col] = date['string_value']
                    } else {
                        table_value_map[col] = '-'
                    }
                    return
                }
                table_value_map[col] = row?.[col] ? row[col] : "-"
            })

            row_body = "";
            Object.keys(table_value_map).forEach((key) => {
                row_body += `
                    <td class="app_server_table_row">
                        <p  class="TableText" style="text-align: center">
                        <span style="color: black">${table_value_map[key]}</span>
                        </p>
                    </td>`
            });
            table_body += `
            <tr>
            ${row_body}
            </tr>
          `;
        });

        table = table.replace(new RegExp(`\{\{table_body\}\}`, "g"), table_body);
        return table;
    }

    const isSingleApplicationGroup = () => {
        let isValid = true
        let row: any = {}
        let appname = selected_rows_app_manager.length > 0 ? selected_rows_app_manager[0].appname : ''
        for (let i = 0; i < selected_rows_app_manager.length; i++) {
            row = selected_rows_app_manager[i]
            if (row.appname !== appname) {
                isValid = false
                break;
            }
        }
        return isValid
    }

    const validateRowsBeforeRequestMigration = () => {
        let row: any = {}
        let isValid = true
        for (let i = 0; i < selected_rows_app_manager.length; i++) {
            row = selected_rows_app_manager[i]
            if (typeof (row?.migration_date) === 'string' && row?.migration_date !== "") {
                isValid = false
                break
            }
        }
        return isValid
    }

    const validateRowsBeforeAction = () => {
        let isValid = true
        let row: any = {}
        let current_date = new Date()
        for (let i = 0; i < selected_rows_app_manager.length; i++) {
            row = selected_rows_app_manager[i]
            if (row?.migration_date && row?.migration_date !== "") {
                if (doesDateFallWithinXDaysOfGivenDate(current_date, row?.migration_date, 3)) {
                    isValid = false
                    break;
                }
            } else {
                isValid = false
                break;
            }
        }
        return isValid
    }

    const onSubmitAction = async () => {
        setProcessingSubmit(true)
        if (action_type === TableActionTypes.APPROVE) {
            let succeeded_approvals = []
            let failed_approvals_count = 0
            let approvals_count = 0
            let app_server: any = {}
            let username = storage.get('user.isid', '')
            let body = JSON.stringify({
                "status": "APPROVED",
                "comment": "Approval",
            });
            for (let i = 0; i < selected_rows_app_manager.length; i++) {
                notification.open({ message: `Migration Approval`, type: 'info', description: `Approving ${approvals_count + 1} App Servers`, duration: 10, key: 'notif_approval' })
                app_server = selected_rows_app_manager[i]
                try {
                    await appAxios.patch('/approval_status', body, {
                        params: {
                            app_ci_id: app_server.app_ci_id,
                            servername: app_server.servername,
                            username: username,
                            workflow: app_server.migtype
                        },
                        headers: { "content-type": "application/json" }
                    });
                    succeeded_approvals.push(app_server)
                } catch (e) {
                    failed_approvals_count++
                }
                ++approvals_count
            }
            await send_email(succeeded_approvals);
            notification.open({ message: `Migration Approval`, type: failed_approvals_count === 0 ? 'success' : 'error', description: `${failed_approvals_count === 0 ? 'Approvals Succeeded' : `Approval failed for ${failed_approvals_count} App Servers. Emails are only triggered for the succeeded approvals.`}`, duration: 10, key: 'notif_approval' })
        }
        if (action_type === TableActionTypes.REQMIG) {
            try {
                let result = await send_email(selected_rows_app_manager);
                notification.open({ message: `Migration Request`, type: 'success', description: `An email has been triggered for ${selected_rows_app_manager.length} servers requesting migration.`, duration: 10, key: 'notif_approval' })
            } catch (e) {
                notification.open({ message: `Migration Request failed`, type: 'error', description: `Failed to trigger an email for the selected servers. Please connect with migration services team for support.`, duration: 10, key: 'notif_approval' })
            }
        }
        setProcessingSubmit(false)
        setShowModal(false)
    }

    const send_email = async (selected_rows_app_manager: any[]) => {
        if (selected_rows_app_manager.length > 0) {
            let comms_email_type = ''
            let cc_list = ''
            let subject = 'No Subject'
            if (action_type === TableActionTypes.APPROVE) {
                comms_email_type = 'email_approval_confirmation'
                subject = `Migration Services Approval Confirmation - ${selected_rows_app_manager[0].wave_name} - ${selected_rows_app_manager[0].appname}`
            }
            if (action_type === TableActionTypes.REQMIG) {
                comms_email_type = 'email_request_migration'
                subject = `Migration Services Request Migration - ${selected_rows_app_manager[0].wave_name} - ${selected_rows_app_manager[0].appname}`
            }
            let comms_email_config = get_comms_email_config('Default', comms_email_type, null)
            if (comms_email_config?.cc_list) {
                cc_list = comms_email_config.cc_list
            }
            if (cc_list !== '') {
                cc_list += ','
            }
            cc_list += cc_emails.join(',')
            let html = await generate_email_html(selected_rows_app_manager)
            let to_email = storage.get('user.email', '')

            //@ts-ignore
            return send_email_api.default._post(to_email, cc_list, subject, html)
        }
        return
    }

    return (
        <>
            <button className="btns" onClick={onActionButtonClick}>
                {action_type === TableActionTypes.APPROVE ? 'Approve' : action_type === TableActionTypes.REQMIG ? 'Request Migration' : '--'}
            </button>
            <Modal
                destroyOnClose
                open={showModal}
                closable
                onCancel={() => setShowModal(false)}
                footer={null}
                title={action_type}
                width={"850px"}
                style={{ overflowY: "auto", maxHeight: "550px" }}
            >
                <Row>
                    <Col>
                        <Space direction='vertical'>
                            <Button type='dashed' onClick={() => setShowPreview(!showPreview)}>{!showPreview ? 'Preview Email' : 'Hide'}</Button>
                            {showPreview ? <HtmlPreview rawHtml={previewEmailHtml ? previewEmailHtml : '<p>Nothing to preview</p>'} useRaw /> : ''}
                        </Space>
                    </Col>
                    <Col span={24}>
                        <div>
                            <p>This {action_type} is for the following applications, servers and dates. </p>
                            <table>
                                <tr><th>Migration Week</th><th>Date</th><th>App Name</th><th>Server Name</th><th>Role</th></tr>
                                <tbody>
                                    {
                                        selected_rows_app_manager.map((x: any, xIndex: number) => {
                                            return <tr key={xIndex}><td>{x?.wave_name ? x?.wave_name : '-'}</td><td>{x?.migration_date}</td><td>{x?.app_project_name}</td><td>{x?.servername}</td><td>{x?.role}</td></tr>
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </Col>
                    <Col span={24}>
                        <p>The migration schedule for the application environment(s) listed above will be finalized.</p>
                        <p style={{ color: "red" }}>
                            ***Please double check the servers you want to Approve
                        </p>
                    </Col>
                    <Col span={24}>
                        <Space direction='vertical' size={20}>
                            <AdditionalEmailsField initial_email_list={[]} />

                            <Space>
                                <Button type="default" onClick={onSubmitAction} disabled={processingSubmit} >Approve</Button>
                                <Button type="dashed"  onClick={() => { setShowModal(false) }} disabled={processingSubmit}>Cancel</Button>
                            </Space>
                        </Space>
                    </Col>

                </Row >
            </Modal>
        </>
    );
}

export default ApproveOrReqMigrationModal
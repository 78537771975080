import { notification } from "antd";
import _ from "lodash";
import { MigTypes } from "types/migserv_types";
import { create } from "zustand";
import { AdditionalEmailsFieldInterface, createAdditionalEmailsFieldSlice } from "./AdditionalEmailsFieldStore";
import { ApiServiceStoreInterface, createApiServiceStoreSlice } from "./ApiServiceStore";
import { createMerckUserDirectorySlice, MerckUserDirectroryInterface } from "./MerckUserDirectoryStore";
import { QuestionnaireStoreInteface, createQuestionnaireSlice } from "./QuestionnaireStore";
import { createTableStoreSlice, TableStoreInterface } from "./TableStore";
import { createNotificationStoreSlice, NotificationStoreInterface } from "./NotificationStore";
import moment from "moment";
import { set_date_config } from "./DateConfigStore";
import * as storage from '../services/migserv_storage'
export interface GlobalStoreSlice {
  setAlert: (message: string, title?: string) => any;
  alert: { message: string; title: string };
  isLoading: boolean;
  setLoading: (status: boolean) => any;
  user_isids: string[];
  set_user_isids: (isids: string[]) => any;
  fetch_and_configure_master_data: () => Promise<boolean>;
  migtypes_and_roles_access: { role: string, migtype: string }[],
  get_migtype_by: (key: 'role', value: string) => string[]
}
export const createGlobalStoreSlice = (set: (state: any) => any, get: () => any): GlobalStoreSlice => ({
  migtypes_and_roles_access: [],
  get_migtype_by: (key = 'role', value = '') => {
    let result: string[] = []
    get().migtypes_and_roles_access.forEach((item: any) => {
      if (item?.[key] == value && item?.['migtype']) {
        result.push(item['migtype'])
      }
    })
    return result
  },
  setAlert: (message = "Error occured", title = "Error Title") => {
    set({ alert: { message, title } });
  },
  alert: { message: "", title: "" },
  isLoading: false,
  setLoading: (status) => set({ isLoading: status }),
  user_isids: [],
  set_user_isids: (isids) => set({ user_isids: isids }),
  fetch_and_configure_master_data: async () => {
    let migtypes = Object.keys(MigTypes);
    let result: any = {};
    result = await get().get_config_api(migtypes);
    if (!result.success) {
      notification.error({
        message: "Error fetching the defaults",
        description: "We were unable to fetch default migration schedules. Please contact the administrator.",
      });
      return false
    }
    result = result.data.migserv_config_get;
    if (result.length > 0) {
      let default_migration_dates = [];
      let default_mig_date_obj: any = {};
      let users: any = []
      let migtype = "";
      let prop_type = "datetime";
      let logged_in_user = storage.get('user.isid', null)
      result.forEach((config_obj: any) => {
        default_migration_dates = config_obj?.["default_migration_dates"];
        migtype = config_obj?.["migtype"];
        users = config_obj?.['users']

        // store default migration dates
        if (_.isArray(default_migration_dates)) {
          default_mig_date_obj = {}
          default_migration_dates.forEach((date) => {
            if (date?.environment === "unknown") {
              default_mig_date_obj["time"] = date.migration_time;
              default_mig_date_obj["weekday"] = moment(date.migration_day, "ddd").format("dddd");
              default_mig_date_obj["prop_type"] = prop_type;
            } else {
              default_mig_date_obj[date.environment] = {}
              default_mig_date_obj[date.environment]["time"] = date.migration_time;
              default_mig_date_obj[date.environment]["weekday"] = moment(date.migration_day, "ddd").format("dddd");
              default_mig_date_obj[date.environment]["prop_type"] = prop_type;
            }
          });
          set_date_config(migtype, { default_mig_date: default_mig_date_obj });
        }

        // Store the allowed migtypes
        if (_.isArray(users)) {
          users.forEach((role_username) => {
            if (role_username?.['role'] && role_username?.['username'] && role_username?.['username'] === logged_in_user) {
              set({ migtypes_and_roles_access: [...get().migtypes_and_roles_access, { role: role_username['role'], migtype }] })
            }
          })
        }

      });
      return true
    } else {
      return true
    }
  },
});

export type AllStoreSlices = AdditionalEmailsFieldInterface &
  QuestionnaireStoreInteface &
  GlobalStoreSlice &
  TableStoreInterface &
  MerckUserDirectroryInterface &
  ApiServiceStoreInterface &
  NotificationStoreInterface

const useGlobalStore = create<AllStoreSlices>((set, get: () => any) => ({
  ...createAdditionalEmailsFieldSlice(set, get),
  ...createQuestionnaireSlice(set, get),
  ...createGlobalStoreSlice(set, get),
  ...createTableStoreSlice(set, get),
  ...createMerckUserDirectorySlice(set, get),
  ...createApiServiceStoreSlice(set, get),
  ...createNotificationStoreSlice(set, get)
}));

export default useGlobalStore;

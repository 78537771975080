import { Tag } from "antd";
import _ from "lodash";
import { FC, useEffect, useState } from "react";
import { shallow } from "zustand/shallow";
import useGlobalStore from "../../store";

interface SchedulerTagProps {
  tag_key: string;
  tag_search_key: string;
  tag_value?: string;
  type?: "DIVLEAD" | "APPROVER" | "TESTER";
  closable?: boolean;
  onClose?: () => any;
}
const SchedulerTag: FC<SchedulerTagProps> = ({
  tag_search_key,
  tag_key,
  type,
  tag_value = "",
  closable = false,
  onClose,
}) => {
  const { get_user_by_queue, users } = useGlobalStore(
    (state) => ({
      get_user_by_queue: state.get_user_by_queue,
      users: state.users
    }),
    shallow
  );

  const [tagValue, setTagValue] = useState(tag_value);

  useEffect(() => {
    if (tagValue == "") {
      search_func();
    }
  }, []);

  useEffect(()=>{
    let key: 'ISID' | 'EMAIL' = "ISID";
    if (type === "DIVLEAD") {
      key = "EMAIL";
    }
    let search_key = key == 'ISID' ? _.toLower(tag_search_key) : tag_search_key 
    let _user = users.find((item)=>item[key] == search_key)
    if(_user){
        setTagValue(_user?.EMAIL)
    }

  },[users])

  const search_func = async () => {
    try {
      let key: 'ISID' | 'EMAIL' = "ISID";
      if (type === "DIVLEAD") {
        key = "EMAIL";
      }
      await get_user_by_queue(key, tag_search_key ? tag_search_key : "");
    } catch (e) {
      setTagValue('-');
    }
  };

  return (
    <Tag key={tag_key} closable={closable} onClose={onClose}>
      {tagValue}
    </Tag>
  );
};

export default SchedulerTag;

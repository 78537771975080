import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable'
import _ from 'lodash'

const DownloadWaveStatus = ({ rowInstance, tableRef }: any) => {
    let rows: any[] = []
    let single_wave_name_selected = true
    const onClick = () => {
        single_wave_name_selected = true
        rows = tableRef.current.getSelectedRowModel()?.rows
        rows = rows.map((item: any) => item.original) 
        if (rows.length > 0) {
            const wave_group = _.groupBy(rows, 'wave_name')
            if (Object.keys(wave_group).length > 1) {
                window.alert('Please select a single wave for the export')
            } else {
                let groups:any = {}
                let group_key = ''
                rows.forEach((row)=>{
                    group_key = row?.migtype + '__' + (row?.migration_date ? row.migration_date : 'null') + '__' + (row?.migration_time ? row?.migration_time : 'null')
                    if(Array.isArray(groups?.[group_key])){
                        groups[group_key].push(row)
                    }else{
                        groups[group_key] = [row]
                    }
                })
         
                let group_keys = Object.keys(groups)
                group_keys.forEach((group_key) => {
                    generatePdfWithTable(rows[0].wave_name, groups[group_key], group_key)
                })
          
            }
        } else {
            window.alert('Please select a valid group')
        }

    }

    const generateTableRows = (rows: any[] = []) => {
        let result: any[][] = []
        rows.sort((a,b)=>{
            let textA = a?.servername?.toUpperCase()
            let textB = b?.servername?.toUpperCase()
            return (textA < textB) ? -1 : (textA > textB) ? 1: 0
        })
        rows.forEach((row, index) => {
            result.push([row.servername, row.appname,row?.app_project_name && row?.app_project_name !== "" ? row.app_project_name : '-', row.approved_by == "" ? '-' : row.approved_by, row.approval_date ? row.approval_date : "-"])
        })
        return result

    }
    function generatePdfWithTable(wave_name = '', rows: any[] = [], group_key = '') {
        let body: any = generateTableRows(rows)
        const doc = new jsPDF()
        let timestamp = new Date().toUTCString()
        let group_key_arr = group_key.split('__')
        autoTable(doc, {
            head: [['Server Name', 'Application Name','App Project Name', 'Approved By', 'Approval Date']],
            body,
            theme: 'striped',
            headStyles: { fillColor: [0, 133, 124] },
            margin: { top: 40 },
            didDrawPage: function (data) {
                // Header
                doc.setFontSize(20)
                doc.setTextColor(40)
                doc.text(`${wave_name} ${group_key_arr[0]} Approvals`, data.settings.margin.left, 22)
                doc.setFontSize(10)
                doc.text(`Migration Date: ${group_key_arr[1] !== 'null' ? group_key_arr[1] : 'Not Available'}, Migration Time: ${group_key_arr[2] !== 'null' ? group_key_arr[2] : 'Not Available'}`, data.settings.margin.left, 28)
                doc.setFontSize(8)
                doc.text(`Printed ${timestamp}`, data.settings.margin.left, 34)
                //footer
                doc.setFontSize(10)
                var pageSize = doc.internal.pageSize
                var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
                doc.text(`Page ${data.pageCount}`, data.settings.margin.left, pageHeight - 10)
            }
        })
        doc.save(`Wave_Status_${group_key}`)
    }
    return <button className='btns' onClick={onClick}> Approvals PDF</button>
}

export default DownloadWaveStatus
import { useState, useEffect } from 'react';
import { Button, Select, Space, Tag, notification } from 'antd';
import { ClockCircleOutlined, LoadingOutlined } from '@ant-design/icons'
import { getCurrentMerckWeek, getListOfMerckWeeksFromAGivenWeek } from 'services/helper';
import useGlobalStore from 'store';
import { shallow } from 'zustand/shallow';


const generateWeekOpts = (in_progress_weeks: (string | null)[] = []) => {
    let current_week = getCurrentMerckWeek()
    let opts = [current_week, ...getListOfMerckWeeksFromAGivenWeek(current_week, 10)]
    return opts.map((w) => {
        if (in_progress_weeks.includes(w)) {
            return { disabled: true, label: <Space>{w} <ClockCircleOutlined color='yellow' /></Space>, value: w }
        } else {
            return { label: w, value: w }
        }
    })
}
const ApprovalsUpdate = () => {

    const [week_opts, setWeekOpts] = useState<any>([])
    const [selected_week, setSelectedWeek] = useState<string | null>(null)
    const [in_progress_weeks, setInProgressWeeks] = useState<(string | null)[]>([])
    const { post_approvals_update_api } = useGlobalStore(
        (state) => ({
            post_approvals_update_api: state.post_approvals_update_api,
        }),
        shallow
    );
    useEffect(() => {
        setWeekOpts(generateWeekOpts())
    }, [])

    useEffect(() => {
        setWeekOpts(generateWeekOpts(in_progress_weeks))
    }, [in_progress_weeks])

    const onSubmit = async (e: any) => {
        notification.info({ key: 'content_update', message: 'Submitting Request...', icon: <LoadingOutlined /> })
        setInProgressWeeks([...in_progress_weeks, selected_week])
        setSelectedWeek(null)
        if (selected_week) {
            let result = await post_approvals_update_api(selected_week)
            if (result.success) {
                notification.info({ key: 'content_update', message: 'Request Submitted', description: 'Approvals update in progress - You will be notified when the update is complete.' })
            } else {
                notification.error({ key: 'content_update', message: 'Request Failed', description: `Error Updating Approvals. Error Code ${result.code}` })
            }
        }
    }



    return (
        <Space direction='horizontal'>
            <Select
                value={selected_week}
                placeholder="Approvals Update"
                size="small"
                maxTagCount={'responsive'}
                style={{ width: 200 }}
                onChange={setSelectedWeek}
                options={week_opts}
            />
            <Button size="small" type='default' htmlType='button' onClick={onSubmit} disabled={selected_week == null}>Submit</Button>
        </Space>
    )
}

export default ApprovalsUpdate
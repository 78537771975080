import { FC, useState, useEffect } from 'react'
import { Button, Col, Divider, Modal, Row, Select, Tag, Tooltip, notification, Space } from 'antd'
import UserIsidField from '../common/UserIsidsField'
import appAxios from '../app_config/axios'
import useGlobalStore from '../../store'
import { shallow } from 'zustand/shallow'

interface ApproverTesterPropType {
    manage_type?: string
    tableRef?: { current: any }
}

const ApproverTester: FC<ApproverTesterPropType> = ({ manage_type = 'APPROVER', tableRef = { current: {} } }) => {
    const [showModal, setShowModal] = useState(false)
    const [title, setTitle] = useState('Manage')
    const [selectedRows, setSelectedRows] = useState([])
    const [selectMenuOptions, setSelectMenuOptions] = useState<any[]>([])
    const [selectMenuValue, setSelectMenuValue] = useState<any[]>([])

    const { user_isids, delete_approvers_testers_api,create_approvers_testers_api } = useGlobalStore((state) => ({
        user_isids: state.user_isids,
        delete_approvers_testers_api: state.delete_approvers_testers_api,
        create_approvers_testers_api: state.create_approvers_testers_api,
    }), shallow)


    useEffect(() => {
        setTitle(manage_type === 'APPROVER' ? 'Manage Approvers' : 'Manage Testers')
    }, [])


    const openModal = () => {
        setShowModal(true)
    }

    const onMainButtonClick = () => {
        let rows = tableRef.current.getSelectedRowModel()?.rows
        console.log(rows)
        if (rows?.length == 0) {
            return window.alert('Please select some servers to continue')
        } else {
            setSelectedRows(rows.map((item: any) => item.original))
            openModal()
        }
    }

    useEffect(() => {
        let options: any[] = []
        let defaultValue: any[] = []
        let value = ''
        selectedRows.forEach((item) => {
            value = `${item?.['migtype']};${item?.['sched_id']}`
            options.push({ label: item?.['appname'], value })
            defaultValue.push(value)
        })

        setSelectMenuOptions(options)
        setSelectMenuValue(defaultValue)


    }, [selectedRows])

    const onAddApproversTesters = async () => {
        if (user_isids.length == 0) {
            return window.alert(`Please enter at least one ${manage_type.toLowerCase()} to continue`)
        }

        let message = ''
        let failure = 0
        let result: any = {}
        for (let i = 0; i < selectMenuValue.length; i++) {
            message = `Updating ${manage_type === 'APPROVER' ? 'approvers' : 'testers'} for ${i + 1} out of ${selectMenuValue.length} application servers`
            notification.open({ message, description: 'Please wait', duration: 20, key: 'notif_approvers' })
            result = await create_approvers_testers_api(manage_type, selectMenuValue[i].split(';')[0], selectMenuValue[i].split(';')[1], user_isids.join(';'),false)
            if (!result.success) {
                failure++
                continue
            }

        }

        notification.open({ message: `${manage_type === 'APPROVER' ? 'Approvers' : 'Testers'} updated`, type: 'success', description: `${failure === 0 ? '' : `Update failed for ${failure} App Servers`}`, duration: 10, key: 'notif_approvers' })
    }

    const onDeleteApproversTesters = async () => {
        if (user_isids.length == 0) {
            return window.alert(`Please enter at least one ${manage_type.toLowerCase()} to continue`)
        }

        let message = ''
        let failure = 0

        for (let i = 0; i < selectMenuValue.length; i++) {
            message = `Deleting ${manage_type === 'APPROVER' ? 'approvers' : 'testers'} for ${i + 1} out of ${selectMenuValue.length} application servers`
            notification.open({ message, description: 'Please wait', duration: 20, key: 'notif_approvers' })
            let result = await delete_approvers_testers_api(manage_type, selectMenuValue[i].split(';')[0], selectMenuValue[i].split(';')[1], user_isids.join(';'),false)
            if (!result.success) {
                failure++
                continue
            }
        }
        notification.open({ message: `${manage_type === 'APPROVER' ? 'Approvers' : 'Testers'} updated`, type: 'success', description: `${failure === 0 ? '' : `Update failed for ${failure} App Servers`}`, duration: 10, key: 'notif_approvers' })
    }

    const closeModal = () => {
        setShowModal(false)
    }

    return (
        <>
            <button className='btns' onClick={onMainButtonClick}>{title}</button>
            <Modal
                destroyOnClose
                open={showModal}
                footer={null}
                title={title}
                onCancel={closeModal}
                width={'750px'}
                style={{ overflowY: 'auto', maxHeight: '550px' }}
            >
                <div>
                    <Divider />

                    <Row gutter={[10, 10]}>
                        <Col span={24}>
                            <p>Selected App-Servers</p>
                            <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
                                <Select
                                    style={{ width: '500px' }}
                                    mode="tags"
                                    options={selectMenuOptions}
                                    value={selectMenuValue}
                                    placeholder='Select servers'
                                    tagRender={(props) => <Tooltip title={props.value}><Tag {...props} closable={false}>{props.label}</Tag></Tooltip>}
                                >
                                </Select>
                            </div>
                        </Col>
                        <Col span={24}>
                            <p>Add {manage_type === 'APPROVER' ? 'Approvers' : 'Testers'}</p>
                            <UserIsidField max_width='500px' default_width='500px' />
                        </Col>
                        <Col span={10}>

                        </Col>
                    </Row>
                    <Row>
                        <Col span={16}>
                            <Space>
                                <Button type='default' danger onClick={onDeleteApproversTesters} >Delete {manage_type === 'APPROVER' ? 'approvers' : 'testers'}</Button>
                                <Button type='default' onClick={onAddApproversTesters} >Add {manage_type === 'APPROVER' ? 'approvers' : 'testers'}</Button>
                            </Space>
                        </Col>
                    </Row>
                </div>
            </Modal>

        </>
    )
}

export default ApproverTester
import Template from "../Header/Template";
import AlertComponent from "../common/AlertComponent";
import AppRoutes from "../AppRoutes";
import { useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { ConfigProvider } from "antd";
import WebSocketConnect from "components/common/WebSocketConnect";
import Authenticator from "./Authenticator";
import { AuthenticatorProvider } from "@aws-amplify/ui-react-core";
import { Amplify } from "aws-amplify";
import { auth_config } from "config/aws_exports";
Amplify.configure(auth_config)
function App() {

  return (
    <AuthenticatorProvider>
      <Authenticator>
        <ConfigProvider
          theme={{
            token: {
              // Seed Token
              colorPrimary: '#00857C',
            },
          }}
        >
          <div className="App">
            <BrowserRouter>
              <AlertComponent />
              <Template />
              <>
                <AppRoutes />
                {/* {process.env.REACT_APP_DISABLE_WEBSOCKET == 'true' ? <></> : <WebSocketConnect />} */}
              </>
            </BrowserRouter>
          </div>
        </ConfigProvider>
      </Authenticator >
    </AuthenticatorProvider>

  );
}

export default App;

import { Modal, Row, notification, Col, Button } from "antd"
import { useState } from 'react'
import { EditOutlined } from '@ant-design/icons'
import { template } from "lodash"
import appAxios from "components/app_config/axios"
import HtmlPreview from "components/common/HtmlPreview"
import { clear_comms_email_config_cache } from "store/CommsEmailConfigStore"


const EditTemplate = ({ template_html_path = '' }) => {

    const [showModal, setShowModal] = useState(false)
    const [template_html, set_template_html] = useState('<p>No HTML found</p>')
    const [updated_html_body, set_updated_html_body] = useState('')
    let baseURL = process.env.REACT_APP_MIGSERV_API_URL + '/email_templates'


    const onEdit = async () => {
        try {
            notification.info({ duration: 0, message: 'Fetching template, please wait...', key: 'axios_request' })
            let result = await appAxios.request({
                baseURL,
                url: template_html_path,
                method: "GET",
            });
            notification.success({ message: 'Fetched Successfully', key: 'axios_request', duration: 3 })
            set_template_html(result.data)
            setShowModal(true)
        } catch (e) {
            return notification.error({ message: 'No HTML Template Found for the given path: ' + template_html_path, key: 'axios_request', duration: 5 })
        }
    }

    const HtmlEdited = (updatedHtml = '') => {
        if (updatedHtml) {
            set_updated_html_body(updatedHtml)
        }
    }

    const onSaveTemplate = async () => {
        if (window.confirm('Are you sure you want to save?')) {
            // replace original html with a new body
            let updated_html = template_html + ''
            updated_html = updated_html.replace(/(<body[\s\S]*?>)[\s\S]*(<\/body>)/gmi,`$1\n${updated_html_body}\n$2`)
            notification.info({ duration: 0, message: 'Updating Template, please wait...', key: 'axios_request' })
            try{
                await appAxios.put(baseURL+template_html_path,{
                    html_value: updated_html
                })
                let subpath = template_html_path.split('/')
                clear_comms_email_config_cache(`email_${subpath[2]}.template_html`)
                notification.success({ duration: 3, message: 'Template updated successfully !', key: 'axios_request' })

            }catch(e){
                console.log(e)
                notification.error({ duration: 3, message: 'Failed to update the template', key: 'axios_request' })
            }
           
        }
    }

    return <>
        <EditOutlined onClick={onEdit} />
        <Modal
            destroyOnClose
            open={showModal}
            closable
            onCancel={() => setShowModal(false)}
            footer={null}
            title={template_html_path}
            he
            width={1000}
            style={{ overflowY: "auto" }}
        >
            <Row gutter={[0, 10]}>
                <Col span={24}>
                    <Button onClick={onSaveTemplate} type="default">Save Template</Button>
                </Col>
                <Col span={24}>
                    <HtmlPreview allowedit onHtmlEdited={HtmlEdited} rawHtml={template_html} style={{ maxHeight: '100vh' }} />
                </Col>
            </Row >
        </Modal>
    </>
}

export default EditTemplate
import { Row, Col,  Descriptions, Collapse, notification, Space, Button } from "antd"
import _ from "lodash";
import ViewBuilder from "components/ViewBuilder";
import SchedulerTag from "./SchedulerTag";
import { shallow } from "zustand/shallow";
import useGlobalStore from "store";
import { useEffect, useMemo, useState } from "react";
import {  DeleteTwoTone } from '@ant-design/icons'
const DetailsModal = ({ row }) => {
    const [entities, setEntities] = useState([])
    const [loading, setLoading] = useState(true)
    const [rowSelection, setRowSelection] = useState({})
    const { linked_entities } = useGlobalStore((state) => ({
        linked_entities: state.linked_entities,
    }), shallow)
    useEffect(() => {
        notification.info({ message: 'Retrieving data, please wait..', key: 'entities' })
        linked_entities('get', _.get(row, 'original.sched_id', null), false).then((result) => {
            if (result.success) {
                setEntities(process_entities(result.data))
                notification.destroy('entities')
            } else {
                notification.error({ message: 'Failed to retrieve data', key: 'entities' })
            }
            setLoading(false)

        })

    }, [])
    const process_entities = (entities) => {
        return entities.map((item) => {
            return {
                ...item,
                APPROVED: item['APPROVED'] ? 'Yes' : 'No',
            }
        })
    }
    const onRowSelectionChange = (change) => {
        setRowSelection(change)
    }

    return (
        <Row gutter={[10,10]}>
            <Col span={24}>
                <Collapse>
                    <Collapse.Panel header="Selection Details">
                        <Descriptions
                            bordered
                            layout="vertical"
                            size="small"
                            labelStyle={{}}
                        >
                            {
                                _.map(_.pick(row.original,
                                    ['wave_name', 'migtype', 'servername', 'appname', 'app_project_name', 'env']),
                                    (val, key) => <Descriptions.Item key={key} label={_.capitalize(key)}>{val}</Descriptions.Item>)
                            }
                        </Descriptions></Collapse.Panel>
                </Collapse>

            </Col>
            <Col span={24}>
                <ViewBuilder table_library="ReactMaterialTable_v2"
                    view_config={{
                        data: entities,
                        hide_view_details: true,
                        material_react_table_props: {
                            initialState: {
                                columnVisibility: { APPROVERS_ID: false },
                            },
                            state: {
                                isLoading: loading,
                                rowSelection
                            },
                            enableRowSelection: true,
                            onRowSelectionChange,
                            muiTableBodyRowProps: ({ row }) => {
                                return {
                                    onClick: (event) => {
                                        if (rowSelection[row.index]) {
                                            setRowSelection(_.omit(rowSelection, row.index));
                                        } else {
                                            setRowSelection({ ...rowSelection, [row.index]: true })
                                        }
                                    },
                                };
                            },
                            renderTopToolbar: ({ table }) => {
                                let selectionStatus = _.isEmpty(rowSelection)
                                return (<Row gutter={[10, 10]}>
                                    <Col>
                                        <Button type="dashed" icon={ <DeleteTwoTone twoToneColor={_.isEmpty(rowSelection) ? 'grey' : 'red'} />} disabled={selectionStatus} >Delete</Button>
                                    </Col>
                                    &nbsp;
                                </Row>
                                )
                            }
                        },
                    }} />
            </Col>
        </Row>
    )
}

export default DetailsModal
import {  Card, Col, Row } from "antd"
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash'
import useGlobalStore from "store"
import { shallow } from "zustand/shallow"

const OptType = () => {
    const router = useNavigate()
    const onCardClick = (type: 'approver' | 'scheduler') => {
        if (type == 'approver') {
            router('/app-manager')
        }
        if (type == 'scheduler') {
            router('/sched-approvers')
        }
    }
    const cardContent = (showTitle = true) => {
        return <>
            <Row justify="center" align="top" gutter={[10, 0]}>
                <Col span={12}>
                    <Card style={{ borderColor: '#00857C' }} hoverable onClick={() => onCardClick('approver')} >
                        <Row justify={'center'} align="middle" style={{ minHeight: '25vh' }} >
                            <Col span={8}></Col>
                            <Col span={8}>
                                <h2>Application Approver</h2>
                            </Col>
                            <Col span={8}></Col>
                        </Row>
                    </Card></Col>
                <Col span={12}>
                    <Card style={{ borderColor: '#00857C' }} hoverable onClick={() => onCardClick('scheduler')} >
                        <Row justify={'center'} align="middle" style={{ minHeight: '25vh' }} >
                            <Col span={8}></Col>
                            <Col span={8}>
                                <h2>Scheduling Admin</h2>
                            </Col>
                            <Col span={8}></Col>

                        </Row>
                    </Card></Col>
            </Row>
        </>
    }
    return <Row justify={'center'} align={'middle'} style={{ minHeight: '50vh' }} >
        <Col span={18}>
            <Row align={'top'} >
                <Col span={24}>
                    {cardContent(true)}
                    {/* <Collapse defaultActiveKey={collapse_role_selection_card ? '' : "main"}>
                        <Collapse.Panel key="main" header={`Welcome, ${retrieveUserInfo().userFirstname} ${retrieveUserInfo().userLastname}`}>
                            
                        </Collapse.Panel>
                    </Collapse> */}
                </Col>

            </Row>
        </Col>
    </Row>
}

export default OptType
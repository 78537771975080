import appAxios from "../components/app_config/axios";
import * as storage from "../services/migserv_storage";
import {AxiosError, isAxiosError} from "axios";
import {notification} from "antd";
import moment from "moment";
import {AllStoreSlices} from "store";
import _ from "lodash";
import {DatabaseManager} from "services/aws/db";
import {getUserIdentity} from "../services/auth";
import {STATUS_TYPES} from "../components/Questionnaire/v2/types";
import {QuestionnaireMigTypes} from "../types/migserv_types";

type ApiResponse = {
  success: boolean;
  code: number | string;
  data: any;
  error?: any;
  nextStartKey?: any;
};
const db_manager = new DatabaseManager()

export interface ApiServiceStoreInterface {
  delete_approvers_testers_api: (
    manage_type: string,
    workflow: string,
    schedule_id: string,
    approver_tester_str: string,
    show_notification: boolean
  ) => Promise<ApiResponse>;
  mark_as_read_api: (
    event_id: null | number,
  ) => Promise<ApiResponse>;
  create_approvers_testers_api: (
    manage_type: string,
    workflow: string,
    schedule_id: string,
    approver_tester_str: string,
    show_notification: boolean
  ) => Promise<ApiResponse>;
  _approvers_testers_api: (
    method: "put" | "delete",
    manage_type: string,
    workflow: string,
    schedule_id: string,
    approver_tester_str: string,
    show_notification: boolean
  ) => Promise<ApiResponse>;
  _approvers_testers_api$?: any;
  appinfo_api: (
    app_ci_id: string
  ) => Promise<ApiResponse>;
  approval_status_api: (
    status: "RESCHEDULE REQUESTED" | "DROP REQUESTED",
    app_ci_id: string,
    servername: string,
    workflow: string,
    comment: string
  ) => Promise<ApiResponse>;
  get_config_api: (migtype: string[], config_properties: string[]) => Promise<ApiResponse>;
  get_questionnaire_list_api: () => Promise<ApiResponse>;
  post_approvals_update_api: (
    week_name: string,
    file_name?: string
  ) => Promise<ApiResponse>;
  linked_entities: (
    method: "get" | "post" | "put",
    schedule_id: string,
    show_notification: boolean
  ) => Promise<ApiResponse>;
  v2_questionnaire_api: (
    method: "getQuestionnaireList" | "getQuestionnaireInfo" | "getQuestionsForQuestionnaire" | "getTemplateQuestions"
      | 'create' | 'update' | 'updateQuestionnaire' | 'updateQuestion' | 'deleteQuestionnaire' | 'deleteQuestion',
    filterBy?: any | undefined,
    fields?: string[] | undefined,
    body?: any | undefined
  ) => Promise<ApiResponse>;
}

export const createApiServiceStoreSlice = (set: (state: any) => any, get: () => AllStoreSlices): ApiServiceStoreInterface => ({
  _approvers_testers_api$: null,
  mark_as_read_api: async (event_id) => {
    try {
      let result = await appAxios.request({
        method: 'PATCH',
        url: '/events',
        params: {mark_all: event_id == null ? 'true' : 'false', user: storage.get("user.isid", "")},
        data: event_id ? {event_ids: [event_id]} : {}
      })
      if (result.status == 201) {
        return {
          success: true,
          code: "201",
          data: true,
          error: null,
        };
      }
      return handleError(new Error(result.statusText))

    } catch (e) {
      return handleError(e)
    }
  },
  delete_approvers_testers_api: async (...args) => {
    return get()._approvers_testers_api("delete", ...args);
  },
  create_approvers_testers_api: async (...args) => {
    return get()._approvers_testers_api("put", ...args);
  },
  _approvers_testers_api: async (
    method: "put" | "delete",
    manage_type,
    workflow,
    schedule_id,
    approver_tester_str,
    show_notification = false
  ) => {
    try {
      let params: { [key: string]: string } = {
        //@ts-ignore
        username: storage.get("user.isid", ""),
        [manage_type.toLowerCase()]: approver_tester_str,
        workflow,
        schedule_id,
      };
      let urlParams = new URLSearchParams();
      Object.keys(params).forEach((key) => {
        urlParams.set(key, params[key]);
      });

      let result = await appAxios.request({
        url: `/${manage_type === "APPROVER" ? "approvers" : "testers"}?${urlParams.toString()}`,
        method,
        data: {},
      });
      if (show_notification) {
        notification.success({message: `${manage_type.toLowerCase()} ${method === "put" ? "added" : "removed"}`});
      }
      set({
        _approvers_testers_api$: {},
      });
      return {
        success: true,
        code: result?.status ? result.status.toString() : "200",
        data: result.data,
        error: null,
      };
    } catch (e: any | AxiosError) {
      if (show_notification) {
        notification.error({
          message: `Failed to ${method === "put" ? "add" : "remove"} ${manage_type.toLowerCase()}`,
          description: e?.message ? e.message : "",
        });
      }
      return handleError(e);
    }
  },
  appinfo_api: async (
    app_ci_id: string
  ) => {
    try {
      let result = await appAxios.request({
        url: `/appinfo`,
        params: {
          app_ci_id: app_ci_id
        },
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      return {
        success: true,
        error: null,
        data: result.data,
        code: result.status.toString(),
      };
    } catch (e: any | AxiosError) {
      return handleError(e);
    }
  },
  approval_status_api: async (
    status: "RESCHEDULE REQUESTED" | "DROP REQUESTED",
    app_ci_id: string,
    servername: string,
    workflow: string,
    comment: string
  ) => {
    try {
      let result = await appAxios.request({
        url: `/approval_status`,
        params: {
          app_ci_id,
          servername,
          workflow,
          username: storage.get("user.isid", ""),
        },
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          status,
          comment,
        }),
      });

      return {
        success: true,
        error: null,
        data: result.data,
        code: result.status.toString(),
      };
    } catch (e: any | AxiosError) {
      return handleError(e);
    }
  },
  get_config_api: async (migtype = [], config_properties = [""]) => {
    try {
      let result = await appAxios.request({
        url: `/config`,
        params: {
          in_migtype: migtype.length == 0 ? "" : migtype.join(","),
          in_config: config_properties.length == 0 ? "" : config_properties.join(","),
        },
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      return {
        success: true,
        error: null,
        data: result.data,
        code: result.status.toString(),
      };
    } catch (e: any | AxiosError) {
      return handleError(e);
    }
  },
  get_questionnaire_list_api: async () => {
    try {
      let result = await appAxios.request({
        url: `/questions`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      return {
        success: true,
        error: null,
        data: result.data,
        code: result.status.toString(),
      };
    } catch (e: any | AxiosError) {
      return handleError(e);
    }
  },

  post_approvals_update_api: async (week_name, file_name = moment().unix().toString()) => {
    try {
      let result = await appAxios.request({
        baseURL: process.env.REACT_APP_MIGSERV_API_URL,
        url: `/content`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          week_data: week_name,
          filename: file_name
        }
      });
      let timeout = setTimeout(() => {
        notification.error({
          message: `Your approvals update for week: ${week_name} might have been failed`,
          description: 'Since you selected week for the update, it has been 5 minutes. Please try again or contact admin.'
        })
      }, 12 * 60000)
      get().watch_event('APPROVAL_DATA_UPDATE', file_name, function () {
        clearTimeout(timeout)
      })
      // let result: any = {}
      return {
        success: true,
        error: null,
        data: result?.data,
        code: result?.status?.toString(),
      };
    } catch (e: any | AxiosError) {
      console.log(e)
      return handleError(e);
    }
  },
  linked_entities: async (method, schedule_id, show_notification) => {
    try {
      let result = await appAxios.request({
        url: '/approvers',
        method: method.toUpperCase(),
        params: {
          sched_id: schedule_id
        }
      })
      return {
        success: true,
        code: '200',
        error: null,
        data: result.data
      }
    } catch (e: any | AxiosError) {
      console.log(e)
      return handleError(e);
    }

  },
  v2_questionnaire_api: async (
    method,
    filterBy,
    fields,
    body) => {

    try {
      if (method == 'getQuestionnaireList') {

        let projectionExpression = fields
          ? fields.join(', ')
          : "PK, SK, app_ci_id, mig_type, app_name, q_status, created_at, completed_at"
        let filterExpression = `PK = SK`
        // let expressionAttributeValues = {':entityTypeValue': 'Questionnaire'}

        return await db_manager.getAllObjects(
          process.env.REACT_APP_DYNAMO_DB_QUESTIONNAIRE_TABLE_NAME || "",
          filterExpression,
          undefined,
          projectionExpression)

      } else if (method == 'getQuestionnaireInfo') {

        let projectionExpression = fields
          ? fields.join(', ')
          : "PK, SK, app_ci_id, mig_type, q_status, created_at, completed_at"
        let key = {PK: _.get(filterBy, 'PK', ''), SK: _.get(filterBy, 'SK', '')}

        return await db_manager.getObjectById(
          process.env.REACT_APP_DYNAMO_DB_QUESTIONNAIRE_TABLE_NAME || "",
          key,
          projectionExpression)

      } else if (method == 'getQuestionsForQuestionnaire') {

        let projectionExpression = fields
          ? fields.join(', ')
          : undefined
        let keyConditionExpression = `PK = :pkValue and begins_with(SK, :skValue)`
        let expressionAttributeValues = {
          ':pkValue': _.get(filterBy, 'PK', ''),
          ':skValue': _.get(filterBy, 'SK', '')
        }

        return await db_manager.queryTable(
          process.env.REACT_APP_DYNAMO_DB_QUESTIONNAIRE_TABLE_NAME || "",
          keyConditionExpression,
          undefined,
          expressionAttributeValues,
          projectionExpression
        )

      } else if (method == 'getTemplateQuestions') {
        // Check if mig_type is one of which Templated questions are defined, else fall back to Default
        let mig_type = _.get(QuestionnaireMigTypes, _.get(filterBy, 'PK', ''), 'Default')
        let keyConditionExpression = 'PK = :pk_value'
        let expressionAttributeValues = {
          ':pk_value': mig_type
        }
        let template_response = await db_manager.queryTable(
          process.env.REACT_APP_DYNAMO_DB_QUESTIONNAIRE_TABLE_NAME || "",
          keyConditionExpression,
          undefined,
          expressionAttributeValues)

        if (!template_response.success) {
          // Template Questions for given mig_type not found, fall back to Default questions
          expressionAttributeValues = {
            ':pk_value': 'Default'
          }
          template_response = await db_manager.queryTable(
            process.env.REACT_APP_DYNAMO_DB_QUESTIONNAIRE_TABLE_NAME || "",
            keyConditionExpression,
            undefined,
            expressionAttributeValues)
        }
        return template_response

      } else if (method == 'create') {
        if (_.isObject(body)) {
          return await db_manager.createObject(
            process.env.REACT_APP_DYNAMO_DB_QUESTIONNAIRE_TABLE_NAME || "",
            body)
        }
      } else if (method == 'update') {
        if (_.isObject(body)) {
          return await db_manager.updateObject(
            process.env.REACT_APP_DYNAMO_DB_QUESTIONNAIRE_TABLE_NAME || "",
            body)
        }
      } else if (method == 'updateQuestion') {
        let PK = _.get(filterBy, 'PK', '')
        let updateExpression = 'SET '
        let key = {PK: PK, SK: _.get(filterBy, 'SK', '')}
        let expressionAttributeValues: any = {}
        let date_now_utc = moment.utc().toISOString()
        let updated_by = (await getUserIdentity()).email

        // Only one question exits in body
        Object.keys(body).forEach((question_id) => {
          if (body[question_id]['q_name']) {
            updateExpression += 'q_name = :q_name, '
            expressionAttributeValues[':q_name'] = body[question_id]['q_name']
          }
          if (body[question_id]['admin_comments']) {
            updateExpression += 'admin_comments = :admin_comments, '
            expressionAttributeValues[':admin_comments'] = body[question_id]['admin_comments']
          }
          if (body[question_id]['responder_comments']) {
            updateExpression += 'responder_comments = :responder_comments, '
            expressionAttributeValues[':responder_comments'] = body[question_id]['responder_comments']
          }
          if (body[question_id]['q_status']) {
            updateExpression += 'q_status = :q_status_value, '
            expressionAttributeValues[':q_status_value'] = body[question_id]['q_status']
            if (body[question_id]['q_status'] === STATUS_TYPES.COMPLETE) {
              updateExpression += 'completed_at = :completed_at_value, '
              expressionAttributeValues[':completed_at_value'] = date_now_utc
              updateExpression += 'completed_by = if_not_exists(completed_by, :completed_by_value), '
              expressionAttributeValues[':completed_by_value'] = updated_by
            } else if (body[question_id]['q_status'] === STATUS_TYPES.SUBMITTED) {
              updateExpression += 'started_at = if_not_exists(started_at, :started_at_value), '
              expressionAttributeValues[':started_at_value'] = date_now_utc
              updateExpression += 'started_by = if_not_exists(started_by, :started_by_value), '
              expressionAttributeValues[':started_by_value'] = updated_by
            }
          }
        })
        updateExpression += 'updated_at = :updated_at, '
        expressionAttributeValues[':updated_at'] = date_now_utc

        updateExpression += 'updated_by = :updated_by, '
        expressionAttributeValues[':updated_by'] = updated_by
        updateExpression = updateExpression.trim()
        updateExpression = updateExpression.endsWith(',')
          ? updateExpression.substring(0, updateExpression.length - 1)
          : updateExpression

        let response_question =  await db_manager.updateRecord(
          _.get(process.env, 'REACT_APP_DYNAMO_DB_QUESTIONNAIRE_TABLE_NAME', ''),
          key,
          updateExpression,
          undefined,
          undefined,
          expressionAttributeValues
        )

        if (response_question && response_question.success) {
          updateQuestionnaireStatusBasedOnQuestions(PK)
        }

        return response_question

      } else if (method == 'updateQuestionnaire') {

        let updateExpression = 'SET '
        let key = {PK: _.get(filterBy, 'PK', ''), SK: _.get(filterBy, 'SK', '')}
        let expressionAttributeValues: any = {}
        let date_now_utc = moment.utc().toISOString()
        let updated_by = (await getUserIdentity()).email

        if (body["viewed_at"]) {
          updateExpression += 'viewed_at = :viewed_at, '
          expressionAttributeValues[':viewed_at'] = body["q_status"]
        } else {

          if (body["q_status"]) {
            updateExpression += 'q_status = :q_status, '
            expressionAttributeValues[':q_status'] = body["q_status"]
          }

          updateExpression += 'updated_at = :updated_at, '
          expressionAttributeValues[':updated_at'] = date_now_utc
          updateExpression += 'updated_by = :updated_by, '
          expressionAttributeValues[':updated_by'] = updated_by
        }

        updateExpression = updateExpression.trim()
        updateExpression = updateExpression.endsWith(',')
          ? updateExpression.substring(0, updateExpression.length - 1)
          : updateExpression

        return await db_manager.updateRecord(
          _.get(process.env, 'REACT_APP_DYNAMO_DB_QUESTIONNAIRE_TABLE_NAME', ''),
          key,
          updateExpression,
          undefined,
          undefined,
          expressionAttributeValues
        )

      } else if (method == 'deleteQuestionnaire') {

        let key = {PK: _.get(filterBy, 'PK', '')}

        await db_manager.deleteObject(
          _.get(process.env, 'REACT_APP_DYNAMO_DB_QUESTIONNAIRE_TABLE_NAME', ''),
          key,
          undefined,
          undefined
        )
      } else if (method == 'deleteQuestion') {

        let key = {PK: _.get(filterBy, 'PK', ''), SK: _.get(filterBy, 'SK', '')}

        await db_manager.deleteObject(
          _.get(process.env, 'REACT_APP_DYNAMO_DB_QUESTIONNAIRE_TABLE_NAME', ''),
          key,
          undefined,
          undefined
        )
      }
      return {
        success: false,
        code: 500,
        data: null,
        error: null
      }

    } catch (e: any | AxiosError) {
      console.log(e)
      return handleError(e);
    }

  }
});


const updateQuestionnaireStatusBasedOnQuestions = async (PK: string) => {

  let projectionExpression = "PK, SK, q_status"
  let keyConditionExpression = `PK = :pkValue and begins_with(SK, :skValue)`
  let expressionAttributeValues = {
    ':pkValue': PK,
    ':skValue': "Q|"
  }

  let response_questions = await db_manager.queryTable(
    process.env.REACT_APP_DYNAMO_DB_QUESTIONNAIRE_TABLE_NAME || "",
    keyConditionExpression,
    undefined,
    expressionAttributeValues,
    projectionExpression
  )

  if (response_questions && response_questions.success && response_questions.data) {
    // Check each Question status to determine the Questionnaire status
    let all_questions_complete = response_questions.data
      .every((question: any) => question.q_status === STATUS_TYPES.COMPLETE)
    let all_questions_submitted = response_questions.data
      .every((question: any) => question.q_status === STATUS_TYPES.SUBMITTED)
    let any_questions_need_response = response_questions.data
      .some((question: any) => question.q_status === STATUS_TYPES.NEED_RESPONSE)
    let any_questions_in_progress = response_questions.data
      .some((question: any) => (question.q_status === STATUS_TYPES.SUBMITTED || question.q_status === STATUS_TYPES.COMPLETE))

    let updateExpression = 'SET '
    let key = {PK: PK, SK: PK}
    let expressionAttributeValues: any = {}
    let date_now_utc = moment.utc().toISOString()
    let updated_by = (await getUserIdentity()).email

    if (all_questions_complete) {
      // If all questions (within a Questionnaire) are Complete, then update the Questionnaire status to Complete
      updateExpression += 'q_status = :q_status, '
      expressionAttributeValues[':q_status'] = STATUS_TYPES.COMPLETE
      updateExpression += 'completed_at = :completed_at, '
      expressionAttributeValues[':completed_at'] = date_now_utc
      updateExpression += 'completed_by = :completed_by, '
      expressionAttributeValues[':completed_by'] = updated_by
    } else if (all_questions_submitted) {
      // If all questions are submitted, then update the Questionnaire status to Submitted (Indication that Admin needs to Approve)
      updateExpression += 'q_status = :q_status, '
      expressionAttributeValues[':q_status'] = STATUS_TYPES.SUBMITTED
    } else if (any_questions_need_response) {
      // If any questions has Needs Response, mark the Questionnaire as Needs Response
      updateExpression += 'q_status = :q_status, '
      expressionAttributeValues[':q_status'] = STATUS_TYPES.NEED_RESPONSE
    } else if (any_questions_in_progress) {
      // If any questions is submitted, mark the Questionnaire as In Progress
      updateExpression += 'q_status = :q_status, '
      expressionAttributeValues[':q_status'] = STATUS_TYPES.IN_PROGRESS
      updateExpression += 'started_at = :started_at, '
      expressionAttributeValues[':started_at'] = date_now_utc
      updateExpression += 'started_by = :started_by, '
      expressionAttributeValues[':started_by'] = updated_by
    }

    updateExpression += 'updated_at = :updated_at, '
    expressionAttributeValues[':updated_at'] = date_now_utc
    updateExpression += 'updated_by = :updated_by, '
    expressionAttributeValues[':updated_by'] = updated_by

    updateExpression = updateExpression.trim()
    updateExpression = updateExpression.endsWith(',')
      ? updateExpression.substring(0, updateExpression.length - 1)
      : updateExpression

    await db_manager.updateRecord(
      _.get(process.env, 'REACT_APP_DYNAMO_DB_QUESTIONNAIRE_TABLE_NAME', ''),
      key,
      updateExpression,
      undefined,
      undefined,
      expressionAttributeValues
    )
  }

}

const handleError = (e: any) => {
  if (isAxiosError(e)) {
    return {
      success: false,
      data: null,
      error: e.message,
      code: e?.code ? e.code : "500",
    };
  }
  return {
    success: false,
    data: null,
    error: e,
    code: "500",
  };
};

import _ from "lodash"
import { AllStoreSlices } from "store"
import { MigservEvents } from "types/migserv_types"
import { notification as antd_notification } from 'antd'

export interface NotificationStoreInterface {
    notification_count: number,
    incrNotificationCount: () => void,
    handle_incoming_notification: (notification: any) => void
    events_under_watch?: { [key in MigservEvents]?: Function | null },
    watch_event: (event_name: MigservEvents, key: string, action?: Function | null) => void,
    unwatch_event: (event_name: MigservEvents, key: string, process_action?: boolean) => Promise<any>
}
export const createNotificationStoreSlice = (set: (state: any) => any, get: () => AllStoreSlices): NotificationStoreInterface => ({
    notification_count: 0,
    handle_incoming_notification: (notification = {}) => {
        if (notification?.['event_name']) {
            get().incrNotificationCount()
            let event_name = notification?.['event_name']
            if (event_name === 'APPROVAL_DATA_UPDATE') {
                if (notification?.['details_json']?.['status'] == 'COMPLETE') {
                    get().unwatch_event(event_name, notification?.['details_json']?.['file'])
                    antd_notification.success({
                        message: `Approvals Data Update Successful for Week: ${notification?.['details_json']?.['weeks']?.join(',')}`,
                        duration: 0
                    })
                }
                return
            }

            notification.info({ message: 'New Notification', description: event_name })
        }
    },
    incrNotificationCount: () => {
        set({ notification_count: get().notification_count + 1 })
    },
    events_under_watch: {},
    watch_event: (event_name, key, action = null) => {
        set({ events_under_watch: _.merge(get().events_under_watch, { [event_name]: { [key]: action } }) })
    },
    unwatch_event: async (event_name, key, process_action = true) => {
        let path = `${event_name}.${key}`
        let action = _.get(get().events_under_watch, path)
        set({ events_under_watch: _.omit(get().events_under_watch, path) })
        if (action && process_action) {
            if (typeof (action) == 'function') {
                let result = action()
                if (result instanceof Promise) {
                    return await result
                } else {
                    return result
                }
            }
        }
        return action
    }
})
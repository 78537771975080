import { Result } from "antd"
import {LoadingOutlined} from '@ant-design/icons'

const LoadingResult = () => {


    return <Result
        icon={<LoadingOutlined/>}
        
        title="Loading, please wait..."
        // subTitle="Order number: 2017182818828182881 Cloud server configuration takes 1-5 minutes, please wait."
        extra={[
            
        ]}
    />
}

export default LoadingResult
/* eslint-disable no-redeclare */

export function get<T>(key: string, defaultValue?: T): T | undefined {
  const value = localStorage.getItem(`migserv.${key}`);
  if (value === null) return defaultValue;
  try {
    return JSON.parse(value);
  } catch {
    return defaultValue;
  }
}

export function set<T>(key: string, value: T): void {
  localStorage.setItem(`migserv.${key}`, JSON.stringify(value));
}

export function remove(key: string): void {
  localStorage.removeItem(`migserv.${key}`);
}

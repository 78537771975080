import {
    Routes,
    Route,
    useNavigate
} from "react-router-dom"
import ConfigPage from "../pages/ConfigPage"
import GenericViewPageV2 from "../pages/GenericViewPageV2"
import AppManagerAdmin from "./Admin/AppManagerAdmin"
import SchedulerAdmin from "./Scheduler/SchedulerAdmin"
import NotificationsPage from "pages/Notifications"
import OptType from "./App/OptType"
import V2QuestionnairePage from "pages/v2/V2QuestionnairePage"
import {useEffect} from 'react'
const AppRoutes = () => {
    const router = useNavigate()
    useEffect(() => {
        let route_to = window.localStorage.getItem('redirect_to_path')
        if (route_to) {
            window.localStorage.removeItem('redirect_to_path')
            setTimeout(() => router(route_to))
        }
    }, [])
    return (
        <Routes>
            <Route path="/" element={<OptType />}></Route>
            <Route path="/notifications" element={<NotificationsPage />}></Route>
            <Route path="/app-manager" element={<AppManagerAdmin />}></Route>
            <Route path="/v2/questionnaire" element={<V2QuestionnairePage />}></Route>
            <Route path="/sched-approvers" element={<SchedulerAdmin />}></Route>
            <Route path="/view" element={<GenericViewPageV2 />}></Route>
            <Route path="/config" element={<ConfigPage />}></Route>
        </Routes>
    )
}

export default AppRoutes
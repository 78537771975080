import { FC, useState, useMemo, useEffect } from "react";
import {
  doesDateFallWithinXDaysOfGivenDate,
  extractEmailsFromString,
  getCurrentMerckWeek,
  getListOfMerckWeeksFromAGivenWeek,
} from "../../services/helper";
import { shallow } from "zustand/shallow";
import useGlobalStore from "../../store";
import { MaterialReactTable, MRT_Cell } from "material-react-table";
import { Button, Input, Modal, notification, Select, Space } from "antd";
import { RedoOutlined } from "@ant-design/icons";
import * as storage from "../../services/migserv_storage";
import send_email from "../../services/send_email_api";
import AdditionalEmailsField from "../common/AdditionalEmailsField";
import appAxios from "components/app_config/axios";
import { get_comms_email_config, get_comms_email_config_with_cache, set_comms_email_config } from "store/CommsEmailConfigStore";
import moment from "moment";

interface ModalPropType {
  show?: boolean;
  handleClose?: () => void;
  onSubmit?: () => any;
  onCancel?: () => any;
}
let comms_email_type = 'email_drop_request'


const DropModal: FC<ModalPropType> = () => {
  const { selected_rows_app_manager, approval_status_api, cc_emails } =
    useGlobalStore(
      (state) => ({
        selected_rows_app_manager: state.selected_rows_app_manager,
        approval_status_api: state.approval_status_api,
        cc_emails: state.cc_emails,
      }),
      shallow
    );
  let allowed_waves = getListOfMerckWeeksFromAGivenWeek(
    getCurrentMerckWeek(),
    6
  );

  const [showModal, setShowModal] = useState(false);
  const [responses, setResponses] = useState<any>({});

  const drop_reasons = [
    "Retiring or Decommissioning servers selected.",
    "Due diligence turned up reason to delay/cancel.",
    "Failure of migration of prior environment.",
    "Application, vendor, or user resources are not available to support migration.",
    "Other",
  ];

  useEffect(() => {
    console.log('show MOdal set to true')
    if (showModal) {
      if (!validateRowsBeforeAction()) {
        notification.warning({
          message: "Action not allowed",
          description: `Your application selection is too close to the migration date, or you have selected an invalid application - please contact the migration team`,
        });
        setShowModal(false);
        return;
      }
      let _responses = {};
      selected_rows_app_manager.forEach((row) => {
        _responses = {
          ..._responses,
          [generate_key(row)]: {
            data: row,
            reason: null,
            enableComment: false,
          },
        };
      });
      setResponses(_responses);
    }
  }, [showModal]);

  const generate_key = (row: any) => {
    return row?.["app_ci_id"] + row?.["servername"];
  };
  const onSelectChange = function (value: string, row: any) {
    setResponses({
      ...responses,
      [generate_key(row)]: {
        data: row,
        reason: value === "Other" ? null : value,
        enableComment: value === "Other" ? true : false,
      },
    });
  };
  const onReasonCommentBlurr = function (value: string, row: any) {
    setResponses({
      ...responses,
      [generate_key(row)]: {
        data: row,
        reason: value.trim(),
        enableComment: true,
      },
    });
  };

  const onSubmitClick = async () => {
    if (validateFormValues()) {
      await trigger_api_and_email();
      setShowModal(false);
    } else {
      notification.error({
        message: "Some fields are not filled",
        description:
          "Please make sure to fill all the details before submitting",
        duration: 10,
        key: "notif_drop",
      });
    }
  };

  const trigger_api_and_email = async () => {
    let failure_count = 0;
    let successful_app_server_responses: any = {};
    console.log(responses);
    let key: string = "";
    let response_keys = Object.keys(responses);
    for (let i = 0; i < response_keys.length; i++) {
      key = response_keys[i];
      notification.info({
        message: `Requesting Drop for ${i + 1} out of ${responses.length
          } app-servers`,
        key: "notif_drop",
        description: "Please wait",
      });
      let result = await approval_status_api(
        "DROP REQUESTED",
        responses[key].data.app_ci_id,
        responses[key].data.servername,
        responses[key].data.migtype,
        responses[key].reason
      );
      if (result.error !== null) {
        failure_count++;
      } else {
        successful_app_server_responses[key] = responses[key];
      }
      console.log(successful_app_server_responses);
    }
    await request_drop_email(successful_app_server_responses);
    notification.open({
      type: failure_count > 0 ? "error" : "success",
      message: `Drop Request ${failure_count > 0 ? "failed for some servers" : "succeeded"
        }`,
      key: "notif_drop",
      description:
        failure_count > 0
          ? `Failed to Request Drop for ${failure_count} app-servers`
          : "Drop Request Completed",
    });
  };

  const generate_email_html = async (selected_rows_app_manager: any[]) => {

    let comms_email_config = await get_comms_email_config_with_cache('Default', comms_email_type, null)
    let template_html = comms_email_config?.['template_html']
    if (!template_html) {
      template_html = 'No Html Found !!'
    }

    let replace_values: { [key: string]: string } = {
      migweek: selected_rows_app_manager[0].wave_name,
      appname: selected_rows_app_manager[0].appname,
      //@ts-ignore
      sender_name: storage.get('user.givenName', '') + ' ' + storage.get('user.familyName', ''),
      request_time: moment().toString(),
      app_table: build_table()
    }

    Object.keys(replace_values).forEach((key) => {
      template_html = template_html.replaceAll(`\{\{${key}\}\}`, replace_values[key])
    })

    return template_html
  }

  const build_table: () => string = () => {
    let table_cols_object: any = {
      wave_name: 'Migration Week',
      appname: 'App Abbrvtn',
      app_project_name: 'App Name',
      servername: 'Server Name',
      role: 'Role',
      reason: 'Reason for drop',
      migtype: 'Migration Type'
    }
    let table_cols = ``
    Object.keys(table_cols_object).forEach((key) => {
      table_cols += `
        <td nowrap class="app_server_table">
            <p class="TableHeaderMerck" style="text-align: center">
            ${table_cols_object[key]}
            </p>
        </td>`
    })
    let table = `
    <table
    border="0"
    cellspacing="0"
    cellpadding="0"
    width="75%"
  >
  <thead>
  <tr>
     ${table_cols}
    </tr>
    </thead>
    <tbody>
 {{table_body}}
 </tbody>
  </table>
  `;

    let table_body = ``;
    let row_body = ``;
    let table_value_map: any = {}
    Object.keys(responses).forEach((key) => {
      let { data: row, reason } = responses[key]
      table_value_map = {}
      Object.keys(table_cols_object).forEach((col) => {
        if (col === 'reason') {
          table_value_map[col] = reason
          return
        }
        table_value_map[col] = row?.[col] ? row[col] : "-"
      })

      row_body = "";
      Object.keys(table_value_map).forEach((key) => {
        row_body += `
                <td class="app_server_table_row">
                    <p  class="TableText" style="text-align: center">
                    <span style="color: black">${table_value_map[key]}</span>
                    </p>
                </td>`
      });
      table_body += `
        <tr>
        ${row_body}
        </tr>
      `;
    });

    table = table.replace(new RegExp(`\{\{table_body\}\}`, "g"), table_body);
    return table;
  }

  const validateFormValues: () => boolean = () => {
    let is_valid = true;
    Object.keys(responses).forEach((response: any) => {
      if (!responses[response].reason || responses[response]?.reason === "") {
        is_valid = false;
      }
    });
    return is_valid;
  };

  const request_drop_email = async (responses: any) => {
    let html = await generate_email_html(selected_rows_app_manager)

    let email = storage.get<string>("user.email", "")
      ? storage.get<string>("user.email", "")
      : "";
    let comms_email_config = get_comms_email_config('Default', comms_email_type, null)
    let _cc_emails_arr = cc_emails
    let _to_emails_arr = typeof email === "string" ? [email] : []
    if (comms_email_config?.['cc_list']) {
      _cc_emails_arr.push(...extractEmailsFromString(comms_email_config?.['cc_list']))
    }
    if (comms_email_config?.['to_list']) {
      _to_emails_arr.push(...extractEmailsFromString(comms_email_config?.['to_list']))
    }
    await send_email._post(
      _to_emails_arr.join(','),
      _cc_emails_arr.join(','),
      "Migration Services Drop Request",
      html
    );
  };

  const validateRowsBeforeAction = () => {
    console.log('validating rows')
    let isValid = true;
    let row: any = {};
    let current_date = new Date();
    for (let i = 0; i < selected_rows_app_manager.length; i++) {
      row = selected_rows_app_manager[i];
      console.log('row is')
      console.log(row)
      if (row?.migration_date && row?.migration_date !== "") {
        if (
          doesDateFallWithinXDaysOfGivenDate(
            current_date,
            row?.migration_date,
            3
          )
        ) {
          isValid = false;
          break;
        }
      } else {
        isValid = false;
        break;
      }
    }
    return isValid;
  };

  const columns = useMemo((): any => {
    return [
      {
        accessorKey: "reason",
        header: "Select Drop Reason",
        size: 50,
        Cell: ({ row }: MRT_Cell) => {
          return (
            <>
              {!responses[generate_key(row?.original)]?.enableComment ? (
                <Select
                  size={"small"}
                  style={{ width: "300px" }}
                  defaultValue="Select Reason to Drop"
                  onChange={(val) =>
                    onSelectChange(val, row ? row?.original : {})
                  }
                  options={drop_reasons.map((reason) => {
                    return {
                      value: reason,
                      label: reason,
                    };
                  })}
                />
              ) : (
                <div style={{ width: "300px" }}>
                  <Space direction="horizontal">
                    <Input
                      style={{ width: "280px" }}
                      onBlur={(event) =>
                        onReasonCommentBlurr(
                          event.target.value,
                          row ? row?.original : {}
                        )
                      }
                      size="small"
                      placeholder="Please enter a valid reason"
                    />
                    <RedoOutlined onClick={() => onSelectChange('-1', row)} />
                  </Space>
                </div>
              )}
            </>
          );
        },
      },
      {
        accessorKey: "wave_name", //access nested data with dot notation
        header: "Migration Week",
        size: 10,
      },
      {
        accessorKey: "migration_date", //access nested data with dot notation
        header: "Date",
        size: 10,
      },
      {
        accessorKey: "appname", //access nested data with dot notation
        header: "App Name",
        size: 10,
      },
      {
        accessorKey: "servername", //normal accessorKey
        header: "Servername",
        size: 10,
      },
      {
        accessorKey: "role",
        header: "Role",
        size: 10,
      },
    ];
  }, [responses]);
  return (
    <>
      <button className="btns" onClick={() => setShowModal(true)}>
        Request Drop
      </button>
      <Modal
        destroyOnClose
        open={showModal}
        closable
        onCancel={() => setShowModal(false)}
        footer={null}
        title={"Request Drop"}
        width={"850px"}
        style={{ overflowY: "auto", maxHeight: "550px" }}
      >
        <MaterialReactTable
          columns={columns}
          data={selected_rows_app_manager}
          enableRowSelection={false}
          enablePagination={false}
          enableStickyHeader
          initialState={{
            density: "compact",
            columnPinning: { left: ["reason"] },
          }}
        />
        <br />
        <AdditionalEmailsField initial_email_list={[]} />
        <br />
        <Button
          type="dashed"
          htmlType="button"
          onClick={onSubmitClick}
          title="submit"
        >
          Submit
        </Button>
      </Modal>
    </>
  );
};

export default DropModal;

export enum QuestionType {
    RADIO = 'RADIO',
    LONGTEXT = 'LONGTEXT',
    LONGTEXT_CONVERSATION = 'LONGTEXT_CONVERSATION'
}

export interface FormBuilderQuestion {
    category: string,
    question_name: string,
    question_descr: string,
    type: QuestionType,
    question_id: number,
    question_response?: QuestionResponseObject[],
    individual_submission_url?: string,
}

export interface QuestionResponseObject {
    value: string,
    user_id: string,
    timestamp: string | Date | null
}
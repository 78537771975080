import _ from "lodash";
import appAxios from "../components/app_config/axios";

const apiUrl = process.env.REACT_APP_MERCK_DIRECTORY_BASE_URL
  ? process.env.REACT_APP_MERCK_DIRECTORY_BASE_URL
  : "";
// https://apiportal.merck.com/api-catalog/details/internal-directory-v2/details

let obj: any = {},
  user: any = {},
  accounts: any = [];

export interface MerckUserDirectroryInterface {
  users: { [key: string]: string }[];
  get_user_by: (type: "ISID" | "EMAIL" | "FULL_NAME", value: string) => Promise<any>;
  get_user_by_queue: (type: "ISID" | "EMAIL", value: string) => Promise<any>;
}


let queue_timeout: any = null
const search_directory_by_api = async (
  type: "ISID" | "EMAIL" | "FULL_NAME",
  value: string
) => {
  try {
    let query_param = {};
    if (type === "ISID") {
      query_param = {
        filter: `isid=${value}`,
      };
    }
    if (type === "EMAIL") {
      query_param = {
        filter: `email=${value}*`,
      };
    }
    if (type === "FULL_NAME") {
      let first_last_name = value.split(' ')
      if (first_last_name.length == 2 && !_.isEmpty(first_last_name[0]) && !_.isEmpty(first_last_name[1])) {
        query_param = {
          filter: `givenName=${first_last_name[0]};familyName=${first_last_name[1]}`
        }
      } else {
        return {
          success: false,
          error: null,
          data: {},
          code: "200",
        };
      }
    }
    let urlSearchParams = new URLSearchParams(query_param)

    let result = await appAxios.get(apiUrl + "/accounts", {
      headers: {
        "Content-Type": "application/json",
        "X-Merck-APIKey": process.env.REACT_APP_MERCK_DIRECTORY_API_KEY,
      },
      params: urlSearchParams,
    });

    accounts = result.data?.["_embedded"]?.["accounts"];
    if (result?.data?.count > 0) {
      obj = {
        NAME: accounts[0].displayName,
        ISID: _.toLower(accounts[0].isid),
        EMAIL: accounts[0].email,
      };
      return {
        success: true,
        error: null,
        data: obj,
        code: "200",
      };
    }
    return {
      success: false,
      error: null,
      data: {},
      code: "200",
    };
  } catch (e) {
    return {
      success: false,
      data: null,
      error: e,
      code: "400",
    };
  }
};


export const createMerckUserDirectorySlice = (
  set: (state: any) => any,
  get: () => any
): MerckUserDirectroryInterface => ({
  users: [],
  get_user_by: async (type, value) => {
    let result: any = {};
    let _user = get().users.find((item: any) => item[type] == value);
    if (_user) {
      return _user;
    }
    //set processing_dir_users
    let processing_dir_users = window.localStorage.getItem(
      "processing_dir_users"
    );
    processing_dir_users = processing_dir_users
      ? JSON.parse(processing_dir_users)
      : [];
    _user = null
    if (processing_dir_users && Array.isArray(processing_dir_users)) {
      _user = processing_dir_users.find((item) => item == value)
      if (!_user) {
        window.localStorage.setItem(
          "processing_dir_users",
          JSON.stringify([...processing_dir_users, value])
        );
      } else {
        return {}
      }

    }
    result = await search_directory_by_api(type, value);
    if (result["success"] === true) {
      set({ users: [...get().users, result["data"]] });
      if (processing_dir_users && Array.isArray(processing_dir_users)) {
        let _processing_dir_users = processing_dir_users.filter(
          (item) => item != value
        );
        window.localStorage.setItem(
          "processing_dir_users",
          JSON.stringify(_processing_dir_users)
        );
      }
      return result["data"];
    } else {
      return {};
    }
  },
  get_user_by_queue: async (type, value) => {
    clearTimeout(queue_timeout)
    let queue_users: any = window.localStorage.getItem('queue_users')
    queue_users = queue_users === null ? [] : JSON.parse(queue_users)
    queue_users.push({ [type]: type === 'ISID' ? _.toLower(value) : value })
    window.localStorage.setItem('queue_users', JSON.stringify(queue_users))
    queue_timeout = setTimeout(async () => {
      let queue_users: any = window.localStorage.getItem('queue_users')
      queue_users = queue_users === null ? [] : JSON.parse(queue_users)
      queue_users = _.uniqWith(queue_users, _.isEqual)
      //perform api call for all the queu_users
      let result: any = null
      let final_result: any = []
      let cached_user = null
      let queue_user_key: 'ISID' | 'EMAIL' = 'ISID'
      let store_users = get().users
      let queue_user: any = {}
      for (let i = 0; i < queue_users.length; i++) {
        try {
          cached_user = null
          queue_user = queue_users[i]
          //@ts-ignore
          queue_user_key = Object.keys(queue_user)[0]
          cached_user = store_users.find((item: any) => item?.[queue_user_key] == queue_user[queue_user_key])
          if (cached_user !== undefined) {
            continue
          }
          result = await search_directory_by_api(queue_user_key, queue_user[queue_user_key])
          if (result.success == true) {
            final_result.push(result.data)
          }
        } catch (e) {
          continue
        }
      }
      set((state: any) => ({ users: _.uniqWith([...state.users, ...final_result], _.isEqual) }))
      window.localStorage.setItem('queue_users', JSON.stringify([]))

    }, 1000)


  }
});

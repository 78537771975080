import { Row, Col, Result, Button } from "antd";
import { FC, useEffect, useState } from "react";

import { Breadcrumb, Layout } from "antd";
import HeaderV2 from "../components/Header/Header_v2";
import ConfigMenu from "../components/Config/ConfigMenu";
import { ActiveMenuRoute, SelectItemEvent } from "../components/Config/Types";
import ConfigContent from "../components/Config/ConfigContent";
import ManageUsers from "../components/Config/ManageUsers";
import ManageMigDates from "../components/Config/ManageMigDates";
import ManageCommsTemplates from "components/Config/ManageCommsTemplates";
import useGlobalStore from "store";
import { shallow } from "zustand/shallow";
const { Content, Sider } = Layout;

interface ConfigPageProps { }

const ConfigPage: FC<ConfigPageProps> = ({ }) => {

  const { migtypes_and_roles_access } = useGlobalStore((state) => ({
    migtypes_and_roles_access: state.migtypes_and_roles_access
  }), shallow)

  const [activeMenuRoute, setActiveMenuRoute] = useState<ActiveMenuRoute>({
    parent: "",
    child: "",
  });

  const onConfigMenuItemSelect = (e: SelectItemEvent) => {
    if (e.keyPath.length == 2 && e.keyPath.includes('comms_templates')) {
      setActiveMenuRoute({
        parent: e.keyPath[1],
        child: e.key,
      })
      return
    }
    setActiveMenuRoute({
      parent: e.keyPath[1],
      child: e.keyPath[0].split("_")[1],
    });
  };

  useEffect(() => {
    console.log(activeMenuRoute);
  }, [activeMenuRoute]);

  return (
    <>
      <HeaderV2 />
      <Row justify={"center"}>
        <Col span={22}>
          <Row>
            <Col span={6}>
              <Sider>
                <ConfigMenu onConfigMenuItemSelect={onConfigMenuItemSelect} />
              </Sider>
            </Col>
            <Col span={18}>
              <Breadcrumb style={{ margin: "16px 0" }}>
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item>Config</Breadcrumb.Item>
                {activeMenuRoute.parent !== "" ?
                  (
                    <>
                      <Breadcrumb.Item>{activeMenuRoute.parent}</Breadcrumb.Item>
                      <Breadcrumb.Item>{activeMenuRoute.child}</Breadcrumb.Item>
                    </>
                  ) : <></>}
              </Breadcrumb>
              {migtypes_and_roles_access.length == 0 ?
                <Result
                  title="You do not have access to this page."
                /> : activeMenuRoute.parent == "" ? (
                  <Content>Content</Content>
                ) : (
                  activeMenuRoute.parent == 'users' ? <ManageUsers migtype={activeMenuRoute.child} /> :
                    activeMenuRoute.parent == 'migdates' ? <ManageMigDates migtype={activeMenuRoute.child} /> :
                      activeMenuRoute.parent == 'comms_templates' ? <ManageCommsTemplates comms_email_type={activeMenuRoute.child} /> :
                        <ConfigContent />
                )}

            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default ConfigPage;

export interface TableStoreInterface {
    selected_rows_app_manager: any[],
    selected_rows_scheduler: any[],
    set_selected_rows_app_manager: (tableRef: any)=>any
    set_selected_rows_scheduler: (tableRef: any)=>any
}
export const createTableStoreSlice = (set: (state: any) => any, get: () => any): TableStoreInterface => ({
    selected_rows_app_manager: [],
    selected_rows_scheduler: [],
    set_selected_rows_app_manager: (tableRef)=>{
        let rows = tableRef.current.getSelectedRowModel()?.rows
        if (rows?.length == 0) {
            set({selected_rows_app_manager: []})
        } else {
            set({selected_rows_app_manager: rows.map((item: any) => item.original)})
        }
    },
    set_selected_rows_scheduler: (tableRef)=>{
        let rows = tableRef.current.getSelectedRowModel()?.rows
        if (rows?.length == 0) {
            set({selected_rows_scheduler: []})
        } else {
            set({selected_rows_scheduler: rows.map((item: any) => item.original)})
        }
    }


})
import { Col, Modal, Row, Space, Button, notification } from "antd";
import _ from "lodash";
import { FC, useEffect, useRef, useState } from "react";
import { get_comms_email_config, get_comms_email_config_with_cache } from "../../../store/CommsEmailConfigStore";
import get_date_config_property, { compute_date, find_date_config_from_map } from "../../../store/DateConfigStore";
import ViewBuilder from "../../ViewBuilder";
import { MailTwoTone } from "@ant-design/icons";
import { extractEmailsFromString, seperateEmailsNamesAndISIDsFromString } from "../../../services/helper";
import numToWords from 'number-to-words'
import useGlobalStore from "../../../store";
import { shallow } from "zustand/shallow";
import PreviewModal from "./PreviewModal";
import email_api from "services/send_email_api";
import { retrieveUserInfo } from "services/migserv_auth";
import CommsEmailMenu from "./CommsEmailMenu";
import { CommsEmailTypes, InProgressCommsEmailTypes } from "./CommsEmailTypes";
import { MigTypeColors, vars_to_english } from "types/migserv_types";
import moment from "moment";
import { sleep } from "services/migserv_utils";
import LoadingResult from "components/common/LoadingResult";
import GroupingFilter from "./GroupingFilter";

interface CommsEmailPropType {
  manage_type?: string;
  tableRef?: { current: any };
}

export interface GroupedPreviewItem {
  app_managed_by: string;
  grouped_rows: any[];
  migtype: string;
  migtime: string | null;
  env?: string | null;
  migdate: string | null;
  cc_list?: string[];
  to_list?: string[];
  bcc_list?: string[];
  email_success?: boolean;
  email_subject?: string;
  reply_to?: string[]
}

const CommsEmail: FC<CommsEmailPropType> = ({ tableRef = { current: null } }) => {
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [groupedPreview, setGroupedPreview] = useState<GroupedPreviewItem[]>([]);
  const [htmlPreview, setHtmlPreview] = useState("");
  const [commsPreviewData, setCommsPreviewData] = useState<any>({});
  const [rowSelection, setRowSelection] = useState({});
  const [activeGroupedItemIndex, setActiveGroupedItemIndex] = useState(0);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [commsEmailType, setCommsEmailType] = useState<CommsEmailTypes | null>(null);
  const [inProgressCommType, setInProgressCommType] = useState<CommsEmailTypes | null>(null)
  const [comm_bridge_url, set_comm_bridge_url] = useState<string>('')
  const [groupByProperties, setGroupByProperties] = useState<string[]>([])
  const migtypeRef = useRef<string>()
  const groupingTableRef = useRef<any>(null)

  const { get_user_by } = useGlobalStore(
    (state) => ({
      get_user_by: state.get_user_by,
    }),
    shallow
  );

  const resetState = () => {
    setCommsEmailType(null)
    setIsLoading(false)
    setGroupedPreview([])
    setRowSelection({})
    setActiveGroupedItemIndex(0)
    setShowPreviewModal(false)
    setInProgressCommType(null)
    set_comm_bridge_url('')
    migtypeRef.current = ''
    groupingTableRef.current = null
  }

  const onClick = async (comms_email_type: CommsEmailTypes) => {
    resetState()
    if (validateRows(comms_email_type)) {
      setCommsEmailType(comms_email_type);
      setRowSelection({});
      setGroupByProperties(get_group_by_properties(comms_email_type))
    }
  };

  useEffect(() => {
    (async () => {
      if (commsEmailType !== null) {
        if (commsEmailType === 'email_in_progress') {
          flow_for_in_progress_comms()
          return
        }
        setIsLoading(true)
        setShowModal(true);
        let _groupedPreview = await populate_grouping_and_filter(commsEmailType)
        setIsLoading(false)
        setGroupedPreview(_groupedPreview);
      }
    })()

  }, [groupByProperties])
  //presently, we're only allowing rows for a single migtype to be generated in comms
  const validateRows = (comms_email_type: CommsEmailTypes) => {
    if (tableRef.current) {
      let rows = tableRef.current.getSelectedRowModel()?.rows;
      rows = _.groupBy(rows, 'original.migtype')
      if (Object.keys(rows).length > 1 && comms_email_type !== 'email_migration_schedule') {
        notification.error({ message: 'Multiple Mig Types not allowed', description: 'Please select rows from a single migtype and try again' })
        return false
      }
      migtypeRef.current = comms_email_type === 'email_migration_schedule' ? '' : Object.keys(rows)[0]
      return true
    }
    return false
  }


  const flow_for_in_progress_comms = async () => {
    let comm_bridge_url = window.prompt('Please Enter GOC URL for Downtime Complete Comm: ', '')
    set_comm_bridge_url(comm_bridge_url ? comm_bridge_url : '')
    notification.info({ message: 'Generating In Progress Emails', key: 'in_progress' })
    let in_progress_email_types: any = InProgressCommsEmailTypes.filter((item) => item !== 'email_in_progress')
    for (let i = 0; i < in_progress_email_types.length; i++) {
      let comm = in_progress_email_types[i]
      notification.info({ message: `Downloading emails for: ${comm.replaceAll('_', ' ').toUpperCase()}`, key: 'in_progress' })
      let _groupedPreview = await populate_grouping_and_filter(comm)
      setGroupedPreview(_groupedPreview)
      setCommsEmailType(comm)
      setInProgressCommType(comm)
      await sleep(1000)
    }
    notification.success({ message: `Download Complete`, key: 'in_progress' })
    setInProgressCommType(null)
  }

  useEffect(() => {
    (async () => {
      if (inProgressCommType !== null) {
        download_email(false, true)
      }
    })()
  }, [inProgressCommType])

  const populate_grouping_and_filter = async (comms_email_type: CommsEmailTypes) => {
    let rows = [];
    if (tableRef.current) {
      rows = tableRef.current.getSelectedRowModel()?.rows;
      rows = rows.map((item: any) => item.original);
      let _groupedPreview: GroupedPreviewItem[] = [];
      if (rows.length > 0) {
        //group by app_manager here
        let grouped_rows: any = {};
        // Identify if any of the items don't have a migdate / migtime configured, and set defaults for them
        rows = rows.map((item: any) => {
          if (item.migration_date === "" || !item.migration_date || item.migration_date === null) {
            let _date_config_prop: any = get_date_config_property(
              item.migtype,
              "default_mig_date",
              null,
              null,
              item?.env?.toLowerCase()
            );
            if (_date_config_prop) {
              return {
                ...item,
                migration_date: _date_config_prop.computed_value.format("YYYY-MM-DD"),
                migration_time: _date_config_prop.computed_value.format("HH:mm"),
              };
            }
            return null;
          } else {
            return item;
          }
        });
        rows = rows.filter((item: any) => item !== null);
        grouped_rows = filter_unique_rows(group_rows_for_preview(rows, groupByProperties), comms_email_type)

        let reply_to = [
          retrieveUserInfo().userEmail,
          "chris.ryan@merck.com",
          "paul.wilmot@merck.com",
          "edward.shows1@merck.com",
          // "aurelie.guitar@merck.com"
        ]
        if (comms_email_type === 'email_migration_schedule') {
          reply_to = [...reply_to, 'robert.graham@merck.com']
        }
        let grouped_rows_keys = Object.keys(grouped_rows)
        let init_obj: any = {}
        for (let i = 0; i < Object.keys(grouped_rows_keys).length; i++) {
          let grouping_key = grouped_rows_keys[i]
          init_obj = {
            app_managed_by: grouped_rows[grouping_key][0]?.['app_managed_by'] ? grouped_rows[grouping_key][0]?.['app_managed_by'] : null,
            migtype: grouped_rows[grouping_key][0]?.['migtype'] ? grouped_rows[grouping_key][0]?.['migtype'] : null,
            migdate: grouped_rows[grouping_key][0]?.['migration_date'] === "null" ? null : grouped_rows[grouping_key][0]?.['migration_date'],
            migtime: grouped_rows[grouping_key][0]?.['migration_time'] === "null" ? null : grouped_rows[grouping_key][0]?.['migration_time'],
            reply_to,
            grouped_rows: grouped_rows[grouping_key]
          }

          _groupedPreview.push({
            ...init_obj,
            cc_list: await get_cc_email_list(0, {
              ...init_obj
            }, comms_email_type),
            to_list: await get_to_email_list(0, {
              ...init_obj
            }, comms_email_type),
            bcc_list: await get_bcc_email_list(0, {
              ...init_obj
            }, comms_email_type),
          });
        }
      }

      return _groupedPreview
    }
    return []
  }

  const group_rows_for_preview = (rows: { [key: string]: any }, grouping_props: string[] = []) => {
    let grouped_rows: any = {}
    if (grouping_props.length === 0) {
      return { "all_rows": rows }
    }

    rows.forEach((row: any) => {
      let _grouping_key_arr = grouping_props.map((prop) => {
        return row?.[prop] ? row[prop] : "null"
      })
      let grouping_key = _grouping_key_arr.join('__')

      if (Array.isArray(grouped_rows?.[grouping_key])) {
        grouped_rows[grouping_key].push(row);
      } else {
        grouped_rows[grouping_key] = [row];
      }
    });

    return grouped_rows

  }

  const filter_unique_rows = (grouped_rows: any[], commsEmailType: CommsEmailTypes) => {
    let unique_grouping_props: string[] = []
    if (commsEmailType === 'email_migration_schedule') {
      unique_grouping_props = ['migration_date', 'migration_time', 'appname', 'env']
    }

    if (unique_grouping_props.length === 0) {
      return grouped_rows
    }
    let grouped_rows_filtered_by_unique: any = {}

    Object.keys(grouped_rows).forEach((group_key: any) => {
      if (grouped_rows[group_key].length === 1) {
        grouped_rows_filtered_by_unique[group_key] = grouped_rows[group_key]
        return
      }
      let unique_groups: string[] = []
      grouped_rows[group_key].forEach((row: any) => {

        let _grouping_key_arr = unique_grouping_props.map((prop) => {
          return row?.[prop] ? row[prop] : "null"
        }).join('__')
        if (unique_groups.includes(_grouping_key_arr)) {
          return
        } else {
          unique_groups.push(_grouping_key_arr)
          if (grouped_rows_filtered_by_unique?.[group_key]) {
            grouped_rows_filtered_by_unique?.[group_key].push(row)
          } else {
            grouped_rows_filtered_by_unique[group_key] = [row]
          }
        }
      })
    })

    return grouped_rows_filtered_by_unique
  }

  const get_group_by_properties = (comms_email_type: CommsEmailTypes | null) => {
    if (comms_email_type === 'email_migration_schedule') {
      return []
    }

    if (comms_email_type === 'email_approval' && migtypeRef.current === 'Robo') {
      return ['migtype', 'migration_date', 'migration_time']
    }
    if (InProgressCommsEmailTypes.includes(comms_email_type !== null ? comms_email_type : 'none') || comms_email_type === 'email_app_shutdown') {
      if (migtypeRef.current === 'APEX') {
        return ['migtype', 'migration_date', 'migration_time', 'downtime']
      }
      return ['migtype', 'migration_date', 'migration_time']
    }
    if (migtypeRef.current === 'APEX') {
      return ['app_managed_by', 'migtype', 'migration_date', 'migration_time', 'downtime']
    }
    return ['app_managed_by', 'migtype', 'migration_date', 'migration_time']

  }

  useEffect(() => {
    if (groupedPreview.length > 0) {
      generate_preview_for_active_grouped_item(activeGroupedItemIndex);
    }
  }, [activeGroupedItemIndex, groupedPreview, showPreviewModal]);

  const generateHtmlEmail = async (
    grouped_rows: any,
    app_managed_by: string,
    migtype: string,
    migdate: string | null,
    migtime: string | null
  ) => {
    let html = "Generating Preview ...";
    let result: any = {};
    result = await get_comms_email_config_with_cache(commsEmailType === 'email_migration_schedule' ? 'Default' : migtype, commsEmailType)
    if (!result?.['template_html']) {
      result['template_html'] = 'No Html Found'
    }

    let vars: any = generate_comms_schedule(grouped_rows, migtype, migdate, migtime);
    if (vars !== null) {
      vars["app_server_table"] = await generate_app_server_table(grouped_rows);
      let user_info = retrieveUserInfo();
      vars["sender_name"] = user_info.userFirstname + " " + user_info.userLastname;
      vars["sender_email"] = user_info.userEmail;
      vars['comm_bridge_url'] = comm_bridge_url
      vars['site_code'] = grouped_rows[0]?.['site_code'] ? grouped_rows[0]['site_code'] : '-'
      if (vars['approvals_instruction_date']?.['computed_value'] && vars['approvals_instruction_time']?.['computed_value']) {
        vars['approval_due_date'] = get_date_config_property(migtype, 'approval_due_date',
          vars['approvals_instruction_date'].computed_value.toISOString().split('T')[0],
          vars['approvals_instruction_time'].computed_value.toISOString().split('T')[1],
          null, true)
        vars['_approval_due_date'] = vars['approval_due_date']?.['computed_value'].format('dddd, MMMM Do')
      }
      if (migtype === 'APEX') {
        if (_.get(grouped_rows[0], 'downtime', null) !== null) {
          let downtime_complete_offset = _.toInteger(_.get(grouped_rows[0], 'downtime', null))
          vars['downtime_complete_date'] = compute_date(migtype,
            {
              date_config_prop_name: 'downtime_complete_date',
              prop_type: 'date',
              relative_time: downtime_complete_offset
            }, vars['downtime_begins_date']?.computed_value, null)
          vars['downtime_complete_time'] = compute_date(migtype,
            {
              date_config_prop_name: 'downtime_complete_date',
              prop_type: 'time',
              relative_time: downtime_complete_offset
            }, vars['downtime_begins_date']?.computed_value, null)
          vars['downtime_offset_hours_in_words'] = numToWords.toWords(downtime_complete_offset)
        } else {
          let _downtime = find_date_config_from_map(migtype,
            'downtime_complete_date', null, true, true)
          if (_downtime && _downtime['relative_time']) {
            if (_.isInteger(_downtime['relative_time'])) {
              vars['downtime_offset_hours_in_words'] = numToWords.toWords(_downtime['relative_time'])
            }

          }

        }
      }
    } else {
      vars = {};
    }

    html = populate_html(result['template_html'], vars);

    return html;
  };

  const populate_html = (html: string, vars: any) => {
    Object.keys(vars).forEach((key: string) => {
      if (typeof vars[key]["string_value"] === "string") {
        html = html.replace(new RegExp(`\{\{${key}\}\}`, "g"), vars[key]["string_value"]);
      } else {
        html = html.replace(new RegExp(`\{\{${key}\}\}`, "g"), vars[key]);
      }
    });
    return html;
  };

  const generate_comms_schedule = (
    grouped_rows: any[],
    migtype: string,
    migdate: string | null,
    migtime: string | null
  ) => {
    let comms_email_config = get_comms_email_config(commsEmailType === 'email_migration_schedule' ? 'Default' : migtype, commsEmailType);
    let date_config = {};
    if (comms_email_config?.["date_config"]) {
      date_config = comms_email_config?.["date_config"];
    }
    let computed_date_config = get_date_config_property(migtype, date_config, migdate, migtime, null, false);
    return computed_date_config;
  };

  const render_grouping = () => {
    const get_additional_cols = (item: GroupedPreviewItem) => {
      let cols: { [key: string]: any } = {}
      groupByProperties.forEach((col: string) => {
        // @ts-ignore
        cols[col] = item?.grouped_rows[0]?.[col] ? item?.grouped_rows[0]?.[col] : '-'
      })
      return cols
    }

    return (
      <ViewBuilder
        view_config={{
          data: groupedPreview
            .map((item, index) => {
              if (item?.email_success === true) {
                return false;
              } else {
                return {
                  SrNo: index + 1,
                  item_index: index,
                  ...get_additional_cols(item),
                  "Total App-Servers": item.grouped_rows.length,
                };
              }
            })
            .filter((item) => item != false),
          initial_state_react_material_table: {
            enableRowSelection: true,
            onRowSelectionChange: setRowSelection,
            rowSelection,
            enableRowActions: true,
            tableInstanceRef: groupingTableRef,
            renderRowActions: ({ row: { original } }: any) => {
              return (
                <Space>
                  <MailTwoTone
                    title="Preview Email"
                    onClick={() => {
                      onViewClick(original.item_index);
                    }}
                  />
                </Space>
              );
            },
            columnVisibility: { item_index: false },
          },
          hide_view_details: true,
        }}
      />
    );
  };

  const column_mapping: { [key: string]: string } = {
    division: 'Division',
    migration_date: 'Migration Date',
    migration_time: 'Time ET',
    migtype: 'Migration Type',
    appname: 'App<br />Abbrvtn',
    app_project_name: 'App Name',
    servername: 'Server Name',
    role: 'Role',
    env: 'Role',
    ipchange: 'IP Change',
    app_managed_by: 'Managed<br />By',
    premig_datetime: 'Pre-Mig',
    site_approvers: 'Site<br />Approvers'

  }

  const generate_app_server_table = async (grouped_rows: any[]) => {
    let isid_map: any = {}

    let cols = ["appname", "app_project_name", "servername", "env", "migtype", "ipchange"];
    let sort_by = ['appname', 'servername']
    if (commsEmailType === 'email_migration_schedule') {
      cols = ['division', 'migration_date', 'migration_time', 'migtype', 'appname', 'env']
      sort_by = ['division', 'appname', 'migtype']
    }
    if (commsEmailType === 'email_approval' && grouped_rows[0]?.['migtype'] === 'Robo') {
      _.pull(cols, 'migtype', 'ipchange')
      cols = [...cols, 'app_managed_by', 'site_approvers', 'premig_datetime']
    }
    if (commsEmailType === 'email_approval' && grouped_rows[0]?.['migtype'] === 'GreenLake Live') {
      _.pull(cols, 'ipchange')
    }
    let cols_heading = ``
    cols.forEach((col) => {
      cols_heading += `
    <td  nowrap class="app_server_table">
      <p class="TableHeaderMerck" style="text-align: ${col === 'app_project_name' ? 'left' : 'center'}">
        <span>${column_mapping[col]}</span>
      </p>
    </td>`
    })
    let table = `
    <table
    class="MsoNormalTable"
    border="0"
    cellspacing="0"
    cellpadding="0"
    width="100%"
    style="width: 100%; border-collapse: collapse"
  >
    <tr style="height: 0.25in">
      ${cols_heading}           
    </tr>
 {{table_body}}
  </table>
  `;

    let table_body = ``;
    let row_body = ``;

    let _sorted_rows = _.sortBy(grouped_rows, sort_by)
    let row: any
    let col = ''
    for (let j = 0; j < _sorted_rows.length; j++) {
      row = _sorted_rows[j]
      row_body = "";
      col = ''
      for (let i = 0; i < cols.length; i++) {
        col = cols[i]
        if (col === "migtype") {
          let color = MigTypeColors[row?.[col]] ? MigTypeColors[row?.[col]] : '#f2f2f2'
          row_body += `
            <td class="app_server_table_row" style="background:${color}">
            <p class="TableText" style="text-align: center">
              <span style="color: black">${row?.[col] ? row[col] === "Robo" ? row[col]?.toUpperCase() : row[col] : "-"}</span>
            </p>
          </td>`;
        } else if (col === "premig_datetime") {
          row_body += `
            <td class="app_server_table_row">
            <p class="TableText" style="text-align: center">
              <span style="color: black">${row?.[col] ? moment(row?.[col]).format("MMM-DD") + '<br />' + moment(row?.[col]).format("hh A") + ' ET' : "-"}</span>
            </p>
          </td>`;
        } else if (col === 'site_approvers') {
          //gather the approver emails and then put it in the row.
          row_body += `
            <td class="app_server_table_row" title="${row?.[col] ? row?.[col] : '-'}">
            <p class="TableText" style="text-align: center">
              <span style="color: black; max-width: 5px; overflow-x: auto">${row?.[col] ? row?.[col].split(';').join(', ') : '-'}</span>
            </p>
          </td>`;
        }
        else {
          row_body += `
            <td class="app_server_table_row">
            <p class="TableText" style="text-align: ${col === 'app_project_name' ? 'left' : 'center'}">
              <span style="color: black">${row?.[col] ? row[col] : "-"}</span>
            </p>
          </td>`;
        }
      }

      table_body += `
          <tr style="height: 0.25in">
          ${row_body}
          </tr>
        `;

    }



    table = table.replace(new RegExp(`\{\{table_body\}\}`, "g"), table_body);
    return table;
  };

  const generate_preview_for_active_grouped_item = async (index: number) => {
    let group = groupedPreview[index];
    let html = await generateHtmlEmail(
      group["grouped_rows"],
      group["app_managed_by"],
      group["migtype"],
      group["migdate"],
      group["migtime"]
    );
    let date_config = generate_comms_schedule(
      group["grouped_rows"],
      group["migtype"],
      group["migdate"],
      group["migtime"]
    );

    setHtmlPreview(html);
    setCommsPreviewData(date_config);
  };

  const onViewClick = (groupedPreviewIndex: number) => {
    setShowPreviewModal(true);
    setActiveGroupedItemIndex(groupedPreviewIndex);
  };

  const generateEmailSubject = (groupedPreviewItem: GroupedPreviewItem) => {
    let migtype = groupedPreviewItem["migtype"];
    let first_row = groupedPreviewItem["grouped_rows"][0];
    let comms_email_config = get_comms_email_config(commsEmailType === 'email_migration_schedule' ? 'Default' : first_row["migtype"], commsEmailType, null);
    let action_required = comms_email_config?.['action_required'] === undefined ? true : comms_email_config?.['action_required']
    let default_suffix = 'Content Freeze and Approval Request'
    let subject = `${action_required ? 'ACTION REQUIRED: ' : ''}${first_row?.["wave_name"]
      } Merck Migration Services - ${commsEmailType !== 'email_migration_schedule' ? migtype.toUpperCase() : ''} ${comms_email_config ? comms_email_config?.['email_subject_suffix'] ? comms_email_config['email_subject_suffix'] : default_suffix : default_suffix}`;
    if (migtype === 'Robo') {
      let site_code = first_row?.['site_code'] ? first_row['site_code'] : '-'
      subject = `ACTION REQUIRED: ${first_row?.["wave_name"]
        } - ${commsEmailType !== 'email_migration_schedule' ? migtype.toUpperCase() : ''} ${comms_email_config ? comms_email_config?.['email_subject_suffix'] ? comms_email_config['email_subject_suffix'] : default_suffix : default_suffix} ${commsEmailType !== 'email_migration_schedule' ? '(' + site_code + ')' : ''}`;
    }
    return subject
  };

  const sendEmail = async (trigger_for_active_groupedPreviewItem: boolean = false) => {
    let _groupedPreviewIndex = 0;
    let _keys = groupingTableRef.current?.getSelectedRowModel()?.flatRows?.map((row: any) => row.original.item_index)
    let keys = _.isArray(_keys) ? _keys : []
    let html = "";
    let subject = "";
    let group: GroupedPreviewItem | any = {};
    if (trigger_for_active_groupedPreviewItem) {
      keys = [activeGroupedItemIndex.toString()];
    }
    if (keys.length === 0) {
      return notification.error({
        message: "Please make a selection",
        description: 'Please select at least one of the rows and click "Send Mail"',
      });
    }
    notification.info({
      key: "email_notif",
      message: `Sending emails, please don't close the window ...`,
      duration: 0

    });
    let sent_emails = 0;
    let sent_email_item_indexes: number[] = [];
    let failed_emails = 0;

    for (let i = 0; i < keys.length; i++) {
      _groupedPreviewIndex = +keys[i];
      group = groupedPreview[_groupedPreviewIndex];
      html = await generateHtmlEmail(
        group["grouped_rows"],
        group["app_managed_by"],
        group["migtype"],
        group["migdate"],
        group["migtime"]
      );
      subject = generateEmailSubject(group);

      let cc_email_list = await get_cc_email_list(_groupedPreviewIndex, null, commsEmailType);
      let to_email_list = await get_to_email_list(_groupedPreviewIndex, null, commsEmailType);
      try {
        if (group?.email_success != true) {
          await email_api._post(to_email_list.join(","), cc_email_list.join(","), subject, html, {
            replymail: _.uniq(group?.['reply_to'] ? group['reply_to'] : []).join(","),
            bcc: group?.['bcc_list'] ? group['bcc_list'].join(',') : ''
          });
          sent_emails++;
          sent_email_item_indexes.push(_groupedPreviewIndex);
        }

      } catch (e) {
        failed_emails++;
      }
    }
    let _groupedPreview = groupedPreview.map((item, index) => {
      if (sent_email_item_indexes.includes(index)) {
        return { ...item, email_success: true };
      } else {
        return item;
      }
    });
    setGroupedPreview(_groupedPreview);
    setRowSelection({})
    if (failed_emails > 0) {
      notification.error({
        key: "email_notif",
        message: `Failed to send ${failed_emails} out of ${failed_emails + sent_emails} Email(s)`,
        description: "Please try again",
      });
    } else {
      if (showPreviewModal) {
        setShowPreviewModal(false);
      }
      notification.success({
        key: "email_notif",
        message: `${sent_emails} Email(s) sent successfully`,
      });
    }
  };


  const create_email_from_groupedItemPreview = async (group: GroupedPreviewItem, comm: null | CommsEmailTypes) => {
    let html = await generateHtmlEmail(
      group["grouped_rows"],
      group["app_managed_by"],
      group["migtype"],
      group["migdate"],
      group["migtime"]
    );
    let subject = generateEmailSubject(group);

    let cc_email_list = await get_cc_email_list(0, group, comm ? comm : commsEmailType);
    let to_email_list = await get_to_email_list(0, group, comm ? comm : commsEmailType);
    try {
      const message: any = {
        To: to_email_list.join(";"),
        Cc: cc_email_list.join(";"),
        Bcc: group?.['bcc_list'] ? group['bcc_list'].join(';') : '',
        Subject: subject,
        html,
        "Content-Type": "text/html",
        "X-Unsent": 1,
        "Reply-To": _.isArray(group?.['reply_to']) ? group['reply_to'].join(';') : ''
      };
      let emlFileContent: string = "";
      _.pull(_.keys(message), "html").forEach((key) => {
        emlFileContent += `${key}:${message[key]}` + "\n";
      });
      emlFileContent += "\n";
      emlFileContent += message["html"];

      let data = new Blob([emlFileContent], { type: "text/plain" });
      let textFile = window.URL.createObjectURL(data);
      let group_props = groupByProperties
      if (group_props.length === 0) {
        group_props = ['migtype', 'migdate', 'migtime']
      }
      let file_name = `${commsEmailType}`
      let prop = ''
      for (let i = 0; i < group_props.length; i++) {
        prop = group_props[i]
        file_name += `_${group?.['grouped_rows'][0]?.[prop] ? group?.['grouped_rows'][0]?.[prop] : prop}`
      }

      return { file: textFile, file_name }

    } catch (e) {
      console.log(e)
      return { file: null, file_name: '' }
    }
  }

  const download_email = async (trigger_for_active_groupedPreviewItem: boolean = false, download_all: boolean = false) => {
    let _groupedPreviewIndex = 0;
    let keys = download_all ? [] : Object.keys(rowSelection);
    let group: any = {};
    if (trigger_for_active_groupedPreviewItem) {
      keys = [activeGroupedItemIndex.toString()];
    } else if (download_all) {
      groupedPreview.forEach((item, index) => {
        keys[index] = index.toString()
      })
    } else { }

    if (keys.length === 0) {
      return notification.error({
        message: "Please make a selection",
        description: 'Please select at least one of the rows and click "Send Mail"',
      });
    }
    const fragment = document.createDocumentFragment()
    var a = document.createElement("a");
    var linkText = document.createTextNode("fileLink");
    a.appendChild(linkText);
    a.id = "fileLink";
    a.style.visibility = "hidden";
    fragment.appendChild(a)
    for (let i = 0; i < keys.length; i++) {
      _groupedPreviewIndex = +keys[i];
      group = groupedPreview[_groupedPreviewIndex];
      let email = await create_email_from_groupedItemPreview(group, commsEmailType)
      if (email?.file) {
        a.href = email?.file;
        a.download = `${email?.file_name}.eml`;
        a.click()
        await sleep(200)
      }
    }
    fragment.removeChild(a)

  };

  const get_cc_email_list = async (groupedPreviewIndex: number, groupedPreviewItem: GroupedPreviewItem | null = null, commsEmailType: CommsEmailTypes | null): Promise<string[]> => {
    let _groupedPreviewItem: any = {}
    if (groupedPreviewItem !== null) {
      _groupedPreviewItem = { ...groupedPreviewItem }
    } else {
      _groupedPreviewItem = groupedPreview[groupedPreviewIndex];
    }
    if (_groupedPreviewItem?.["cc_list"]) {
      return _groupedPreviewItem?.["cc_list"];
    }

    let comms_email_config = get_comms_email_config(commsEmailType === 'email_migration_schedule' ? 'Default' : _groupedPreviewItem["migtype"], commsEmailType, null);
    let cc_list: any[] = [];
    if (comms_email_config && comms_email_config?.["cc_list"]) {
      cc_list = extractEmailsFromString(comms_email_config?.["cc_list"]);
    }
    let fields = ['divisional_lead']
    cc_list = [...cc_list, ...await produce_recepients_list(_groupedPreviewItem, fields)]
    let custom_order = [
      "chris.ryan@merck.com",
      "paul.wilmot@merck.com",
      "edward.shows1@merck.com",
      "edward.shows@hpe.com",
      "scott_hillegass@merck.com",
      "robert.digiovanni@merck.com",
      "hpewinlinmigration@merck.com",
    ];
    return _.uniq(cc_list).sort((a, b) => {
      const aIndex = custom_order.indexOf(a);
      const bIndex = custom_order.indexOf(b);
      return aIndex - bIndex;
    });
  };

  const get_to_email_list = async (groupedPreviewIndex: number, groupedPreviewItem: GroupedPreviewItem | null = null, commsEmailType: CommsEmailTypes | null): Promise<string[]> => {
    let _groupedPreviewItem: any = {}
    if (groupedPreviewItem !== null) {
      _groupedPreviewItem = { ...groupedPreviewItem }
    } else {
      _groupedPreviewItem = groupedPreview[groupedPreviewIndex];
    }
    if (_groupedPreviewItem?.["to_list"]) {
      return _groupedPreviewItem?.["to_list"];
    }

    let to_list: any[] = [];
    if (groupedPreviewItem) {
      let migtype = _groupedPreviewItem["grouped_rows"][0]['migtype']
      let fields = ['testers', 'approvers', 'app_managed_by']
      if (migtype === 'Robo') {
        fields.push('site_approvers')
      }

      to_list = _.uniq([...await produce_recepients_list(groupedPreviewItem, fields)])
    }
    return to_list;
  };
  const get_bcc_email_list = async (groupedPreviewIndex: number, groupedPreviewItem: GroupedPreviewItem | null = null, commsEmailType: CommsEmailTypes): Promise<string[]> => {
    let _groupedPreviewItem: any = {}
    if (groupedPreviewItem !== null) {
      _groupedPreviewItem = { ...groupedPreviewItem }
    } else {
      _groupedPreviewItem = groupedPreview[groupedPreviewIndex];
    }
    if (_groupedPreviewItem?.["bcc_list"]) {
      return _groupedPreviewItem?.["bcc_list"];
    }

    let bcc_list: any[] = [];
    if (commsEmailType === 'email_migration_schedule') {
      bcc_list = await produce_recepients_list(_groupedPreviewItem, ['product_manager'])
    }
    return bcc_list;
  };

  const produce_recepients_list = async (groupedPreviewItem: GroupedPreviewItem, fields: string[]) => {
    let _rows = _.isArray(groupedPreviewItem?.['grouped_rows']) ? groupedPreviewItem['grouped_rows'] : []
    let [isids, emails, full_names]: [string[], string[], string[]] = [[], [], []]
    //@ts-ignore
    for (let i = 0; i < _rows.length; i++) {
      let result = { emails: [], isids: [], full_names: [] }
      for (let field_idx = 0; field_idx < fields.length; field_idx++) {
        result = seperateEmailsNamesAndISIDsFromString(_rows[i]?.[fields[field_idx]])
        isids.push(...result.isids)
        emails.push(...result.emails)
        full_names.push(...result.full_names)
      }
    }
    isids = _.uniq(isids)
    emails = _.uniq(emails)
    full_names = _.uniq(full_names)
    isids = await convert_to_email(isids, 'ISID')
    full_names = await convert_to_email(full_names, 'FULL_NAME')
    return _.uniq([...isids, ...emails, ...full_names])
  }


  const convert_to_email = async (items: string[], type: 'ISID' | 'FULL_NAME') => {
    let user: any = {};
    let result = [];
    items = _.uniq(items)
    for (let i = 0; i < items.length; i++) {
      user = await get_user_by(type, items[i]);
      if (user?.["EMAIL"] && user?.["EMAIL"] !== "") {
        result.push(user?.["EMAIL"]);
      }
    }
    return result;
  };

  const on_emails_added_or_removed = (emails_list: string[] = [], type: 'cc_list' | 'to_list' | 'bcc_list') => {
    let _groupedPreview = groupedPreview;
    _groupedPreview[activeGroupedItemIndex][type] = emails_list;
    setGroupedPreview(_groupedPreview);
    return [];
  }

  return (
    <>
      <CommsEmailMenu initiateFlow={onClick} />
      {showModal ? (
        <Modal
          destroyOnClose
          open={showModal}
          footer={null}
          title={'Comm Type: ' + vars_to_english[commsEmailType != null ? commsEmailType : '']}
          onCancel={() => setShowModal(false)}
          width={"80vw"}
          style={{ overflowY: "auto", maxHeight: "550px" }}
        >
          {isLoading ? <div><LoadingResult /></div> : <div>
            <PreviewModal
              htmlPreview={htmlPreview}
              commsPreviewData={commsPreviewData}
              showPreviewModal={showPreviewModal}
              setShowPreviewModal={setShowPreviewModal}
              on_emails_added_or_removed={on_emails_added_or_removed}
              send_email={sendEmail}
              groupedPreviewItem={groupedPreview[activeGroupedItemIndex]}
            />
            <Row align="middle" justify="center" gutter={[16, 16]}>
              <Col span={20}>
                <GroupingFilter
                  onChange={(v: any) => setGroupByProperties(v)}
                  value={groupByProperties} />
              </Col>
              <Col span={20}>{render_grouping()}</Col>
              <Col span={20}>
                <Space direction="vertical">
                  <Button type="default" icon={<MailTwoTone />} onClick={() => sendEmail()} size={"small"}>
                    Send Mail
                  </Button>
                  <Button type="default" icon={<MailTwoTone />} onClick={() => download_email()} size={"small"}>
                    Download Email
                  </Button>
                </Space>
              </Col>
            </Row>
            &nbsp;
          </div>}
        </Modal>
      ) : (
        <></>
      )}
    </>
  );
};

export default CommsEmail;

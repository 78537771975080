import { useRef, useState, useEffect } from 'react';
import DropdownButton from "react-bootstrap/DropdownButton";
import Table from "../Table";
import "react-calendar/dist/Calendar.css";
import "react-datepicker/dist/react-datepicker.css";
import 'react-tabs/style/react-tabs.css';
import { groupBy } from "../../services/helper";
import * as storage from '../../services/migserv_storage'
import ApproveOrReqMigrationModal from "../TableActions/ApproveOrReqMigrationModal";
import useGlobalStore from "../../store";
import { shallow } from "zustand/shallow";
import { TableActionTypes } from "../../types/Action_Types";
import appAxios from "../app_config/axios";
import RescheduleModal_v2 from "../TableActions/RescheduleModal_v2";
import DropModal from "../TableActions/DropModal";


const migservAPIURL = process.env.REACT_APP_MIGSERV_API_URL;

export const AppManagerAdmin = () => {
  const tableRef = useRef(null);
  const newSelectRow = useRef(null);
  const [appData, setData] = useState([]);
  const [username, setUsername] = useState('')
  const [isLoading, setLoading] = useState(true);
  const [searchColumns, setSearchColums] = useState([
    "wave_calendar_date"
  ]);
  const [wavename, setWavename] = useState([]);
  const [isChecked, setIsChecked] = useState('approval-needed');

  const { selected_rows_app_manager } = useGlobalStore((state) => ({
    selected_rows_app_manager: state.selected_rows_app_manager
  }), shallow)

  useEffect(
    () => {

      (async () => {
        let username = storage.get('user.isid', '')
        setUsername(username)
        const result = await appAxios.get(
          migservAPIURL + "/applications?app_managed_by=" + username.toLowerCase()
        );
        if (result.data == null) { return ("No Applications") }
        else {
          result.data.sort(function (a, b) {
            return a?.wave_calendar_date?.localeCompare(b?.wave_calendar_date);
          });
          setData(result);
          setLoading(false);
        }
      })();
      (async () => {
        const url =
          migservAPIURL + "/migration_waves";
        const result1 = await appAxios.get(url);
        setWavename(result1.data);
      })();
    }, []);

  //end added by sharath 3/15/2023
  const refresh = () => {
    (async () => {
      const result = await appAxios.get(
        migservAPIURL + "/applications?app_managed_by=" + username.toLowerCase()
      );
      setData(result);
      setLoading(false)
    })();
  };

  const reset_state = () => {
    HandleReset()
  }

  const cancel_action = () => {
    HandleReset()
  };

  function HandleReset() {
    if (tableRef.current != null) {
      tableRef.current.resetRowSelection()
    }
  }

  // Set up the logic for the radio buttons default filters
  const search = (rows) => {
    let filtered_rows = []
    // Display any row that has a "Week" value, should be in the format <YY>W<WW>
    if (isChecked == "migrating") {
      filtered_rows = rows.filter((row) => row?.wave_name && row.wave_name !== "" && row.wave_name !== "null")
      return filtered_rows
      // Show all results
    } else if (isChecked == "all") {
      return rows
      // Show only the rows that have the intital Approval Status 'Scheduled', meaning they've
      //  been scheduled and need approval.  This is also the default state upon load, so have
      //  it here as the fall through option
    } else if (isChecked == "approval-needed") {
      filtered_rows = rows.filter((row) => row?.approval_status === 'SCHEDULED')
      return filtered_rows
    } else {
      filtered_rows = rows.filter((row) =>
        searchColumns.some(
          (column) => row[column] == "lctcsd1500"
        )
      );
      return filtered_rows
    }
  }

  const HandleSelectedRows = (r) => {
    newSelectRow.current = {
      getRow: () => r,
      getActRow: () => {
        const ar = []
        r.forEach((el) => {
          ar.push(el.original)
        })
        return ar
      }
    }
  }
  if (isLoading) {
    return <div style={{ width: "96%", margin: " 40px auto" }} className="Loading">Loading...</div>;
  }


  let appArray = [];
  try {
    const tempAppObject = groupBy(search(appData.data), "appname");
    appArray = Object.keys(tempAppObject);
  } catch (e) {

  }

  return (
    <div style={{ width: "96%", margin: 'auto' }}>
      <div className="mx-auto py-3 px-4 flex justify-between border border-black">
        <div className="bulits">
          <label className="bulits">
            <input
              type="radio"
              name="filter"
              value="approval-needed"
              defaultChecked
              onChange={e => setIsChecked(e.target.value)}
              className="outline-none bg-white rounded-md py-2 px-3 border border-black"
            /> APPROVAL NEEDED
          </label>
          <label className="bulits">
            <input
              type="radio"
              name="filter"
              value="migrating"
              onChange={e => setIsChecked(e.target.value)}
              className="outline-none bg-white rounded-md py-2 px-3 border border-black"
            /> SCHEDULED TO MIGRATE
          </label>
          <label className="bulits">
            <input
              type="radio"
              name="filter"
              value="all"
              onChange={e => setIsChecked(e.target.value)}
              className="outline-none bg-white rounded-md py-2 px-3 border border-black"
            /> ALL APPS
          </label>

        </div>
        <DropdownButton variant="contained" className="bannerBg" size="lg" id="dropdown-item-button" title="Select an Action" drop="down">
          <ApproveOrReqMigrationModal onCancel={cancel_action} action_type={TableActionTypes.APPROVE} onSubmit_text={'Approve'} tableRef={tableRef} />
          <RescheduleModal_v2 wavename={wavename} />
          <DropModal />
          <ApproveOrReqMigrationModal onCancel={cancel_action} action_type={TableActionTypes.REQMIG} onSubmit_text={'Request'} tablRef={tableRef} />
        </DropdownButton>
      </div>


      <Table
        HandleSelectedRows={HandleSelectedRows}
        search={() => search(appData.data)} appArray={appArray}
        tableRef={tableRef}
      />

    </div >
  );


};



export default AppManagerAdmin;
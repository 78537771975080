import { Button, Modal } from "antd"

const ErrorDescription = (props) => {

    return (
        <>
            {props?.short_description ? props?.short_description : null}
            {props?.detailed_description ?
                <Button type="link"
                    onClick={() => Modal.info({
                        title: "Error Details",
                        content: props.detailed_description
                    })}>More Details</Button> : null}
        </>

    )
}

export default ErrorDescription
import React, { useEffect, useState } from "react";
import { FaRegUser } from "react-icons/fa";
import { FiSettings } from "react-icons/fi";
import { BellTwoTone } from '@ant-design/icons'
import * as storage from "../../services/migserv_storage";
import AppMenu from "./AppMenu";
import { useLocation, useNavigate } from "react-router-dom";
import useGlobalStore from "store";
import { shallow } from "zustand/shallow";
import './header.css'

function Template({ override_exclude_path = false }) {
  const [userID, setuserID] = useState("");
  const location = useLocation();
  const navigate = useNavigate()
  const exclude_template_paths = ["/config"];
  const { notification_count } = useGlobalStore(
    (state) => ({
      notification_count: state.notification_count,
    }),
    shallow
  );

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  useEffect(() => {
    setuserID(storage.get("user.isid", ""));
  }, []);

  if (!override_exclude_path && exclude_template_paths.includes(location?.pathname)) {
    return <></>
  }

  return (
    <div>
      <div
        className="flex bannerBg h-[80px] justify-between items-center p-5 m-auto"
        style={{ width: "96%" }}
      >
        <div className="w-[200px] h-[200px]">
          <img
            src="/images/logo.png"
            alt="logo"
            className="w-full h-full object-contain"
          />
        </div>
        <h1 className="text-white font-secondary font-bold text-4xl w-full text-center bannerHead">
          MIGRATION SERVICES
        </h1>
      </div>

      <div
        className="flex justify-end mx-auto p-7 border border-black"
        style={{ width: "96%" }}
      >
        <div className="flex gap-10">
          <div className="flex items-center gap-2 justify-center relative">
            <AppMenu />
          </div>
          <div className="flex items-center gap-2 justify-center relative" onClick={() => navigate('/config')} >
            <div className="hover-text ">
              <FiSettings className="text-3xl cursor-pointer" />
              <span className="tooltip-text" id="bottom">
                Settings
              </span>
            </div>
          </div>
          <div className="flex items-center gap-2 justify-center relative">
            <div className="hover-text ">
              <FaRegUser className="text-3xl cursor-pointer" />
              <span className="tooltip-text" id="bottom">
                {userID}
              </span>
            </div>
          </div>
          <div className="flex items-center gap-2 justify-center relative" onClick={() => navigate('/notifications')}>
            <div className="hover-text">
              <div className="bell-icon-container">
                <BellTwoTone style={{ fontSize: '20px' }} className="cursor-pointer chime-effect" twoToneColor={notification_count == 0 ? undefined : 'red'} />
                
                {notification_count > 0 ? <span className="notification-count">{notification_count}</span>: <></> }
              </div>
              <span className="tooltip-text" id="bottom">
                Events
              </span>
            </div>
          </div>

          {/* <div className="flex items-center gap-2 justify-center">
          <SlCalender className="text-3xl" />
        </div>
        <div className="flex items-center gap-2 justify-center">
          <FaRegUser className="text-3xl" />
        </div> */}
        </div>
      </div>
    </div>
  );
}

export default Template;

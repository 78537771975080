import {Divider} from "antd";
import {FC, useEffect, useRef, useState,} from "react";
import appAxios from "../app_config/axios";
import ReactMaterialTableView from "./ReactMaterialTableView";
import ViewDetails from "./ViewDetails";
import * as _ from "lodash";
import ReactMaterialTableViewV2 from "./ReactMaterialTableViewV2";
import {MaterialReactTableProps} from "material-react-table";


const migservAPIURL = process.env.REACT_APP_MIGSERV_API_URL;

interface ViewBuilderProps {
  table_library?: "ReactMaterialTable" | "ReactMaterialTable_v2" | "OtherLibrary";
  view_config?:
    | {
    [key: string]: any;
  }
    | {
    data_format?: "json" | "csv";
    migration_wave?: string;
    view_name?: string;
    endpoint?: string;
    endpoint_params?: { [key: string]: any }
    exclude_properties?: string[];
    hide_view_details?: boolean;
    enable_editing_for?: string[]
    enable_editing?: boolean
    data?: { [key: string]: string | number | null | undefined }[];
    initial_state_react_material_table?: { [key: string]: any };
    material_react_table_props?: MaterialReactTableProps<Record<any, any>>;
  };
}

let view_config_init = {
  endpoint_params: {
    migration_wave: "23W28",
    view_name: "content",
    format: 'json'
  },
  endpoint: `${migservAPIURL}/views`,
  hide_view_details: false,
  initial_state_react_material_table: {},
  enable_editing_for: [],
  enable_editing: false,
  material_react_table_props: {}
};

export type ObjectDataType = {
  [key: string]: string | number | null | undefined;
}[];
const ViewBuilder: FC<ViewBuilderProps> = ({
                                             table_library = "ReactMaterialTable",
                                             view_config = view_config_init,
                                           }) => {
  const [viewData, setViewData] = useState<ObjectDataType | []>([]);
  const [viewDataColumns, setViewDataColumns] = useState<ObjectDataType | []>([]);
  const [view_config_state, set_view_config_state] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);

  const isMounted = useRef(false);

  useEffect(() => {
    view_config_state_update()
  }, [view_config])

  const view_config_state_update = (updated_state: any = {}) => {
    let _view_config = {...view_config_init, ...view_config, ...updated_state};
    if (!isMounted.current) {
      _view_config['endpoint_params'] = {..._view_config['endpoint_params'], ...getQueryParamsFromURL()}
    }
    if (updated_state?.['endpoint_params']) {
      _view_config['endpoint_params'] = {..._view_config['endpoint_params'], ...updated_state['endpoint_params']}
    }

    if (!_.isEqual(_view_config, view_config)) {
      set_view_config_state(_view_config);
    }
  }

  const getQueryParamsFromURL = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const params: any = {};

    searchParams.forEach((value, key) => {
      params[key] = value;
    });
    return params
  }

  useEffect(() => {
    setViewDataColumnsFromViewData();
  }, [viewData]);

  useEffect(() => {
    if (isMounted.current) {
      if (
        view_config_state?.["data"]
      ) {
        setViewData(view_config_state?.["data"]);
      } else {
        fetchEndpoint();
      }
    } else {
      isMounted.current = true;
    }
  }, [view_config_state]);

  const fetchEndpoint = () => {
    setViewData([]);
    setIsLoading(true);
    let params = _.clone(view_config_state['endpoint_params']);
    appAxios
      .get<ObjectDataType | string>(view_config_state?.["endpoint"], {
        params: params,
      })
      .then(({data}) => {
        if (Array.isArray(data)) {
          let _data = apply_exclusions(data);
          setViewData(_data);
        } else if (_.isObject(data)) {
          setViewData(apply_exclusions([data]));
        } else {
          setViewData([])
        }
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        setViewData([])
      });
  };

  const apply_exclusions = (data: any[]) => {
    if (
      view_config?.exclude_properties &&
      Array.isArray(view_config?.exclude_properties)
    ) {
      let _data = _.clone(data);
      _data.forEach((element, index) => {
        Object.keys(element).forEach((key) => {
          if (view_config?.exclude_properties.includes(key)) {
            delete _data[index][key];
          }
        });
      });
      return _data;
    } else {
      return data;
    }
  };

  const setViewDataColumnsFromViewData: () => void = () => {
    let keys: any[] = [];
    if (viewData.length > 0) {
      keys = Object.keys(viewData[0]);
    }

    if (table_library == "ReactMaterialTable" || table_library == "ReactMaterialTable_v2") {
      keys = keys.map((item) => {
        return {
          accessorKey: item,
          header: item,
          enableEditing: view_config_state?.['enable_editing_for'].includes(item)
        };
      });
    }
    setViewDataColumns(keys);
  };

  const on_view_config_change = (
    updated_view_config: ViewBuilderProps["view_config"]
  ) => {
    if (!_.isEqual(view_config_state, updated_view_config)) {
      view_config_state_update(updated_view_config)
    }
  };

  return (
    <>
      {view_config_state?.["hide_view_details"] === false ? (
        <>
          <ViewDetails
            view_config={view_config_state}
            on_view_config_change={on_view_config_change}
          />
          <Divider/>
        </>
      ) : (
        <></>
      )}
      {table_library === "ReactMaterialTable"
        ? (
          <ReactMaterialTableView
            columns={viewDataColumns}
            data={viewData}
            isLoading={isLoading}
            enableEditing={view_config_state['enable_editing']}
            initialState={view_config["initial_state_react_material_table"]}
          />
        ) : table_library == "ReactMaterialTable_v2" ?
          <ReactMaterialTableViewV2
            columns={viewDataColumns}
            data={viewData}
            isLoading={isLoading}
            enableEditing={view_config_state['enable_editing']}
            initialState={view_config["initial_state_react_material_table"]}
            material_react_table_props={view_config['material_react_table_props']}
          /> : (
            <></>
          )}
    </>
  );
};

export default ViewBuilder;

import { Button, Col, Collapse, Divider, Modal, Row, Tag } from "antd";
import _ from "lodash";
import { FC, useEffect, useState } from "react";
import HtmlPreview from "../../common/HtmlPreview";
import AdditionalEmailsField from "../../common/AdditionalEmailsField";
import { SendOutlined } from "@ant-design/icons";
import { retrieveUserInfo } from '../../../services/migserv_auth'
import { GroupedPreviewItem } from ".";

const { Panel } = Collapse;

interface PreviewModalPropType {
  htmlPreview?: string;
  commsPreviewData?: any;
  showPreviewModal?: boolean;
  setShowPreviewModal?: (status: boolean) => any;
  on_emails_added_or_removed: (emails: string[], type:any) => any;
  send_email?: any;
  groupedPreviewItem: GroupedPreviewItem
}
const PreviewModal: FC<PreviewModalPropType> = ({
  htmlPreview = "",
  commsPreviewData = {},
  showPreviewModal = false,
  setShowPreviewModal = () => { },
  on_emails_added_or_removed,
  send_email = () => { },
  groupedPreviewItem = {}
}) => {
  const [user_info, setUserInfo] = useState<any>({})
  useEffect(() => {
    setUserInfo(retrieveUserInfo())
  }, [])

  return (
    <Modal
      destroyOnClose
      open={showPreviewModal}
      footer={null}
      title={"Preview Email"}
      onCancel={() => setShowPreviewModal(false)}
      width={"80vw"}
      style={{ overflowY: "auto" }}
    >
      <div>
        <Row align="middle" justify={"center"}>
          <Col span={24}></Col>
        </Row>
        <Row align="middle" justify={"center"}>
          <Col span={20}>
            <Row style={{ border: "1px solid #ccc" }} align="middle" justify={"center"} gutter={[10, 10]}>
              <Col span={24}>
                <div style={{ borderBottom: "1px solid #ccc", paddingBottom: "1%", paddingTop: "1%", width: "100%" }}>
                  <Button
                    icon={<SendOutlined />}
                    onClick={() => (send_email ? send_email(true) : null)}
                    title="Send Email"
                  >
                    Send Email
                  </Button>
                </div>
              </Col>
              <Col span={2}>
                <Tag>TO</Tag>
              </Col>
              <Col span={22}>
                <div
                  style={{
                    borderLeft: "1px solid #ccc",
                    paddingLeft: "1%",
                    paddingBottom: "1%",
                    height: "100%",
                    width: "100%",
                  }}
                >
                  <AdditionalEmailsField
                    max_width={"100%"}
                    default_width={"100%"}
                    max_height={"50px"}
                    email_added_hook={(emails)=>on_emails_added_or_removed(emails,'to_list')}
                    email_removed_hook={(emails)=>on_emails_added_or_removed(emails,'to_list')}
                    detach_from_central_store
                    initial_email_list={groupedPreviewItem?.['to_list'] ? groupedPreviewItem['to_list'] : []}
                  />
                </div>
              </Col>
              <Col span={2}>
                <Tag>CC</Tag>
              </Col>
              <Col span={22}>
                <div
                  style={{
                    borderLeft: "1px solid #ccc",
                    paddingLeft: "1%",
                    paddingBottom: "1%",
                    height: "100%",
                    width: "100%",
                  }}
                >
                  <AdditionalEmailsField
                    max_width={"100%"}
                    default_width={"100%"}
                    max_height={"50px"}
                    email_added_hook={(emails)=>on_emails_added_or_removed(emails,'cc_list')}
                    email_removed_hook={(emails)=>on_emails_added_or_removed(emails,'cc_list')}
                    detach_from_central_store
                    initial_email_list={groupedPreviewItem?.['cc_list'] ? groupedPreviewItem['cc_list'] : []}
                  />
                </div>
              </Col>
              <Col span={2}>
                <Tag>BCC</Tag>
              </Col>
              <Col span={22}>
                <div
                  style={{
                    borderLeft: "1px solid #ccc",
                    paddingLeft: "1%",
                    paddingBottom: "1%",
                    height: "100%",
                    width: "100%",
                  }}
                >
                  <AdditionalEmailsField
                    max_width={"100%"}
                    default_width={"100%"}
                    max_height={"50px"}
                    email_added_hook={(emails)=>on_emails_added_or_removed(emails,'bcc_list')}
                    email_removed_hook={(emails)=>on_emails_added_or_removed(emails,'bcc_list')}
                    detach_from_central_store
                    initial_email_list={groupedPreviewItem?.['bcc_list'] ? groupedPreviewItem['bcc_list'] : []}
                  />
                </div>
              </Col>
              <Col span={2}>
                <Tag>REPLY TO</Tag>
              </Col>
              <Col span={22}>
                <div
                  style={{
                    borderLeft: "1px solid #ccc",
                    paddingLeft: "1%",
                    paddingBottom: "1%",
                    height: "100%",
                    width: "100%",
                  }}
                >
                  <AdditionalEmailsField
                    max_width={"100%"}
                    default_width={"100%"}
                    max_height={"50px"}
                    detach_from_central_store
                    initial_email_list={groupedPreviewItem?.['reply_to'] ? _.uniq(groupedPreviewItem['reply_to']) : []}
                    allow_removing_emails={false}
                    allow_adding_emails={false}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row align="middle" justify="center" gutter={[16, 16]}>
          <Col span={20}></Col>
          <Col span={20}>
            <HtmlPreview
              rawHtml={htmlPreview}
              useRaw
              style={{ maxHeight: "100vh", border: "5px solid #000000DE", overflow: "scroll" }}
            />
          </Col>
          {/* <Col span={20}>
            {_.isEmpty(commsPreviewData) ? (
              "No Tables to generate"
            ) : (
              <Collapse>
                <Panel header={`Comms Table`} key="1">
                  <ViewBuilder
                    view_config={{
                      data: Object.keys(commsPreviewData).map((key: string) => {
                        return {
                          Communication: commsPreviewData[key]["human_readable_propname"],
                          "Date/Time": commsPreviewData[key]["string_value"],
                        };
                      }),
                      hide_view_details: true,
                    }}
                  />
                </Panel>
              </Collapse>
            )}
          </Col> */}
        </Row>
      </div>
    </Modal>
  );
};

export default PreviewModal;

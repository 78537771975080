const apiUrl = process.env.REACT_APP_MIGSERV_API_URL ? process.env.REACT_APP_MIGSERV_API_URL : ''

export interface AdditionalEmailsFieldInterface {
    cc_emails: string[],
    set_cc_emails: (emails: string[]) => any,
    remove_cc_email: (email: string) => any,
    reset_cc_emails: () => any,
    add_cc_emails: (emails: string[])=>any
}
export const createAdditionalEmailsFieldSlice = (set: (state: any) => any, get: () => any): AdditionalEmailsFieldInterface => ({
    cc_emails: [],
    set_cc_emails: (emails) => set(({ cc_emails: emails })),
    remove_cc_email: (email) => {
        let mails = get().cc_emails
        mails = mails.filter((item: string) => item !== email)
        set({ cc_emails: mails })
    },
    reset_cc_emails: () => set(({ cc_emails: [] })),
    add_cc_emails: (emails)=>{
        let distinct_emails:string[] = []
        emails.forEach((email_to_add)=>{
            if(!get().cc_emails.includes(email_to_add)){
                distinct_emails.push(email_to_add)
            }
        })
        set(({cc_emails: [...get().cc_emails, ...distinct_emails ]}))
    }
})
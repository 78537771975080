import * as storage from './migserv_storage';
import { AuthSession } from '@aws-amplify/core'
import _ from 'lodash';



//return saveUserInfo();


// Get the ISID from the IDToken and query Merck Directory API for user information
export async function saveUserInfo(session: AuthSession) {
  let _identity = _.pick(session?.tokens?.idToken?.payload, ['email', 'family_name', 'given_name', 'identities', 'cognito:groups'])
  let userId = null
  //@ts-ignore
  let object_with_userId: any = _.find(_identity?.['identities'], 'userId')
  if (object_with_userId) {
    userId = object_with_userId['userId']
  }
  storage.set('user.email', _identity.email);
  storage.set('user.isid', userId);
  storage.set('user.familyName', _identity.family_name);
  storage.set('user.givenName', _identity.given_name);
  storage.set('user.cognito:groups', JSON.stringify(_identity['cognito:groups']))
}

export function retrieveUserInfo<User>() {
  let cognito_groups = storage.get<string>('user.cognito:groups') || '[]'
  return {
    userEmail: storage.get<string>('user.email') || '',
    userIsid: storage.get<string>('user.isid') || '',
    userLastname: storage.get<string>('user.familyName') || '',
    userFirstname: storage.get<string>('user.givenName') || '',
    'cognito:groups': JSON.parse(cognito_groups)
  }
}


import { Col, Row, Space } from "antd";
import AppMenu from "./AppMenu";
import { FaRegUser } from "react-icons/fa";
import { FiSettings } from "react-icons/fi";
import {BellTwoTone} from '@ant-design/icons'

const HeaderV2 = () => {
  return (
    <Row justify={"center"}>
      <Col span={22}>
        <Row gutter={[48, 0]} align="middle" style={{ background: "#00857C" }}>
          <Col span={8}>
            <img
              src="/images/logo.png"
              alt="logo"
              style={{ maxWidth: "180px", maxHeight: "180px" }}
            />
          </Col>
          <Col span={8}>
            <h1 className="text-white font-secondary font-bold text-4xl w-full text-center bannerHead">
              MIGRATION SERVICES
            </h1>
          </Col>
        </Row>
      </Col>
      <Col span={22}>
        <Row
          justify={"end"}
          gutter={[48, 0]}
          align="middle"
          style={{ background: "rgba(0, 0, 0, 0.271)" }}
        >
          <Col span={18}></Col>
          <Col span={6} style={{ background: "white" }}>
              <Space direction="horizontal" size={"large"} style={{borderStyle: 'solid'}}>
                <AppMenu />

                <FiSettings className="text-3xl cursor-pointer" />
                <BellTwoTone style={{fontSize:'18px'}} className="cursor-pointer" />
                <FaRegUser className="text-3xl cursor-pointer" />
                <span className="tooltip-text" id="bottom">
                  {"priyank"}
                </span>
              </Space>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default HeaderV2;

import { Row, Col } from 'antd'
import { FC, useEffect } from 'react'
import createDOMPurify from 'dompurify'
import _ from 'lodash'


const DOMPurify = createDOMPurify(window)

interface HtmlPreviewPropType {
  rawHtml?: string
  useRaw?: boolean
  style?: any
  allowedit?: boolean
  onHtmlEdited?: (html: string | null | undefined) => any
}

const HtmlPreview: FC<HtmlPreviewPropType> = ({ onHtmlEdited = null, allowedit = false, rawHtml = `<h1>Nothing to preview</h1>`, useRaw = false, style = {} }) => {
  let previewStyle = { border: '5px solid #000000DE', padding: '10px', maxHeight: '50vh', overflow: 'scroll' }
  if (_.isObject(style)) {
    previewStyle = {
      ...previewStyle,
      ...style
    }
  }

  const onEdit = () => {
    if (onHtmlEdited) {
      onHtmlEdited(document.getElementById('preview_html_content_div')?.innerHTML)
    }
  }
  return (
    <div style={previewStyle}>
      <Row >
        <Col span={24}>
          <p><b><i>*Html Preview</i></b></p>
        </Col>
        <Col span={24}>
          {<div onInput={onEdit} contentEditable={allowedit} id="preview_html_content_div" dangerouslySetInnerHTML={{ __html: useRaw ? rawHtml : DOMPurify.sanitize(rawHtml) }} />}
        </Col>
      </Row>
    </div>
  )
}

export default HtmlPreview
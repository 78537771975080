import { Col, Row, Input, TimePicker, Select } from "antd";
import { FC, useEffect, useMemo } from "react";
import { UserAddOutlined, DeleteOutlined } from "@ant-design/icons";
import { MaterialReactTable } from "material-react-table";
import useGlobalStore from "store";
import { shallow } from "zustand/shallow";
import get_date_config_property from "store/DateConfigStore";
import { weekMap } from "services/helper";
const { Search } = Input;
interface ManageMigDatesProps {
  migtype: string;
}

const ManageMigDates: FC<ManageMigDatesProps> = ({ migtype = "" }) => {
  const onChange = () => {};
  useEffect(() => {
    let date = get_date_config_property(migtype,'default_mig_date',null,null,'',false)
  });
  return (
    <>
      <Row>
        <Col span={10}>
          <h5>Select Default Migration Date</h5>
          <Select showSearch defaultValue={'Monday'} options={Object.keys(weekMap).map((item)=>{return {value: item, label: item}})} /> &nbsp;
          <TimePicker format={'h:mm A'} />
        </Col>
      </Row>
    </>
  );
};

export default ManageMigDates;

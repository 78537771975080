import { Tag, Input, Space, Row, Tooltip } from 'antd'
import { FC, useEffect, useState } from 'react'
import { InfoCircleOutlined, UserOutlined } from '@ant-design/icons'
import useGlobalStore from '../../store'
import { shallow } from 'zustand/shallow'


interface UserIsidField {
    isid_added_hook?: () => string[]
    max_width?: string
    default_width?: string
}

const UserIsidField: FC<UserIsidField> = ({ isid_added_hook, max_width = '750px', default_width = '750px' }) => {
    const [inputFieldError, setInputFieldError] = useState(false)
    const [inputFieldFocus, setInputFieldFocus] = useState(false)
    const [inputFieldValue, setInputFieldValue] = useState('')

    const { user_isids, set_user_isids } = useGlobalStore((state) => ({
        user_isids: state.user_isids,
        set_user_isids: state.set_user_isids
    }), shallow)

    useEffect(() => {
        reset_isid_array()
        return ()=>{
            console.log('COMPONENT IS DESTROYED !')
        }
    }, [])

    useEffect(() => {

    }, [inputFieldValue])

    const onPressEnter = () => {
        if (isDuplicate(inputFieldValue)) {
            window.alert('Given ISID is already in the list')
            setInputFieldValue('')
        } else {
            set_user_isids([...user_isids,inputFieldValue])
            setInputFieldValue('')
        }

    }

    const reset_isid_array = () => {
        set_user_isids([])
    }

    const remove_isid_from_array = (isid: string) => {
        let filtered = user_isids.filter((item) => item !== isid)
        set_user_isids(filtered)
    }

    const isDuplicate: (isid: string) => boolean = (isid) => {
        return user_isids.find((item) => item === isid) === undefined ? false : true
    }


    return (
        <Space direction='vertical' size={10}>
            <Input
                width={100}
                size="small"
                addonBefore="Merck ISID"
                status={inputFieldError && inputFieldFocus ? "error" : ""}
                placeholder="Enter valid Merck ISID"
                prefix={<UserOutlined className="site-form-item-icon" />}
                suffix={
                    inputFieldError && inputFieldFocus ?
                        <Tooltip title="Extra information">
                            <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                        </Tooltip> : null
                }
                value={inputFieldValue}
                onChange={(event) => setInputFieldValue(event.target.value)}
                onFocus={() => setInputFieldFocus(true)}
                onBlur={() => setInputFieldFocus(false)}
                onPressEnter={(event) => onPressEnter()}

            />
            <div style={{ maxWidth: max_width, width: default_width, overflowX: 'scroll' }}>
                {user_isids.length > 0 ?

                    (<Space>
                        {user_isids.map((item) => <Tag key={item} closable onClose={(e) => remove_isid_from_array(item)}>{item}</Tag>)}
                    </Space>) : null}
            </div>

        </Space>
    )
}

export default UserIsidField
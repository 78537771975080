import { Button, Collapse, Descriptions, Input, Space } from "antd"
import { FC, useEffect, useState } from 'react'
const { Panel } = Collapse


type view_config_type = {
    [key: string]: any
}

interface ViewDetailsProps {
    view_config?: view_config_type
    on_view_config_change?: (view_config: view_config_type) => any
};
let keys_to_filter = ['initial_state_react_material_table', 'data', 'hide_view_details','format', 'endpoint']

const ViewDetails: FC<ViewDetailsProps> = ({ view_config = {}, on_view_config_change }) => {

    const [view_config_state, set_view_config_state] = useState<view_config_type>(view_config)

    useEffect(()=>{
        set_view_config_state(view_config)
    },[view_config])
    

    const onTextChange = (e: any, view_config_key: string) => {
        let updated_view_config = { ...view_config_state, endpoint_params: {...view_config_state['endpoint_params'], [view_config_key]: e.target.value} }
        console.log('updated to')
        console.log(updated_view_config)
        set_view_config_state(updated_view_config)
    }

    const onSubmit = () => {
        if (on_view_config_change) {
            console.log('submitting ')
            console.log(view_config_state)
            on_view_config_change(view_config_state)
        }
    }
    return <>
        <Collapse defaultActiveKey={['1']}>
            <Panel header="Application Details" key="1">
                <Space direction='vertical'>
                    <Descriptions
                        size='small'
                        bordered
                        column={{ xxl: 4, xl: 5, lg: 4, md: 1, sm: 1, xs: 1 }}
                    >
                        {Object.keys(view_config_state?.['endpoint_params']).map((key: string) => {
                            if(!keys_to_filter.includes(key)){
                                return (<Descriptions.Item key="1" label={key} >
                                {/* {view_config_state[key]} */}
                                <Input value={view_config_state['endpoint_params'][key]} onChange={(e) => onTextChange(e, key)} />
                            </Descriptions.Item>)
                            }
                        })}
                    </Descriptions>

                    <Button type='default' onClick={onSubmit}>Submit</Button>
                </Space>
            </Panel>
        </Collapse>
    </>
}

export default ViewDetails
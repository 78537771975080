import { Col, Row, Input, Space, Select } from "antd";
import { FC, useMemo } from "react";
import { UserAddOutlined, DeleteOutlined } from "@ant-design/icons";
import { MaterialReactTable } from "material-react-table";
const { Search } = Input;
interface ManageUsersProps {
  migtype: string;
}

const ManageUsers: FC<ManageUsersProps> = ({ migtype = "" }) => {
  const columns: any = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "NAME",
      },
      {
        accessorKey: "email",
        header: "EMAIL",
      },
      {
        accessorKey: "actions",
        header: "ACTIONS",
        size: "230",
        Cell: (cell: { renderedCellValue: any; row: any }) => {
          return (
            <Select
              size={"small"}
              defaultActiveFirstOption
              placeholder="Select an Action"
            >
              <Select.Option size="small">Delete</Select.Option>
            </Select>
          );
        },
      },
    ],
    []
  );

  const data = [
    {
      name: "Priyank R",
      email: "priyank.rupareliya@merck.com",
    },
    {
      name: "Anand K",
      email: "anand.k@merck.com",
    },
    {
      name: "Rob W",
      email: "rob.w@merck.com",
    },
  ];

  return (
    <>
      <Row justify={"space-between"}>
        <Col span={10}>
          <Space direction="horizontal">
            <Search
              size="middle"
              placeholder="Search users"
              onSearch={() => null}
            />
            <UserAddOutlined
              style={{ fontSize: "15px", cursor: "pointer" }}
              placeholder="Add User"
            />
          </Space>
        </Col>
        <Col span={8}>
          <Select
            size={"small"}
            defaultActiveFirstOption
            placeholder="Bulk Actions"
          >
            <Select.Option size="small">Delete</Select.Option>
          </Select>
        </Col>
      </Row>
      <Row>
        <Col span={20}>
          <MaterialReactTable
            columns={columns}
            data={data}
            enableRowSelection
          />
        </Col>
      </Row>
    </>
  );
};

export default ManageUsers;

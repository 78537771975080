import {fetchAuthSession} from "aws-amplify/auth"

export const getUserIdentity = async () => {
    let session = await fetchAuthSession()
    return {
        sso_isid: session.tokens.idToken.payload['cognito:username'],
        isid: removePingfedPrefix(session.tokens.idToken.payload['cognito:username']),
        email: session.tokens.idToken.payload['email']
    }

}

export function removePingfedPrefix(inputString) {
    return inputString.replace(/^MERCK-PINGFED-SSO_/, '');
}

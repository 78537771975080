import React, { useState } from 'react';
import {  Modal, Typography } from 'antd';
import JSONPretty from 'react-json-pretty';
import 'react-json-pretty/themes/acai.css'

const { Text } = Typography;

const JsonViewer = ({ jsonData }) => {
    const [modalVisible, setModalVisible] = useState(false);

    const handleOpenModal = () => {
        setModalVisible(true);
    };

    const handleCloseModal = () => {
        setModalVisible(false);
    };

    return (
        <>
            <button type="primary" onClick={handleOpenModal}>
                View JSON
            </button>
            <Modal
                title="JSON Viewer"
                open={modalVisible}
                onCancel={handleCloseModal}
                footer={null}
                width={600}
                destroyOnClose
            >
                {/* <Text strong>JSON Data:</Text> */}
                <JSONPretty json={jsonData} />
            </Modal>
        </>
    );
};

export default JsonViewer;

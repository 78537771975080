export type Key = string | number;
export type KeyValue<T = any> = Record<Key, T>;


export interface User {
  userIsid: string,
  userFirstname: string,
  userLastname: string,
  userEmail: string,
}


export interface User_Data {
  accountStatusCode: number,
  alternativeEmail: string[],
  company: string,
  country: string,
  countryCode: string,
  department: string,
  displayName: string,
  divisionId: string,
  divisionName: string,
  dn: string,
  email: string,
  employeeID: number,
  employeeId: number,
  familyName: string,
  fullName: string,
  givenName: string,
  isid: string,
  location: string,
  manager: string,
  phone: string,
  postalCode: string,
  state: string,
  streetAddress: string,
  title: string,
  unixHomeDirectory: string,
  unixUid: string,
  userPrincipalName: string,
  _links: string[]
}

export interface ParseJob {
  id: number,
  project_id: number,
  input_file_key: string,
  started_at: string,
  updated_at: string,
  current_step: string,
  status: string,
  error_message: string,
}

export interface Project {
  id: number,
  name: string,
  description: string
}

interface identity {
  "userId": string,
  "providerName": string,
  "providerType": string,
  "issuer": string,
  "primary": boolean,
  "dateCreated": number
}

export interface IdTokenPayload {
  "at_hash": string,
  "sub": string,
  "iss": string,
  "cognito:groups": string[],
  "cognito:username": string,
  "nonce": string,
  "origin_jti": string,
  "aud": string,
  "identities": identity[],
  "token_use": string,
  "auth_time": number,
  "exp": number,
  "iat": number,
  "jti": string
}

export type TokenResponse = {
  id_token: string;
  access_token: string;
  refresh_token?: string;
  expires_in: number;
  token_type: 'Bearer';
};

export const MigTypes = {
  'APEX': 'APEX',
  'BlueSky Rehost': 'BlueSky Rehost',
  'GreenLake': 'GreenLake',
  'GreenLake Live': 'GreenLake Live',
  'GreenLake SRM': 'GreenLake SRM',
  'River Meadow': 'River Meadow',
  'Robo': 'Robo',
}

export const QuestionnaireMigTypes = {
  'APEX': 'APEX',
  'BlueSky Rehost': 'BlueSky Rehost',
  'GreenLake': 'GreenLake',
  'MCS': 'MCS',
  'P2V': 'P2V',
  'River Meadow': 'River Meadow',
}

export const MigTypeColors: { [key: string]: string } = {
  'BlueSky Rehost': '#00B0F0',
  'Robo': '#FF0000',
  'MCS': 'grey',
  'GreenLake Live': '#92D050',
  'GreenLake': '#92D050',
  'GreenLake SRM': '#92D050',
  'APEX': '#ED7D31',

}

export const vars_to_english: { [key: (string | number)]: string } = {
  'email_app_shutdown': 'App Shutdown',
  'email_approval': 'Approval',
  'email_downtime_start': 'Downtime Start',
  'email_downtime_complete': 'Downtime Complete',
  'email_hypercare_start': 'Hypercare Start',
  'email_migration_complete': 'Hypercare Complete',
  'email_migration_schedule': 'Migration Schedule',
  'email_in_progress': 'In Progress',
}

export type MigservEvents = 'APPROVAL_DATA_UPDATE' | 'EMAIL_TRIGGERED' | 'DUMMY_EVENT'
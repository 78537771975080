import { Alert, Row, Col, notification } from "antd"
import { shallow } from "zustand/shallow";
import useGlobalStore from "../../store";
import { useEffect } from 'react'

type NotificationType = 'success' | 'info' | 'warning' | 'error';
const AlertComponent = () => {
    const [api, contextHolder] = notification.useNotification();
    const { alert } = useGlobalStore((state) => ({
        alert: state.alert
    }), shallow)

    useEffect(() => {
        console.log('alert changed')
        console.log(alert)
        if (alert && alert?.message !== "") {
            openNotificationWithIcon('error')
        }
    }, [alert])

    const openNotificationWithIcon = (type: NotificationType) => {
        api[type]({
            message: alert.title,
            description: alert.message
        });
    };
    return (
        <>
            {contextHolder}
        </>
    )
}

export default AlertComponent
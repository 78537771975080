import { Row, Col, Space, Divider, Steps, Select } from "antd";
import _ from "lodash";
import { FC, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {retrieveUserInfo} from '../services/migserv_auth'

import ViewBuilder from "../components/ViewBuilder";
import { getListOfMerckWeeksFromAGivenWeek, getCurrentMerckWeek } from "../services/helper";

let migtypes = [
  { value: "BlueSky Rehost", label: "BlueSky Rehost" },
  { value: "GreenLake", label: "GreenLake" },
  { value: "APEX", label: "APEX" },
  { value: "MCS", label: "MCS" },
  { value: "ROBO", label: "ROBO" },
];

const GenericViewPageV2: FC = ({}) => {
  let current_week = getCurrentMerckWeek();
  const [migrationWeeks, setMigrationWeeks] = useState<{ value: string; label: string }[]>([]);
  const [urlParams, setUrlParams] = useState({
    migration_wave: current_week,
    view_name: "content",
    format: "json",
    migtypes: "",
    migtypes_select: [],
    username: retrieveUserInfo()?.userIsid
  });
  const [selectMigrationWeek, setSelectMigrationWeek] = useState(current_week);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    let weeks = getListOfMerckWeeksFromAGivenWeek(current_week, 10).map((week) => {
      return { value: week, label: week };
    });
    setMigrationWeeks(weeks);
    let _urlParams: any[] = [];
    searchParams.forEach((value, key) => {
      _urlParams.push({ [key]: value });
    });
    urlParamsChange(_urlParams);
  }, []);

  const urlParamsChange = (params: any[]) => {
    let _urlParams:any = { ...urlParams };
    params.forEach((param) => {
      _urlParams = { ..._urlParams, ...param };
    });
    if(_urlParams['view_name'] !== 'tags'){
     delete _urlParams?.['migtypes']
     _urlParams['migtypes_select'] = []
    }
    if(_urlParams?.['migtypes_select']){
      _urlParams['migtypes'] = _urlParams['migtypes_select'].join(',')
    }
    _urlParams = _.omitBy(_urlParams,_.isUndefined)
    _urlParams = _.omitBy(_urlParams,_.isEmpty)
    setUrlParams(_urlParams);
  };

  return (
    <>
      <Divider orientation="left">
        View &nbsp;
        <Space direction="horizontal">
          <Select
            value={urlParams["view_name"]}
            defaultValue={urlParams["view_name"]}
            onChange={(value) => urlParamsChange([{ view_name: value }])}
            options={[
              { value: "content", label: "Content" },
              { value: "tags", label: "Tags" },
              { value: "assets-vs-content", label: "Assets Vs Content" },
            ]}
          />
          <Select
            defaultValue={urlParams["migration_wave"]}
            value={urlParams["migration_wave"]}
            options={migrationWeeks}
            onChange={(value) => urlParamsChange([{ migration_wave: value }])}
          />
          {urlParams["view_name"] === "tags" ? (
            <Select
              mode="tags"
              style={{width: '500px',maxWidth: '500px'}}
              placeholder='Select Migration Type'
              defaultValue={[]}
              value={urlParams["migtypes_select"]}
              options={migtypes}
              onChange={(value) => urlParamsChange([{ migtypes_select: value }])}
            />
          ) : (
            <></>
          )}
        </Space>
      </Divider>
      <Row></Row>

      <Row justify={"center"} gutter={{ sm: 8, md: 16, lg: 32 }}>
        <Col span={22}>
          <ViewBuilder
            view_config={{
              hide_view_details: true,
              endpoint_params: urlParams,
              enable_editing: true,
              enable_editing_for: ["Division"],
            }}
          />
        </Col>
      </Row>
      <Divider />
    </>
  );
};

export default GenericViewPageV2;

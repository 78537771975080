import validator from "validator";
import isEmail from "validator/lib/isEmail";
const moment = require("moment");

export let weekMap = {
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
  Sunday: 7,
};

export const groupBy = function (xs, key) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export const isValidEmail = (str) => {
  return validator.isEmail(str, {
    host_whitelist: ["merck.com"],
    domain_specific_validation: true,
  });
};

export const getListOfMerckWeeksFromAGivenWeek = (givenWeek, getWeeksUpto) => {
  let givenWeekNum = +givenWeek.substr(3);
  let given_week_to_moment_obj = moment().startOf('year').startOf("isoweek").add(givenWeekNum, "w");
  let tmp = given_week_to_moment_obj.clone();
  let arr = [];

  for (let i = 0; i < getWeeksUpto; i++) {
    arr.push(`${tmp.year().toString().substring(2)}W${tmp.week().toString().padStart(2, "0")}`);
    tmp = tmp.add(1, "week");
  }
  return arr;
};

export const getCurrentMerckWeek = () => {
  let tmp = moment();
  return `${tmp.year().toString().substring(2)}W${tmp.isoWeek().toString().padStart(2, "0")}`;
};

export const doesDateFallWithinXDaysOfGivenDate = (
  date_to_validate = new Date(),
  given_date = new Date(),
  days = 3
) => {
  date_to_validate = moment(date_to_validate);
  given_date = moment(given_date);
  if (date_to_validate.isValid() && given_date.isValid()) {
    let date_to_validate_doy = getDayOfYear(new Date(date_to_validate.toISOString()));
    let given_date_doy = getDayOfYear(new Date(given_date.toISOString()));
    if (given_date_doy - date_to_validate_doy > 0 && given_date_doy - date_to_validate_doy <= days) {
      return true;
    } else {
      return false;
    }
  } else {
    throw new Error("Date is not valid");
  }
};

// const getDayOfYear = (date = new Date()) => Math.floor((date - new Date(date.getFullYear(), 0, 0)) / 1000 / 60 / 60 / 24);
const getDayOfYear = (date = new Date()) => moment(date).dayOfYear();
export const extractEmailsFromString = (str) => {
  if (typeof str === "string") {
    let emails = str.match(/([a-zA-Z0-9._+-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi);
    if (Array.isArray(emails)) {
      return emails;
    }
    return [];
  }
  return [];
};

export function hasFirstNameLastNameFormat(input) {
  input = input.trim();
  const regex = /^[A-Z][a-z]*\s[A-Z][a-z]*$/;
  return regex.test(input);
}

export const seperateEmailsNamesAndISIDsFromString = (str = "", delimiter = ";") => {
  const result = { emails: [], isids: [], full_names: [] };
  if (typeof str == "string") {
    let split_arr = str.split(delimiter);
    split_arr.forEach((item) => {
      if (item !== "") {
        if (isEmail(item)) {
          result.emails.push(item);
        } else {
          let emails = extractEmailsFromString(item)
          if (emails.length > 0) {
            result.emails.push(...emails)
          } else if (hasFirstNameLastNameFormat(item)) {
            result.full_names.push(item)
          } else {
            result.isids.push(item);
          }
        }
      }

    });
  }
  return result;
};



export const getWeekDayNumberFromWeekDayName = (weekday_name = "Monday") => {
  return weekMap[weekday_name];
};

export function getRelativeTime(date) {
  const now = moment();
  const diffInSeconds = moment(now).diff(date, 'seconds');
  let suffix = ' ago'
  let prefix = ''
  if (diffInSeconds < 0) {
    suffix = ''
    prefix = 'before '
  }
  if (diffInSeconds < 2592000) {
    return prefix + moment.duration(diffInSeconds, 'seconds').humanize(false) + suffix;
  }
  return moment(date).format('MMMM DD, YYYY');

}

export function getQueryParams() {
  const queryParams = {};
  const queryString = window.location.search.slice(1);
  const pairs = queryString.split('&');

  pairs.forEach(pair => {
    const [key, value] = pair.split('=');
    queryParams[decodeURIComponent(key)] = decodeURIComponent(value || '');
  });

  return queryParams;
}

import { Select } from "antd"


const empty_arr = []
const default_options = ['migtype',
    'migration_date',
    'migration_time',
    'app_managed_by',
    'appname',
    'env',
    'servername',
    'wave_name'].map((item) => {
        return {
            label: item,
            value: item
        }
    })
const GroupingFilter = ({ onChange, value, options = default_options }) => {
    if (typeof (onChange) !== 'function') {
        throw new Error('onChange is required and should be a function.')
    }

    return <>
        Group By: &nbsp;
        <Select
            mode="tags"
            title="Group by"
            style={{ width: '50%' }}
            placeholder="Tags Mode"
            value={value}
            onChange={onChange}
            options={options}
        />
    </>



}



export default GroupingFilter
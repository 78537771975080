import { UserOutlined, CalendarTwoTone } from "@ant-design/icons";
import {useEffect} from 'react'
import { Menu, MenuProps } from "antd";
import { createElement, FC } from "react";
import { MigTypes } from "types/migserv_types";
import { SelectItemEvent } from "./Types";
import { CommsEmailTypesArr } from "components/TableActions/CommsEmail/CommsEmailTypes";
import { shallow } from "zustand/shallow";
import useGlobalStore from "store";

interface ConfigMenuProps {
  onConfigMenuItemSelect?: (e: SelectItemEvent) => any;
}
const ConfigMenu: FC<ConfigMenuProps> = ({ onConfigMenuItemSelect = undefined }) => {
  const { get_migtype_by } = useGlobalStore((state) => ({
    get_migtype_by: state.get_migtype_by
  }), shallow)

  useEffect(()=>{},[])
  let configMenuItems: MenuProps["items"] = [
    {
      key: "comms_templates",
      icon: createElement(CalendarTwoTone),
      label: "Comms Templates",
      children:CommsEmailTypesArr.map((comm) => {
        return { key: `${comm}`, label: comm.replaceAll('email','').replaceAll('_',' ') };
      }),
    },
    {
      key: "users",
      icon: createElement(UserOutlined),
      label: "Manage Users",
      children: get_migtype_by('role','ADMIN').map((migtype) => {
        return { key: `users_${migtype}`, label: migtype };
      }),
    },
    {
      key: "migdates",
      icon: createElement(CalendarTwoTone),
      label: "Migration Dates",
      children: get_migtype_by('role','ADMIN').map((migtype) => {
        return { key: `migdates_${migtype}`, label: migtype };
      }),
    },
 
  ];

  const onMenuItemSelect = (e: SelectItemEvent) => {
        if (onConfigMenuItemSelect) {
      onConfigMenuItemSelect(e);
    }
  };

  return (
    <Menu
      onSelect={onMenuItemSelect}
      mode="inline"
      defaultSelectedKeys={["comms_templates"]}
      defaultOpenKeys={["comms_templates"]}
      style={{ height: "100%", borderRight: 0 }}
      items={configMenuItems}
    />
  );
};

export default ConfigMenu;
